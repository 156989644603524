import React from 'react';
import Helpers from './../services/Helpers.js';
import Store from './../services/Store.js';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Dropzone from 'react-dropzone';
import * as loadImage from 'blueimp-load-image';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import Form from 'react-bootstrap/Form';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import { formatDate, parseDate } from 'react-day-picker/moment';
import Table from 'react-bootstrap/Table';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import FormValidation from './../services/FormValidation.js';
import DismissibleAlert from './DismissibleAlert.js';

import IconButton from '@material-ui/core/IconButton';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';

export default class ExpenseEditModal extends React.Component{

	constructor(props) {
	  	super(props);
	  	this.state={
	  		create:true,
	  		errors:{},
	  		success:{},
		  	alerts:[],
	      	alertsField:[],
	      	expense:{},
	      	loadingSelectSupplier:false
	  	};
	  	this.validation_expense={
      		"expense_category":["required","not_empty"],
      		"supplier":["required","not_empty"],
      		"date":["required"],
      		"amount":["required"]
      	};
	}

	componentDidMount(){
		let oldState=Object.assign({}, this.state);
		Helpers.doRequest('get','financial/expense?property_id='+this.props.property_id+"&expense_id="+this.props.expense_id)
 			.then(data=>{
            	oldState.create=this.props.expense_id && !isNaN(this.props.expense_id) ? true : false;
            	oldState.expense=data.expense;
            	this.setState(oldState);
 			}).catch(error=>{
            	//
        	});
	}

	componentWillUnmount(){
		this.revokeFilesUrls();
	}

	revokeFilesUrls(){
		if(this.state.expense.files){
			for(let x in this.state.expense.files){
				if(this.state.expense.files[x] instanceof Blob && this.state.expense.files[x].url){
					URL.revokeObjectURL(this.state.expense.files[x].url);
				}
			}
		}
	}

	closeModal(){
		this.revokeFilesUrls();
		this.props.onHide();
	}

	updateStateValue(value,model,parent){
        let oldState=Object.assign({}, this.state);
        if(parent){
        	if(model === 'expense_category' || model === 'supplier'){
        		if(value !== null){
	        		oldState[parent][model]=[];
	        		oldState[parent][model].push(value);
	        	}else{
	        		oldState[parent][model]=value;
	        	}
        	}else{
        		oldState[parent][model]=value;
        	}
        }else{
        	oldState[model]=value;
        }
        this.setState(oldState);
    }

    supplierSearch(search,action){
    	if(action === 'input-change'){
    		this.setState({ loadingSelectSupplier:true });
    		if(this.searchPromise){
	            clearTimeout(this.searchPromise);
	        }
	        let oldState=Object.assign({}, this.state);
	        this.searchPromise=setTimeout(()=>{
	            Helpers.doRequest('get','financial/supplier?search='+search,{},true)
		            .then(data=>{
		                oldState.expense.suppliers=data.suppliers;
		                oldState.loadingSelectSupplier=false;
		                this.setState(oldState);
		            }).catch(error=>{});
		    },500);
	    }
    }

    createSupplier(value){
    	if(this.searchPromise){
            clearTimeout(this.searchPromise);
        }
    	let tmp_option={'id':"tmp"+(new Date().getTime()),'name':value};
    	let oldState=Object.assign({}, this.state);
    	oldState.expense.suppliers.push(tmp_option);
    	oldState.expense.supplier=[];
    	oldState.expense.supplier.push(tmp_option);
    	this.setState(oldState);
    }

    onDrop(acceptedFiles){
		if(acceptedFiles && Array.isArray(acceptedFiles)){
	    	if(acceptedFiles.length){
	    		Helpers.showLoading();
		    	this.verifyFile(acceptedFiles,0);
	    	}else{
	    		Helpers.showAlerts(this,{'errors':{'error':(Helpers.translate('Wrong file type',this.props.translations.general,'general'))}});
	    	}
	    }
	}

	verifyFile(files,index){
		if(index < files.length){
			let genThis=this;
			let oldState=Object.assign({},genThis.state);
			if(files[index].type.indexOf('image') !== -1){
				const loadImageOptions = { canvas: true };
				loadImage.parseMetaData(files[index], (data) => {
				  	if (data.exif && data.exif.get('Orientation')) {
				    	loadImageOptions.orientation = data.exif.get('Orientation');
				  	}
				  	loadImage(files[index], (canvas) => {
				  		let convertedImage=Helpers.converterDataURItoBlob(canvas.toDataURL(files[index].type));
				  		convertedImage.url=URL.createObjectURL(convertedImage);
				  		convertedImage.name=files[index].name;
				  		convertedImage.file_type='1';
				  		if(!oldState.expense.files){
				  			oldState.expense.files=[];
				  		}
				  		oldState.expense.files.push(convertedImage);
				    	genThis.setState(oldState);
				    	setTimeout(function(){
				    		genThis.verifyFile(files,index+1);
				    	},100);
				  	}, loadImageOptions)
				});
			}else{
				if(!oldState.expense.files){
		  			oldState.expense.files=[];
		  		}
		  		let fileObj=files[index];
		  		fileObj.file_type='2';
				oldState.expense.files.push(fileObj);
		    	genThis.setState(oldState);
		    	setTimeout(function(){
		    		genThis.verifyFile(files,index+1);
		    	},100);
			}
		}else{
			Helpers.stopLoading();
		}
	}

	removeFile(index){
		confirmAlert({
		  customUI: ({ onClose }) => {
		    return (
		      <div className='custom-ui'>
		        <h1>{Helpers.translate('Confirm',this.props.translations.general,'general')}</h1>
		        <p>{Helpers.translate('Are you sure you want to delete selected line?',this.props.translations.general,'general')}</p>
		        <button onClick={onClose}>{Helpers.translate('No',this.props.translations.general,'general')}</button>
		        <button
		          onClick={() => {
		            onClose();
		            let oldState=Object.assign({},this.state);
		            if(oldState.expense.files[index] instanceof Blob && this.state.expense.files[index].url){
		            	URL.revokeObjectURL(oldState.expense.files[index].url);
		            }
		            oldState.expense.files.splice(index,1);
					this.setState(oldState);
		          }}
		        >
		          {Helpers.translate('Yes',this.props.translations.general,'general')}
		        </button>
		      </div>
		    );
		  }
		});
	}

	saveExpense(){
		let fieldTranslations={
    		"expense_category":Helpers.translate('Category',this.props.translations.financial,'financial'),
    		"supplier":Helpers.translate('Supplier',this.props.translations.financial,'financial'),
    		"date":Helpers.translate('Date',this.props.translations.general,'general'),
    		"amount":Helpers.translate('Amount',this.props.translations.financial,'financial')
    	};
    	let formErrors=FormValidation.checkValidity(this.validation_expense,this.state.expense,fieldTranslations,this.props.translations.general);
		if(formErrors.has_errors){
			Helpers.showAlerts(this,formErrors,true);
			return false;
		}
		let formData = new FormData();
		formData.append("property_id", this.props.property_id);
		formData.append("expense_id", this.props.expense_id);
		if(Array.isArray(this.state.expense.expense_category) && this.state.expense.expense_category.length>0){
			formData.append("expense_category_id", this.state.expense.expense_category[0].id);
		}
		if(Array.isArray(this.state.expense.supplier) && this.state.expense.supplier.length>0){
			formData.append("supplier_id", this.state.expense.supplier[0].id);
			formData.append("supplier_name", this.state.expense.supplier[0].name);
		}
		if(Helpers.isValidDate(this.state.expense.date)){
			formData.append("date", Math.round(Helpers.localAsUtc(this.state.expense.date).getTime()/1000));
		}else{
			formData.append("date", Math.round(Helpers.localAsUtc(new Date(this.state.expense.date)).getTime()/1000));
		}
		formData.append("amount", this.state.expense.amount);
		formData.append("is_public", (this.state.expense.is_public ? '1' : '0'));
		for(let x in this.state.expense.files){
			if(this.state.expense.files[x] instanceof File || this.state.expense.files[x] instanceof Blob){
				formData.append("files[]", this.state.expense.files[x]);
			}else{
				formData.append("file_ids[]", this.state.expense.files[x].id);
			}
		}
		let genThis=this;
		let oReq = new XMLHttpRequest();
	    oReq.open("POST", process.env.REACT_APP_API_URL+'financial/expense', true);
	    oReq.setRequestHeader("Authorization","Bearer " + Store.retrieveAccessToken());
	    Helpers.showLoading();
	    oReq.onload = function(oEvent) {
	        if(oReq.status === 200){
	        	Helpers.stopLoading();
	        	genThis.revokeFilesUrls();
	        	genThis.props.onUpdate(JSON.parse(oEvent.target.response));
	        }else{
	        	Helpers.stopLoading();
	        	Helpers.showAlerts(genThis,JSON.parse(oEvent.target.response),true);
	        }
	    };
	    oReq.send(formData);
	}

	closeAlert(index){
		let oldState=Object.assign({}, this.state);
		oldState.alerts.splice(index,1);
		this.setState(oldState);
	}

	render(){
		const generalMsg = this.state.alerts.map((item, key) =>
	        <DismissibleAlert modal="true" key={key} index={key} type={item.type} message={item.msg} dismissAlert={(e) => this.closeAlert(e)}/>
	    );
		let expenseFiles;
		if(this.state.expense.files){
			expenseFiles=this.state.expense.files.map((item, key) =>
		        <tr key={key}>
		        	<td>{ item.name }</td>
		        	<td>{ (parseInt(item.file_type)===2 ? 'pdf' : Helpers.translate('image',this.props.translations.general,'general')) }</td>
		        	<td>{ (item.url ? <a href={item.url} target="_blank" rel="noopener noreferrer"><i className="fa fa-link" style={{color:"#17a2b8", fontSize:"14px"}}aria-hidden="true"></i></a> : '') }

						<IconButton aria-label="delete" size="small" onClick={(event)=>this.removeFile(key)}>{Helpers.translate(this.props.translations.general,'general')}
							<DeleteOutlineIcon fontSize="inherit" />
						</IconButton>

							</td>

		        </tr>
		    )
		}


		return(
			<Modal
				show={this.props.show}
		      	size="medium"
		      	aria-labelledby="contained-modal-expense-edit"
		      	centered
		      	backdrop="static"
		      	onHide={()=>this.closeModal()}
		    >
		    	<Modal.Header closeButton>
		    		<Modal.Title>{ this.props.title }</Modal.Title>
		    	</Modal.Header>
		      	<Modal.Body >
		      		{generalMsg}
						<div className="row justify-content-md-center">
		      		<div className="col-md-10">
		      			<Form className="form-control-Modal-Expense">
						  	<Form.Group>
						    	<Form.Label className="ft-16">{Helpers.translate('Category',this.props.translations.financial,'financial')}</Form.Label>

						    	<Select options={this.state.expense.categories}
						    		value={this.state.expense.expense_category && this.state.expense.expense_category.length ? this.state.expense.expense_category : []}
										components={{ IndicatorSeparator: () => null }}
										className={"selectize " + (this.state.errors.expense_category ? 'border-error' : '' )}
	          						isClearable={true}
	          						isSearchable={true}
	          						placeholder={Helpers.translate('Select',this.props.translations.general,'general')}
						    		getOptionLabel={option => option.name}
						    		getOptionValue={option => option.id}
						    		onChange={(value)=>this.updateStateValue(value,'expense_category','expense')}

						    	/>

						    	{this.state.errors.expense_category && <p className="text-danger mt-1">{this.state.alertsField.expense_category}</p>}
						  	</Form.Group>
						  	<Form.Group>
						    	<Form.Label className="ft-16">{Helpers.translate('Supplier',this.props.translations.financial,'financial')}</Form.Label>
						    	<CreatableSelect options={this.state.expense.suppliers}
						    		value={this.state.expense.supplier && this.state.expense.supplier.length ? this.state.expense.supplier : []}
										components={{ IndicatorSeparator: () => null }}
										className={"selectize "+(this.state.errors.supplier ? 'border-error' : '')}
	          						isClearable={true}
	          						isLoading={this.state.loadingSelectSupplier}
	          						placeholder={Helpers.translate('Select',this.props.translations.general,'general')}
						    		getOptionLabel={option => option.name}
						    		getOptionValue={option => option.id}
						    		onChange={(value)=>this.updateStateValue(value,'supplier','expense')}
						    		onInputChange={(str,action)=>this.supplierSearch(str,action.action)}
						    		onCreateOption={(value)=>this.createSupplier(value)}
						    		getNewOptionData={(inputValue, optionLabel) => ({
								        id: optionLabel,
								        name: Helpers.translate('Create',this.props.translations.general,'general')+" "+inputValue,
								        __isNew__: true
								    })}
						    	/>
						    	{this.state.errors.supplier && <p className="text-danger mt-1">{this.state.alertsField.supplier}</p>}
						  	</Form.Group>
						<div className="row" >
							<div className="col align-self-start">
						  	<Form.Group>
						    	<Form.Label className="ft-16" style={{ justifyContent: 'center'}}>{Helpers.translate('Date',this.props.translations.general,'general')}</Form.Label>
						    	<div>

						    		<DayPickerInput
										className="Financial-form-control"
										format= "DD/MM/YYY"
						    			value={ this.state.expense.date }
							    		placeholder="D/M/YYYY"
							  			dayPickerProps={{ firstDayOfWeek: 1, showOutsideDays:true }}
							  			formatDate={formatDate}
	        							parseDate={parseDate}
	        							inputProps={{ className:"form-control", readOnly: true }}
	        							onDayChange={(selectedDay, modifiers, dayPickerInput)=>this.updateStateValue(selectedDay,'date','expense')}
	        						/>
						    	</div>
						    	{this.state.errors.date && <p className="text-danger mt-1">{this.state.alertsField.date}</p>}
						  	</Form.Group>
							</div>
							<div className="col align-self-end">
						  	<Form.Group style={{textAlign:"center"}}>
						    	<Form.Label className="ft-16" >{Helpers.translate('Amount',this.props.translations.financial,'financial')}</Form.Label>
						    	<Form.Control className="Financial-form-control" type="number" min="0" value={this.state.expense.amount || ''} name="amount" onChange={(e)=>this.updateStateValue(e.target.value,'amount','expense')} placeholder={Helpers.translate('Amount',this.props.translations.financial,'financial')} autoComplete="off"/>
						  		{this.state.errors.amount && <p className="text-danger mt-1">{this.state.alertsField.amount}</p>}
						  	</Form.Group>
							</div>
						</div>
						  	<Form.Group controlId="public_availability">
							    <Form.Check className="accountCheckbox"
							    	type="checkbox"
							    	custom
							    	label={Helpers.translate('Visible for accountant',this.props.translations.financial,'financial')}
							    	onChange={(e)=>this.updateStateValue(e.target.checked,'is_public','expense')}
							    	checked={this.state.expense.is_public && this.state.expense.is_public === true ? 'checked' : ''}
							    />
							</Form.Group>
						  	<Form.Group>
						  		<Form.Label className="ft-16">{Helpers.translate('Files',this.props.translations.financial,'financial')}</Form.Label>
						  		<Table responsive="sm">
						  			<thead>
								      <tr>
								        <th>{Helpers.translate('Name',this.props.translations.general,'general')}</th>
								        <th>{Helpers.translate('Type',this.props.translations.general,'general')}</th>
								        <th>{Helpers.translate('Link',this.props.translations.general,'general')}</th>
								        <th></th>
								      </tr>
								    </thead>
								    <tbody>
								    	{ expenseFiles }
								    </tbody>
						  		</Table>
						  	</Form.Group>
						  	<div className="dropZone mt-4">
			        			<Dropzone onDrop={(acceptedFiles)=>this.onDrop(acceptedFiles)} accept={'image/*,application/pdf'} multiple={false}>
								  {({getRootProps, getInputProps}) => (
								    <section>
								      <div {...getRootProps()} className="dropZone_container">
								        <input {...getInputProps()} />
								        <p>{Helpers.translate('Drag and drop files here or click to select file',this.props.translations.general,'general')}</p>
								      </div>
								    </section>
								  )}
								</Dropzone>
			            	</div>
						</Form>
		      </div>
		      		</div>
		      	</Modal.Body>
		      	<Modal.Footer>
		      		<Button variant="Light"  onClick={()=>this.closeModal()}>{Helpers.translate('Close',this.props.translations.general,'general')}</Button>
		        	<Button className="ButtonAdd" variant="outline-secondary" onClick={()=>this.saveExpense()}>{Helpers.translate('Save',this.props.translations.general,'general')}</Button>
		      	</Modal.Footer>
		    </Modal>
		)
	}

}
