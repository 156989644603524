import React from 'react';
import PicturesList from './../../components/PicturesList.js';
import Helpers from './../../services/Helpers.js';
import Store from './../../services/Store.js';
import DismissibleAlert from './../../components/DismissibleAlert.js';
import Dropzone from 'react-dropzone';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Select from 'react-select';
import FormValidation from './../../services/FormValidation.js';
import Iframe from './../../components/Iframe.js';
import Script from 'react-load-script';
import { withRouter } from 'react-router-dom';
import * as loadImage from 'blueimp-load-image';

class Onboarding extends React.Component{

	constructor(props) {
	  	super(props);
	  	this.state = {
	  		pictures:[], 			
	  		errors:{},
		  	alerts:[],
	      	alertsField:[],
	      	translations:{},
	      	step:null,
	      	property_id:null,
	      	g_key:'',
	      	public_g_key:'',
	      	map_address_base:'',
	      	map_address:'',
	      	gAutocompleteInput:'',
	      	property_location:{},
	      	property_location_select:[],
	      	property_location_select_value:[],
	      	countries:[],
	      	languages:[],
	      	property_publish:{},
	      	current_upload_size:0,
	      	max_post_size:16000000
	  	};
	  	this.handlePlaceChanged = this.handlePlaceChanged.bind(this);
	  	this.searchPromise=0;  	
	  	this.moduleName='onboarding';
	  	this.validation_location={
      		"city":["required"],
      		"country":["required"]
      	}; 	
      	this.validation_publish={
      		"language":["required"]
      	}; 
	}

	componentDidMount(){
		let oldState=Object.assign({}, this.state);
		const current_step=Store.retrieveStep();	
		switch(current_step){
			case 'location_data':
				Helpers.doRequest('get','location_data')
		 			.then(data=>{  	       	                 	            	
		            	oldState.property_id=data.property_id;
		            	oldState.countries=data.countries;
		            	oldState.step=current_step;
		            	oldState.g_key=data.g_key;
		            	oldState.public_g_key=data.public_g_key;
		            	oldState.map_address_base=data.map_address_base;
		            	Helpers.getTranslations(this.moduleName)
				            .then(data=>{
				            	oldState.translations=data;			                						                
				                this.setState(oldState);
				            }).catch(error=>{
				            	oldState.translations=error;
				            	this.setState(oldState);
				            });
		 			}).catch(error=>{
		            	//	
		        	});
				break;
			case 'publish':
				Helpers.doRequest('get','publish_data')
		 			.then(data=>{  	       	  
		 				oldState.step=current_step;               	            	
		            	oldState.property_id=data.property_id;
		            	oldState.languages=data.languages;
		            	Helpers.getTranslations(this.moduleName)
				            .then(data=>{
				            	oldState.translations=data;			                						                
				                this.setState(oldState);
				            }).catch(error=>{
				            	oldState.translations=error;
				            	this.setState(oldState);
				            });
		 			}).catch(error=>{
		            	//	
		        	});
				break;
			default:
				oldState.step=current_step; 
				Helpers.getTranslations(this.moduleName)
		            .then(data=>{
		            	oldState.translations=data;			                						                
		                this.setState(oldState);
		            }).catch(error=>{
		            	oldState.translations=error;
		            	this.setState(oldState);
		            });
		}
	}

	componentWillUnmount(){
		this.revokeUrls();
	}

	revokeUrls(){
		for(let x in this.state.pictures){
			URL.revokeObjectURL(this.state.pictures[x].preview);
		}
	}

	updateStateValue(value,model){
        let oldState=Object.assign({}, this.state);
        oldState[model]=value;
        this.setState(oldState);  
    }

	closeAlert(index){
		let oldState=Object.assign({}, this.state);
		oldState.alerts.splice(index,1);
		this.setState(oldState);
	}

	onDrop(acceptedFiles){
	    if(acceptedFiles && Array.isArray(acceptedFiles)){
	    	if(acceptedFiles.length){
	    		Helpers.showLoading();
		    	this.createImage(acceptedFiles,0);
	    	}else{
	    		Helpers.showAlerts(this,{'errors':{'error':(Helpers.translate('Wrong image file',this.state.translations.general,'general'))}});
	    	}    	
	    }
	};

	createImage(images,index){
		if(index < images.length){
			let genThis=this;
			let oldState=Object.assign({},genThis.state);
			const loadImageOptions = { canvas: true };
			loadImage.parseMetaData(images[index], (data) => {
			  	if (data.exif && data.exif.get('Orientation')) {
			    	loadImageOptions.orientation = data.exif.get('Orientation');
			  	}
			  	loadImage(images[index], (canvas) => {
			  		let convertedImage=Helpers.converterDataURItoBlob(canvas.toDataURL(images[index].type));
			  		convertedImage.preview=URL.createObjectURL(convertedImage);	
			  		if(oldState.current_upload_size+convertedImage.size <= oldState.max_post_size){
			  			oldState.pictures.push(convertedImage);
			  			oldState.current_upload_size+=convertedImage.size;
			    		genThis.setState(oldState);
			    		setTimeout(function(){
			    			genThis.createImage(images,index+1);
			    		},100);
			  		}else{
			  			Helpers.stopLoading();
			  			Helpers.showAlerts(genThis,{'errors':{'error':Helpers.translate('Maximum photos limit size achieved',this.state.translations.general,'general')+": "+(oldState.max_post_size/1000000)+" Mb.<br/>"+Helpers.translate('Reduce the size of the pictures or upload less pictures',this.state.translations.general,'general')}});
			  		}
			  		 			    	
			  	}, loadImageOptions)
			});
		}else{
			Helpers.stopLoading();
		}		
	}

	setPictureAsBanner(index){
		let oldState=Object.assign({},this.state);
		for(let x in oldState.pictures){
			oldState.pictures[x].is_banner=(parseInt(x)===parseInt(index) ? true : false);
		}
		this.setState(oldState);
	}

	deletePicture(index){
		let oldState=Object.assign({},this.state);
       	URL.revokeObjectURL(oldState.pictures[index].preview);
		oldState.pictures.splice(index,1);
		this.setState(oldState);
	}

	savePictures(){
		let genThis=this;
		let formData = new FormData();
		for(let x in this.state.pictures){
			formData.append("files[]", this.state.pictures[x]);
			formData.append("files_banners[]", (this.state.pictures[x].is_banner ? '1' : '0'));
		}
		let oReq = new XMLHttpRequest();
	    oReq.open("POST", process.env.REACT_APP_API_URL+'upload_pictures', true);
	    oReq.setRequestHeader("Authorization","Bearer " + Store.retrieveAccessToken());
	    Helpers.showLoading();
	    oReq.onload = function(oEvent) {  
	        if(oReq.status === 200){
	        	Helpers.stopLoading();
	        	let data=JSON.parse(oEvent.target.response); 	        	       	
	        	genThis.revokeUrls();   
	        	let oldState=Object.assign({},genThis.state);    	       	                 	
            	oldState.step=data.step;             	
            	oldState.pictures=[]; 
            	oldState.property_id=data.property_id;
            	oldState.countries=data.countries;
            	oldState.g_key=data.g_key;
            	oldState.public_g_key=data.public_g_key;
            	oldState.map_address_base=data.map_address_base;
            	genThis.setState(oldState);
            	Store.setStep(data.step);  
	        }else{
	        	Helpers.stopLoading();
	        	Helpers.showAlerts(genThis,JSON.parse(oEvent.target.response));
	        }          
	    };
	    oReq.send(formData);
	}

	generateMapAddress(data){
		let map_address="";
		if(data.address){
			map_address+=encodeURI(data.address)+',';
		}
		if(data.zip){
			map_address+=encodeURI(data.zip)+',';
		}
		if(data.city){
			map_address+=encodeURI(data.city)+',';
		}
		if(data.county){
			map_address+=encodeURI(data.county)+',';
		}
		if(data.country){
			map_address+=encodeURI(data.country_name)+',';
		}
		map_address=map_address.replace(/~+$/,',');
		if(map_address){
			map_address=this.state.map_address_base+"?key="+this.state.public_g_key+"&q="+map_address;
		}
		return map_address;
	}

	updateMap(){
		let oldState=Object.assign({}, this.state);	
		oldState.map_address=this.generateMapAddress({address:this.state.property_location.address,city:this.state.property_location.city,zip:this.state.property_location.zip,county:this.state.property_location.county,country_name:(this.state.property_location.country && this.state.property_location.country.length ? this.state.property_location.country[0].name : '')});
		this.setState(oldState);
	}

	async checkCoordinates(){
		Helpers.showLoading();
		await navigator.geolocation.getCurrentPosition(
			position => {
				Helpers.stopLoading();
				Helpers.doRequest('get','geocode?latitude='+position.coords.latitude+'&longitude='+position.coords.longitude)
		 			.then(data=>{  	       	                 	            	
		            	if(data.address_data && Array.isArray(data.address_data) && data.address_data.length){
		            		if(data.address_data.length>1){
		            			let oldState=Object.assign({}, this.state);
		            			oldState.property_location_select=data.address_data;
		            			this.setState(oldState);
		            		}else{
		            			let oldState=Object.assign({}, this.state);
		            			oldState.property_location.address=data.address_data[0].street;
		            			oldState.property_location.city=data.address_data[0].city;
		            			oldState.property_location.zip=data.address_data[0].zip;
		            			oldState.property_location.county=data.address_data[0].county;
		            			if(data.address_data[0].country_name){
		            				Helpers.doRequest('get','countries?search='+data.address_data[0].country_name)
							            .then(data=>{
							                oldState.countries=data.countries;
							                if(data.countries.length){
							                	oldState.property_location.country=[data.countries[0]];
							                }
							                oldState.map_address=this.generateMapAddress({address:oldState.property_location.address,city:oldState.property_location.city,zip:oldState.property_location.zip,county:oldState.property_location.county,country_name:data.address_data[0].country_name});						                
							                this.setState(oldState);
							            }).catch(error=>{});
		            			}else{
		            				this.setState(oldState);
		            			}
		            		}
		            	}
		 			}).catch(error=>{
		            	Helpers.showAlerts(this,error);
		        	});
			},
			error => {
				Helpers.stopLoading();
				Helpers.showAlerts(this,{'errors':{'error':(Helpers.translate('Could not find location',this.state.translations.onboarding,'onboarding'))}});
			},
			{ enableHighAccuracy: true, timeout: 20000, maximumAge: 1000 }
		);
	}

	handleGScriptLoad(){
		const options = {
	      types: ['geocode','establishment'],
	    }; 

	    /*global google*/ // To disable any eslint 'google not defined' errors
	    this.gautocomplete = new google.maps.places.Autocomplete(
	      	document.getElementById('gautocomplete'),
	      	options,
	    );
	    this.gautocomplete.setFields(['address_components']);
	    this.gautocomplete.addListener('place_changed', this.handlePlaceChanged);
	}

	handlePlaceChanged(){	
		const addressObject = this.gautocomplete.getPlace();
		if(addressObject){
			let composedAddress={'street_number':'','street':'','city':'','zip':'','county':'','country_code':'', 'country_name':''};
        	for(let x in addressObject.address_components){
        		switch(addressObject.address_components[x].types[0]){
        			case 'street_number':
        				composedAddress['street_number']=addressObject.address_components[x].long_name;
        				break;
        			case 'route':
        				composedAddress['street']=addressObject.address_components[x].long_name;
        				break;
        			case 'locality':
        				composedAddress['city']=addressObject.address_components[x].long_name;
        				break;
        			case 'administrative_area_level_1':
        				composedAddress['county']=addressObject.address_components[x].long_name;
        				break;
        			case 'country':
        				composedAddress['country_code']=addressObject.address_components[x].short_name;
        				composedAddress['country_name']=addressObject.address_components[x].long_name;
        				break;
        			default:
        		}
        	}
        	let oldState=Object.assign({}, this.state);
			oldState.property_location.address=composedAddress.street_number ? composedAddress.street+' '+composedAddress.street_number : composedAddress.street;
			oldState.property_location.city=composedAddress.city;
			oldState.property_location.zip=composedAddress.zip;
			oldState.property_location.county=composedAddress.county;
			if(composedAddress.country_name){
				Helpers.doRequest('get','countries?search='+composedAddress.country_name)
		            .then(data=>{
		                oldState.countries=data.countries;
		                if(data.countries.length){
		                	oldState.property_location.country=[data.countries[0]];
		                }		
		                oldState.map_address=this.generateMapAddress({address:oldState.property_location.address,city:oldState.property_location.city,zip:oldState.property_location.zip,county:oldState.property_location.county,country_name:composedAddress.country_name});				                
		                this.setState(oldState);
		            }).catch(error=>{});

			}
  		}
  	}

	updatePropertyLocationValue(e,model){
        let oldState=Object.assign({}, this.state);
        if(model==='country'){
        	if(e !== null){
        		oldState.property_location[model]=[];
        		oldState.property_location[model].push(e);
        	}else{
        		oldState.property_location[model]=e;
        	}
        }else{
        	oldState.property_location[model]=e.target.value;
        }
        this.setState(oldState);  
    }

    updatePropertyData(value){
    	let oldState=Object.assign({}, this.state);
    	oldState.property_location_select_value=[value];
		oldState.property_location.address=value.street;
		oldState.property_location.city=value.city;
		oldState.property_location.zip=value.zip;
		oldState.property_location.county=value.county;
		if(value.country_name){
			Helpers.doRequest('get','countries?search='+value.country_name)
	            .then(data=>{
	                oldState.countries=data.countries;
	                if(data.countries.length){
	                	oldState.property_location.country=[data.countries[0]];
	                }		
	                oldState.map_address=this.generateMapAddress({address:oldState.property_location.address,city:oldState.property_location.city,zip:oldState.property_location.zip,county:oldState.property_location.county,country_name:value.country_name});				                
	                this.setState(oldState);
	            }).catch(error=>{});
		}else{
			this.setState(oldState);
		}
    }

    countrySearch(search,action){
    	if(action === 'input-change'){
    		if(this.searchPromise){
	            clearTimeout(this.searchPromise);
	        }
	        let oldState=Object.assign({}, this.state);
	        this.searchPromise=setTimeout(()=>{   
	            Helpers.doRequest('get','countries?search='+search)
		            .then(data=>{
		                oldState.countries=data.countries;
		                this.setState(oldState);
		            }).catch(error=>{});
		    },500);
	    }
    }

    saveLocationData(){
    	let fieldTranslations={
    		"city":Helpers.translate('City',this.state.translations.onboarding,'onboarding'),
      		"country":Helpers.translate('Country',this.state.translations.onboarding,'onboarding')
    	};
    	let formErrors=FormValidation.checkValidity(this.validation_location,this.state.property_location,fieldTranslations,this.state.translations.general);
		if(formErrors.has_errors){
			Helpers.showAlerts(this,formErrors,true);
			return false;
		}
		let obj=Object.assign({},this.state.property_location);
		obj.property_id=this.state.property_id;
		obj.country_id=obj.country[0].id;
		obj.country=obj.country[0].code;
		Helpers.doRequest('post','location_data',obj)
            .then(data=>{
            	let oldState=Object.assign({}, this.state);
            	oldState.errors={};
            	oldState.alerts=[];
            	oldState.alertsField=[];
            	oldState.property_id=data.property_id;
            	oldState.step=data.step;
            	oldState.languages=data.languages;
            	oldState.property_location={};
	      		oldState.property_location_select=[];
	      		oldState.property_location_select_value=[];
	      		oldState.countries=[];
            	this.setState(oldState);
            	Store.setStep(data.step);
            }).catch(error=>{
            	Helpers.showAlerts(this,error,true);
            });
    }

    updatePropertyPublishValue(e,model){
    	let oldState=Object.assign({}, this.state);
        if(model==='language'){       	
        	if(e !== null){
        		oldState.property_publish[model]=[];
        		oldState.property_publish[model].push(e);
        	}else{
        		oldState.property_publish[model]=e;
        	}
        }else{
        	oldState.property_publish[model]=e.target.value;
        }
        this.setState(oldState);
    }

    publishProperty(){
    	let fieldTranslations={
    		"language":Helpers.translate('Language',this.state.translations.general,'general')
    	};
    	let formErrors=FormValidation.checkValidity(this.validation_publish,this.state.property_publish,fieldTranslations,this.state.translations.general);
		if(formErrors.has_errors){
			Helpers.showAlerts(this,formErrors,true);
			return false;
		}
    	let obj=Object.assign({},this.state.property_publish);
		obj.property_id=this.state.property_id;
		obj.language_id=obj.language[0].id;
		obj.language=obj.language[0].code;	
		Helpers.doRequest('post','publish_data',obj)
            .then(data=>{
            	Store.setStep(data.step);
            	Store.setMainPage(data.main_page);
	 			window.ee.emit('storeChange');
	 			this.props.history.push("/onboarding_more",{"msg":data});
            }).catch(error=>{
            	Helpers.showAlerts(this,error,true);
            });
    }

	render(){
		const generalErrors = this.state.alerts.map((item, key) =>
	        <DismissibleAlert key={key} index={key} type={item.type} message={item.msg} dismissAlert={(e) => this.closeAlert(e)}/>
	    );
	    let uploadPictures;
		if(this.state.step === 'upload_pictures'){
			const picturesList = this.state.pictures.map((item, key) =>
		        <PicturesList key={key} index={key} data={item} checkOption={(index)=>this.setPictureAsBanner(index)} removePhoto={(index)=>this.deletePicture(index)} translations={this.state.translations}/>
		    );
			uploadPictures=<div>
				<div className="page-header">
					<div className="col-sm-12 text-left">
		                <h1>{Helpers.translate('Upload Pictures',this.state.translations.general,'general')}</h1>
		            </div>
		        </div>
	            <div className="col-sm-12">
	            	<div className="dropZone col-sm-8 offset-md-2 mb-2">
	            		<Dropzone onDrop={(acceptedFiles)=>this.onDrop(acceptedFiles)} accept={'image/*'}>
						  {({getRootProps, getInputProps}) => (
						    <section>
						      <div {...getRootProps()} className="dropZone_container">
						        <input {...getInputProps()} />
						        <p>{Helpers.translate('Drag and drop files here or click to select file',this.state.translations.general,'general')}</p>
						      </div>
						    </section>
						  )}
						</Dropzone>
	            	</div>
	            	<Form>
		            	<table className="table active_rows">
		            		<thead>
								<tr>
									<th>{Helpers.translate('Thumbnail',this.state.translations.general,'general')}</th>
									<th>{Helpers.translate('Name',this.state.translations.general,'general')}</th>
									<th>{Helpers.translate('Size',this.state.translations.general,'general')}</th>								
									<th>{Helpers.translate('Set as banner',this.state.translations.general,'general')}</th>
									<th></th>
								</tr>
							</thead>
							<tbody>
								{picturesList}
							</tbody>
		            	</table>
	            	</Form>	            					            	
	            </div>
	            {this.state.pictures.length>0 && <div className="col-sm-12 text-right">
					<Button variant="info" onClick={()=>this.savePictures()}>{Helpers.translate('Continue',this.state.translations.general,'general')}</Button>
				</div>}
            </div>
		} 
		let location_data;
		if(this.state.step === 'location_data'){
			location_data=<div>
				<Script url={"https://maps.googleapis.com/maps/api/js?key="+this.state.g_key+"&libraries=places"} onLoad={()=>this.handleGScriptLoad()} />
				<div className="page-header">
					<div className="col-sm-12 text-left">
		                <h1>{Helpers.translate('Where is your property located?',this.state.translations.onboarding,'onboarding')}</h1>
		            </div>
		        </div>
				<div className="col-sm-12 text-left">
					<Button variant="primary" type="submit" onClick={()=>this.checkCoordinates()}>
				    	{Helpers.translate('Get current position',this.state.translations.onboarding,'onboarding')}
				  	</Button>
				</div>
				<div className="col-sm-6 text-left mt-2">
					<p className="ft-16">{Helpers.translate('or',this.state.translations.onboarding,'onboarding')}</p>
				</div>
				<div className="col-sm-6 text-left mt-2">
					<input type="text" className="form-control" id="gautocomplete" placeholder={Helpers.translate('Search for address',this.state.translations.onboarding,'onboarding')}/>
				</div>
				{this.state.property_location_select.length>0 && <div className="col-sm-6 text-left mt-4">
					<Form>
						<Form.Group>
					    	<Form.Label className="ft-16">{Helpers.translate('Select your location',this.state.translations.onboarding,'onboarding')}</Form.Label>				    	
					    	<Select options={this.state.property_location_select}
					    		value={this.state.property_location_select_value}
					    		placeholder={Helpers.translate('Select',this.state.translations.general,'general')} 
					    		defaultMenuIsOpen={true}
					    		getOptionLabel={option => option.full_address}
					    		getOptionValue={option => option.id}
					    		onChange={(value)=>this.updatePropertyData(value)}
					    	/>
					  	</Form.Group>
					</Form>
				</div>}
				<div className="mt-4 mb-4">
					<div className="col-sm-6 text-left d-inline-block float-left">
						<Form>					
						  	<Form.Group>
						    	<Form.Label className="ft-16">{Helpers.translate('Address',this.state.translations.onboarding,'onboarding')}</Form.Label>
						    	<Form.Control type="text" value={this.state.property_location.address || ''} name="address" onChange={(e)=>this.updatePropertyLocationValue(e,'address')} placeholder={Helpers.translate('Address',this.state.translations.onboarding,'onboarding')} autoComplete="off"/>
						  	</Form.Group>
						  	<Form.Group>
						    	<Form.Label className="ft-16">{Helpers.translate('City',this.state.translations.onboarding,'onboarding')} <i className="ft-11 fas fa-asterisk text-danger"></i></Form.Label>
						    	<Form.Control className={this.state.errors.city ? 'border-error' : ''} type="text" value={this.state.property_location.city || ''} name="city" onChange={(e)=>this.updatePropertyLocationValue(e,'city')} placeholder={Helpers.translate('City',this.state.translations.onboarding,'onboarding')} autoComplete="off"/>
						  		{this.state.errors.city && <p className="text-danger mt-1">{this.state.alertsField.city}</p>}
						  	</Form.Group>
						  	<Form.Group>
						    	<Form.Label className="ft-16">{Helpers.translate('Zip',this.state.translations.onboarding,'onboarding')}</Form.Label>
						    	<Form.Control type="text" value={this.state.property_location.zip || ''} name="zip" onChange={(e)=>this.updatePropertyLocationValue(e,'zip')} placeholder={Helpers.translate('Zip',this.state.translations.onboarding,'onboarding')} autoComplete="off"/>
						  	</Form.Group>
						  	<Form.Group>
						    	<Form.Label className="ft-16">{Helpers.translate('County',this.state.translations.onboarding,'onboarding')}</Form.Label>
						    	<Form.Control type="text" value={this.state.property_location.county || ''} name="county" onChange={(e)=>this.updatePropertyLocationValue(e,'county')} placeholder={Helpers.translate('County',this.state.translations.onboarding,'onboarding')} autoComplete="off"/>
						  	</Form.Group>
						  	<Form.Group>
						    	<Form.Label className="ft-16">{Helpers.translate('Country',this.state.translations.onboarding,'onboarding')} <i className="ft-11 fas fa-asterisk text-danger"></i></Form.Label>
						    	<Select options={this.state.countries}
						    		value={this.state.property_location.country && this.state.property_location.country.length ? this.state.property_location.country : []} 
						    		className={"selectize "+(this.state.errors.country ? 'border-error' : '')}
	          						isClearable={true}
	          						isSearchable={true}
	          						placeholder={Helpers.translate('Select',this.state.translations.general,'general')}
						    		getOptionLabel={option => option.name}
						    		getOptionValue={option => option.code}
						    		onChange={(value)=>this.updatePropertyLocationValue(value,'country')}
						    		onInputChange={(str,action)=>this.countrySearch(str,action.action)}
						    	/>
						    	{this.state.errors.country && <p className="text-danger mt-1">{this.state.alertsField.country}</p>}
						  	</Form.Group>
						</Form>
					</div>				
					{ this.state.map_address && 
						<div className="col-sm-6 d-inline-block">
							<div>
								<Iframe title="map_address" height="350" src={this.state.map_address}/>
							</div>
							<div className="text-left mb-2">
								<Button variant="secondary" onClick={()=>this.updateMap()}>{Helpers.translate('Update map',this.state.translations.general,'general')}</Button>
							</div>
						</div>					
					}
				</div>			
				<div className="col-sm-12 text-left float-left">
					<Button variant="info" onClick={()=>this.saveLocationData()}>{Helpers.translate('Continue',this.state.translations.general,'general')}</Button>
				</div>					
			</div>
		}
		let publish_data;
		if(this.state.step === 'publish'){
			publish_data=<div>
				<div className="page-header">
					<div className="col-sm-12 text-left">
		                <h1>{Helpers.translate('Property language',this.state.translations.onboarding,'onboarding')}</h1>
		            </div>
		        </div>
		        <div className="col-sm-8 text-left mt-4">
					<Form>
						<Form.Group>
					    	<Form.Label className="ft-16">{Helpers.translate('Primary language',this.state.translations.general,'general')} <i className="ft-11 fas fa-asterisk text-danger"></i></Form.Label>				    	
					    	<Select options={this.state.languages}
					    		value={this.state.property_publish.language && this.state.property_publish.language.length ? this.state.property_publish.language : []}
					    		className={"selectize "+(this.state.errors.language ? 'border-error' : '')}
					    		isClearable={true}
          						isSearchable={true}
					    		getOptionLabel={option => option.name}
					    		getOptionValue={option => option.code}
					    		onChange={(value)=>this.updatePropertyPublishValue(value,'language')}
					    	/>
					    	{this.state.errors.language && <p className="text-danger mt-1">{this.state.alertsField.language}</p>}
					  	</Form.Group>
					</Form>
				</div>
				<div className="col-sm-12 text-left">
					<Button variant="info" onClick={()=>this.publishProperty()}>{Helpers.translate('Publish',this.state.translations.general,'general')}</Button>
				</div>
		    </div>
		}   
		return (		
			<div className="content">
				{generalErrors}
				{uploadPictures}
				{location_data}
				{publish_data}
		   	</div>
		)
	}
}
export default withRouter(Onboarding);
