import React from 'react';

export default class Iframe extends React.Component{

	render(){
		return (
			<iframe title={this.props.title} frameBorder="0" style={{ border : "0" }} width={ (this.props.width ? this.props.width : "100%") } height={this.props.height} src={this.props.src} allowFullScreen={true}></iframe>
		)
	}

}