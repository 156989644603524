import React from 'react';
import Helpers from './../services/Helpers.js';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

export default class PropertySetupAttachedPictures extends React.Component{

	removeItem(index){
		confirmAlert({
		  customUI: ({ onClose }) => {
		    return (
		      <div className='custom-ui'>
		        <h1>{Helpers.translate('Confirm',this.props.translations.general,'general')}</h1>
		        <p>{Helpers.translate('Are you sure you want to delete selected line?',this.props.translations.general,'general')}</p>
		        <button onClick={onClose}>{Helpers.translate('No',this.props.translations.general,'general')}</button>
		        <button
		          onClick={() => {
		            this.props.removePhoto(index);
		            onClose();
		          }}
		        >
		          {Helpers.translate('Yes',this.props.translations.general,'general')}
		        </button>
		      </div>
		    );
		  }
		});
	}

	render(){
		let images=this.props.data.map((item, key)=>
				<div className="dropZoneListThumb" key={key}>
			      <div className="thumbInner pointer">
			        <img src={item.url} alt="" onClick={()=>window.open(item.url,'_blank')}/>
			      </div>
			      <div className="position-absolute delete_picture">
					<i className="fas fa-trash-alt ft-16 pointer" onClick={()=>this.removeItem(key)}></i>		      	
				   </div>
			    </div>
		)
		return (
			<div>{images}</div>
		)
	}
}