import React from 'react';
import Chart from "chart.js";

export default class LineChart extends React.Component{

	constructor(props) {
		super(props);
		this.chartRef=React.createRef();  
	}

	componentDidMount() {
        this.generateChart(false);
    }

    componentDidUpdate() {
        this.generateChart(true);
    }

    generateChart(update){
    	let lineChartRef = this.chartRef.current.getContext("2d");

        if(update){
        	lineChartRef.data=this.props.data;
        	lineChartRef.update();
        }else{
        	new Chart(lineChartRef, {
	            type: "line",
	            data: this.props.data,
	            options: {
	            	aspectRatio:(this.props.aspect_ratio ? this.props.aspect_ratio : 2),
	              	scales: {
	                	xAxes: [{
	                  		scaleLabel: {
	                    		display: true,
	                    		labelString: this.props.label_x,
	                    		fontStyle:'bold'
	                  		},
	                	}],
	                	yAxes: [{
	                  		ticks: {
	                    		beginAtZero: true
	                  		},
	                  		scaleLabel: {
	                    		display: true,
	                    		labelString: this.props.label_y,
	                    		fontStyle:'bold'
	                  		}
	               		}]
	              },
	              legend: {
	                display: this.props.display_legend
	              }
	            }
	        });
        }      
    }

	render() {
		return (
			<canvas id="lineChart" ref={this.chartRef}></canvas>
		);
	}

}