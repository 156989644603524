import React from 'react';
import Alert from 'react-bootstrap/Alert';

export default class DismissibleAlert extends React.Component{

	render(){
		let className=this.props.modal ? "alert-fixed-modal" : "alert-fixed";
		return (
			<div className="col-sm-12">
				<Alert variant={this.props.type} className={ className } onClose={() => this.props.dismissAlert(this.props.index)} dismissible>
			        <p className="mb-0" dangerouslySetInnerHTML={{__html: this.props.message}} />
			    </Alert>
		    </div>
		)
	}

}