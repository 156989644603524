import React from 'react';
import Helpers from './../services/Helpers.js';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Dropzone from 'react-dropzone';
import Form from 'react-bootstrap/Form';
import * as loadImage from 'blueimp-load-image';

export default class PropertySetupLinkPicturesModal extends React.Component{

	constructor(props) {
	  	super(props);
	  	this.state={
	  		images:{
	  			stored:[],
	  			added:[]
	  		}
	  	};
	}

	onDrop(acceptedFiles){
		if(acceptedFiles && Array.isArray(acceptedFiles)){
	    	if(acceptedFiles.length){
	    		Helpers.showLoading();
		    	this.createImage(acceptedFiles,0);
	    	}else{
	    		Helpers.showAlerts(this,{'errors':{'error':(Helpers.translate('Wrong image file',this.props.translations.general,'general'))}});
	    	}    	
	    }
	}

	createImage(images,index){
		if(index < images.length){
			let genThis=this;
			let oldState=Object.assign({},genThis.state);
			const loadImageOptions = { canvas: true };
			loadImage.parseMetaData(images[index], (data) => {
			  	if (data.exif && data.exif.get('Orientation')) {
			    	loadImageOptions.orientation = data.exif.get('Orientation');
			  	}
			  	loadImage(images[index], (canvas) => {
			  		let convertedImage=Helpers.converterDataURItoBlob(canvas.toDataURL(images[index].type));
			  		convertedImage.url=URL.createObjectURL(convertedImage);	
			  		convertedImage.checked=true;
			  		oldState.images.added.push(convertedImage);
			    	genThis.setState(oldState);
			    	setTimeout(function(){
			    		genThis.createImage(images,index+1);
			    	},100); 			    	
			  	}, loadImageOptions)
			});
		}else{
			Helpers.stopLoading();
		}		
	}

	checkSavedPicture(value,index){
		let oldState=Object.assign({}, this.state);
		oldState.images.stored[index]=value;
		this.setState(oldState);
	}

	checkAddedPicture(value,index){
		let oldState=Object.assign({}, this.state);
		oldState.images.added[index].checked=value;
		this.setState(oldState);
	}

	savePictures(){
		let images=Object.assign({}, this.state.images);
		for(let x in images.added){
			if(!images.added[x].checked){
				URL.revokeObjectURL(images.added[x].url);
			}		
		}
		this.props.updateImages(images);
	}

	closeModal(){
		for(let x in this.state.images.added){
			if(!this.state.images.added[x].checked){
				URL.revokeObjectURL(this.state.images.added[x].url);
			}		
		}
		this.props.onHide();
	}

	render(){
		return(
			<Modal
				show={this.props.show}
		      	size="lg"
		      	aria-labelledby="contained-modal-title-vcenter"
		      	centered
		      	backdrop="static"
		      	onHide={()=>this.closeModal()}
		    >
		    	<Modal.Header closeButton></Modal.Header>
		      	<Modal.Body>
		        	{this.props.pictures.length>0 && <h4>{Helpers.translate('Link existing images',this.props.translations.general,'general')}</h4>}
		        	<div>
		        		{
		        			this.props.pictures.map((item, key)=>
		        				<div className="dropZoneListThumb" key={key}>
							      <div className="thumbInner pointer">
							        <img src={item.url} alt="" onClick={()=>this.checkSavedPicture(!this.state.images.stored[key],key)}/>
							      </div>
							      <div className="position-absolute">
							      	<Form.Group controlId={"saved_check_"+key}>
									    <Form.Check 
									    type="checkbox" 
									    custom 
									    label="" 
									    onChange={(e)=>this.checkSavedPicture(e.target.checked,key)} 
									    checked={this.state.images.stored[key] && this.state.images.stored[key] === true ? 'checked' : ''}
									    />
									</Form.Group>
							      </div>
							    </div>
		        			)
		        		}	        		
		        	</div>
		        	<div className="dropZone mt-4">
	        			<Dropzone onDrop={(acceptedFiles)=>this.onDrop(acceptedFiles)} accept={'image/*'}>
						  {({getRootProps, getInputProps}) => (
						    <section>
						      <div {...getRootProps()} className="dropZone_container">
						        <input {...getInputProps()} />
						        <p>{Helpers.translate('Drag and drop files here or click to select file',this.props.translations.general,'general')}</p>
						      </div>
						    </section>
						  )}
						</Dropzone>       		
	            	</div>
	            	<div className="mt-2">
	            		{
	            			this.state.images.added.map((item, key)=>
		        				<div className="dropZoneListThumb" key={key}>
							      <div className="thumbInner pointer">
							        <img src={item.url} alt="" onClick={()=>window.open(item.url,'_blank')}/>
							      </div>
							      <div className="position-absolute">
							      	<Form.Group controlId={"added_check_"+key}>
									    <Form.Check 
									    type="checkbox" 
									    custom 
									    label="" 
									    onChange={(e)=>this.checkAddedPicture(e.target.checked,key)} 
									    checked={this.state.images.added[key] && this.state.images.added[key]['checked'] && this.state.images.added[key]['checked'] === true ? 'checked' : ''}
									    />
									</Form.Group>
							      </div>
							    </div>
		        			)
	            		}
	            	</div>
		      	</Modal.Body>
		      	<Modal.Footer>
		        	<Button onClick={()=>this.savePictures()}>{Helpers.translate('Save',this.props.translations.general,'general')}</Button>
		      	</Modal.Footer>
		    </Modal>
		)
	}
}
