import React from 'react';
import Helpers from './../services/Helpers.js';
import PropertySetupAttachedPictures from './PropertySetupAttachedPictures.js';

export default class PropertyRegularSpace extends React.Component{

	render(){
		return(
			<div className="d-inline col-sm-6">
				<div>
					<i className="fas fa-minus-circle ft-30 text-info mr-4 pointer" onClick={()=>this.props.updatePropertySetup('minus')}></i>
					<span className="ft-30">{this.props.property_setup[this.props.item.type] && this.props.property_setup[this.props.item.type]['number'] ? this.props.property_setup[this.props.item.type]['number'] : 0}</span>
					<i className="fas fa-plus-circle ft-30 text-info ml-4 pointer" onClick={()=>this.props.updatePropertySetup('plus')}></i>
					<i className="hidden far fa-images ft-30 pointer ml-5" onClick={()=>this.props.openLinkImageModal()} title={Helpers.translate('Add images',this.props.translations.general,'general')}></i>           					
				</div>
				{this.props.property_setup[this.props.item.type] && this.props.property_setup[this.props.item.type]['images'] && this.props.property_setup[this.props.item.type]['images'].length>0 && 
				<PropertySetupAttachedPictures data={this.props.property_setup[this.props.item.type]['images']} translations={this.props.translations} removePhoto={(index)=>this.props.removePhoto(index)} setAsBanner={(index)=>this.props.setAsBanner(index)} />}			
			</div>
		);
	}
}