import React from 'react';
import Helpers from './../services/Helpers.js';
import Button from 'react-bootstrap/Button';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import PropertySetupAttachedPictures from './PropertySetupAttachedPictures.js';

export default class PropertyOtherSpaces extends React.Component{

	removeItem(index){
		confirmAlert({
		  customUI: ({ onClose }) => {
		    return (
		      <div className='custom-ui'>
		        <h1>{Helpers.translate('Confirm',this.props.translations.general,'general')}</h1>
		        <p>{Helpers.translate('Are you sure you want to delete selected line?',this.props.translations.general,'general')}</p>
		        <button onClick={onClose}>{Helpers.translate('No',this.props.translations.general,'general')}</button>
		        <button
		          onClick={() => {
		            this.props.removePropertySetupOther(index);
		            onClose();
		          }}
		        >
		          {Helpers.translate('Yes',this.props.translations.general,'general')}
		        </button>
		      </div>
		    );
		  }
		});
	}

	render(){
		let otherSpaces;
		if(this.props.property_setup){
			otherSpaces=this.props.property_setup.map((item, key)=>
				<div className="row mt-2" key={key}>
					<div className="col-sm-6">
						<input className="form-control" type="text" value={item.name} onChange={(e)=>this.props.updatePropertySetupOther(key,'name',e.target.value)}/>
					</div>
					<div className="d-inline col-sm-6">
						<i className="fas fa-minus-circle ft-30 text-info mr-4 pointer" onClick={()=>this.props.updatePropertySetupOther(key,'number','minus')}></i>
						<span className="ft-30">{item.number}</span>
						<i className="fas fa-plus-circle ft-30 text-info ml-4 pointer" onClick={()=>this.props.updatePropertySetupOther(key,'number','plus')}></i>					
						<i className="hidden far fa-images ft-30 pointer ml-5" onClick={()=>this.props.openLinkImageModal(key)} title={Helpers.translate('Add images',this.props.translations.general,'general')}></i>
    					<i className="fas fa-trash-alt ft-16 text-danger pointer float-right mt-3" onClick={()=>this.removeItem(key)}></i>		            					
					</div>
					{item['images'] && item['images'].length>0 && 
					<div className="col-sm-12"><PropertySetupAttachedPictures data={item['images']} translations={this.props.translations} removePhoto={(photoIndex)=>this.props.removePhoto(photoIndex,key)} setAsBanner={(photoIndex)=>this.props.setAsBanner(photoIndex,key)}/></div>}
				</div>
			);
		}		
		return (
			<div className="col-sm-9">
				<div>
					<Button variant="info mt-2" onClick={()=>this.props.addOtherSpace()}>{Helpers.translate('Add',this.props.translations.general,'general')}</Button>
				</div>
				{otherSpaces}
			</div>
		)
	}

}