import React from 'react';
import Helpers from './../services/Helpers.js';
import './css/PropertyCalendar.css';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import FormValidation from './../services/FormValidation.js';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

export default class PropertyCalendar extends React.Component{

	constructor(props) {
	  	super(props);
	  	this.state={
	  		errors:{},
	  		success:{},
		  	alerts:[],  	
	      	alertsField:[],
	      	calendar:{},
	      	month_counter:0,
	  		rightPanelOpen:false,
	  		selectionStep:0,
	  		startPoint:{ "row":0, "index":0 },
	  		endPoint:{ "row":0, "index":0 },
	  		actionPrice:''
	  	};
	  	this.validation_price={
      		"actionPrice":["float"],
      	};
	}

	componentDidMount(){
		this.getCalendarData();
	}

	updateStateValue(value,model,parent){
        let oldState=Object.assign({}, this.state);
        if(parent){
        	oldState[parent][model]=value;
        }else{
        	oldState[model]=value;
        }     
        this.setState(oldState);  
    }

    getCalendarData(value){
    	let oldState=Object.assign({}, this.state);
    	oldState.selectionStep=0;
		oldState.startPoint={ "row":0, "index":0 };
		oldState.endPoint={ "row":0, "index":0 };
		for(let x in oldState.calendar.month){
			for(let y in oldState.calendar.month[x].week){
				oldState.calendar.month[x].week[y].selected=false;
				oldState.calendar.month[x].week[y].preselected=false;
			}
		}
    	let month_counter=parseInt(oldState.month_counter);
    	if(value){
    		month_counter+=parseInt(value);
    	}	
		Helpers.doRequest('get','property/calendar?property_id='+this.props.property_id+'&month_counter='+month_counter)
 			.then(data=>{  		 				       	                 	            	
            	oldState.calendar=data.calendar;
            	oldState.month_counter=month_counter;
            	this.setState(oldState);
 			}).catch(error=>{
            	//	
        	});
    }

    navigateMonth(value){
    	this.getCalendarData(value);
    }

	doSelection(row,index){
		let oldState=Object.assign({}, this.state);
		if(oldState.selectionStep === 2){
			return false;
		}		
		if(oldState.selectionStep === 0){
			oldState.selectionStep=1;
			oldState.startPoint.row=parseInt(row);
			oldState.startPoint.index=parseInt(index);
		}else if(oldState.selectionStep === 1){
			if(parseInt(row) < parseInt(oldState.startPoint.row)){
				return false;
			}else if(parseInt(row) === parseInt(oldState.startPoint.row)){
				if(parseInt(index) <= parseInt(oldState.startPoint.index)){
					return false;
				}
			}
			oldState.selectionStep=2;
			oldState.endPoint.row=parseInt(row);
			oldState.endPoint.index=parseInt(index);
		}
		oldState.calendar.month[row].week[index].selected=true;
		oldState.rightPanelOpen=true;
		this.setState(oldState);
	}

	showSelectionInterval(row,index){
		if(this.state.selectionStep === 1){
			let oldState=Object.assign({}, this.state);
			for(let x in oldState.calendar.month){
				for(let y in oldState.calendar.month[x].week){
					if(parseInt(x) === parseInt(oldState.startPoint.row)){
						if(parseInt(row) === parseInt(oldState.startPoint.row)){
							if(parseInt(y)>parseInt(oldState.startPoint.index) && parseInt(y)<parseInt(index)){
								oldState.calendar.month[x].week[y].preselected=true;
							}else{
								oldState.calendar.month[x].week[y].preselected=false;
							}
						}else if(parseInt(row) > parseInt(oldState.startPoint.row) && parseInt(y)>parseInt(oldState.startPoint.index)){
							oldState.calendar.month[x].week[y].preselected=true;
						}else{
							oldState.calendar.month[x].week[y].preselected=false;
						}					
					}else if(parseInt(x) > parseInt(oldState.startPoint.row) && parseInt(x)<parseInt(row)){
						oldState.calendar.month[x].week[y].preselected=true;
					}else if(parseInt(x) > parseInt(oldState.startPoint.row) && parseInt(x) === parseInt(row)){
						if(parseInt(y)<parseInt(index)){
							oldState.calendar.month[x].week[y].preselected=true;
						}else{
							oldState.calendar.month[x].week[y].preselected=false;
						}
					}else{
						oldState.calendar.month[x].week[y].preselected=false;
					}
				}
			}
			this.setState(oldState);
		}
	}

	resetSelectionInterval(){
		if(this.state.selectionStep === 1){
			let oldState=Object.assign({}, this.state);
			for(let x in oldState.calendar.month){
				for(let y in oldState.calendar.month[x].week){
					if(parseInt(x) === parseInt(oldState.startPoint.row)){
						if(parseInt(y) !== parseInt(oldState.startPoint.index)){
							oldState.calendar.month[x].week[y].preselected=false;
						}
					}else{
						oldState.calendar.month[x].week[y].preselected=false;
					}	
				}
			}
			this.setState(oldState);
		}
	}

	newSelection(bool){
		let oldState=Object.assign({}, this.state);
		oldState.selectionStep=0;
		oldState.startPoint={ "row":0, "index":0 };
		oldState.endPoint={ "row":0, "index":0 };
		for(let x in oldState.calendar.month){
			for(let y in oldState.calendar.month[x].week){
				oldState.calendar.month[x].week[y].selected=false;
				oldState.calendar.month[x].week[y].preselected=false;
			}
		}
		oldState.actionPrice='';
		if(bool){
			oldState.rightPanelOpen=false;
		}	
		this.setState(oldState);
	}

	blockItems(bool){
		let blockItemsData=[];
		let oldState=Object.assign({}, this.state);
		for(let x in oldState.calendar.month){
			for(let y in oldState.calendar.month[x].week){
				if(oldState.selectionStep === 1){
					if(parseInt(x) === parseInt(oldState.startPoint.row) && parseInt(y) === parseInt(oldState.startPoint.index)){
						oldState.calendar.month[x].week[y].blocked=bool;
						blockItemsData.push({'tmsp':oldState.calendar.month[x].week[y].tmsp,'blocked':(bool ? '1' : '0')});
					}
				}else if(oldState.selectionStep === 2){
					if(parseInt(x) === parseInt(oldState.startPoint.row)){
						if(parseInt(x) === parseInt(oldState.endPoint.row)){
							if(parseInt(y) >= parseInt(oldState.startPoint.index) && parseInt(y) <= parseInt(oldState.endPoint.index)){
								oldState.calendar.month[x].week[y].blocked=bool;
								blockItemsData.push({'tmsp':oldState.calendar.month[x].week[y].tmsp,'blocked':(bool ? '1' : '0')});
							}
						}else{
							if(parseInt(y) >= parseInt(oldState.startPoint.index)){
								oldState.calendar.month[x].week[y].blocked=bool;
								blockItemsData.push({'tmsp':oldState.calendar.month[x].week[y].tmsp,'blocked':(bool ? '1' : '0')});
							}
						}								
					}else if(parseInt(x) > parseInt(oldState.startPoint.row) && parseInt(x) < parseInt(oldState.endPoint.row)){
						oldState.calendar.month[x].week[y].blocked=bool;
						blockItemsData.push({'tmsp':oldState.calendar.month[x].week[y].tmsp,'blocked':(bool ? '1' : '0')});
					}else if(parseInt(x) > parseInt(oldState.startPoint.row) && parseInt(x) === parseInt(oldState.endPoint.row)){
						if(parseInt(y) <= parseInt(oldState.endPoint.index)){
							oldState.calendar.month[x].week[y].blocked=bool;
							blockItemsData.push({'tmsp':oldState.calendar.month[x].week[y].tmsp,'blocked':(bool ? '1' : '0')});
						}
					}
				}
			}
		}
		let obj={'block_items':blockItemsData};
		obj.property_id=this.props.property_id;
		Helpers.doRequest('post','property/calendar_block',obj)
            .then(data=>{
            	this.setState(oldState);
            }).catch(error=>{
            	Helpers.showAlerts(this,error,true);
            });	
	}

	resetPrice(){
		let prices=[];
		let oldState=Object.assign({}, this.state);
		for(let x in oldState.calendar.month){
			for(let y in oldState.calendar.month[x].week){
				if(oldState.selectionStep === 1){
					if(parseInt(x) === parseInt(oldState.startPoint.row) && parseInt(y) === parseInt(oldState.startPoint.index)){
						oldState.calendar.month[x].week[y].price='';
						prices.push({'tmsp':oldState.calendar.month[x].week[y].tmsp,'price':'0'});
					}
				}else if(oldState.selectionStep === 2){
					if(parseInt(x) === parseInt(oldState.startPoint.row)){
						if(parseInt(x) === parseInt(oldState.endPoint.row)){
							if(parseInt(y) >= parseInt(oldState.startPoint.index) && parseInt(y) <= parseInt(oldState.endPoint.index)){
								oldState.calendar.month[x].week[y].price='';
								prices.push({'tmsp':oldState.calendar.month[x].week[y].tmsp,'price':'0'});
							}
						}else{
							if(parseInt(y) >= parseInt(oldState.startPoint.index)){
								oldState.calendar.month[x].week[y].price='';
								prices.push({'tmsp':oldState.calendar.month[x].week[y].tmsp,'price':'0'});
							}
						}								
					}else if(parseInt(x) > parseInt(oldState.startPoint.row) && parseInt(x) < parseInt(oldState.endPoint.row)){
						oldState.calendar.month[x].week[y].price='';
						prices.push({'tmsp':oldState.calendar.month[x].week[y].tmsp,'price':'0'});
					}else if(parseInt(x) > parseInt(oldState.startPoint.row) && parseInt(x) === parseInt(oldState.endPoint.row)){
						if(parseInt(y) <= parseInt(oldState.endPoint.index)){
							oldState.calendar.month[x].week[y].price='';
							prices.push({'tmsp':oldState.calendar.month[x].week[y].tmsp,'price':'0'});
						}
					}
				}
			}
		}
		let obj={'prices':prices};
		obj.property_id=this.props.property_id;
		Helpers.doRequest('post','property/calendar_price',obj)
            .then(data=>{
            	oldState.actionPrice='';
				this.setState(oldState);
            }).catch(error=>{
            	Helpers.showAlerts(this,error,true);
            });		
	}

	setPrice(){
		let fieldTranslations={
    		"actionPrice":Helpers.translate('Price',this.props.translations.general,'general')
    	};
    	let formErrors=FormValidation.checkValidity(this.validation_price,this.state,fieldTranslations,this.props.translations.general);
		if(formErrors.has_errors){
			Helpers.showAlerts(this,formErrors,true);
			return false;
		}
		let prices=[];
		let oldState=Object.assign({}, this.state);
		oldState.errors={};
		oldState.alertsField=[];
		for(let x in oldState.calendar.month){
			for(let y in oldState.calendar.month[x].week){
				if(oldState.selectionStep === 1){
					if(parseInt(x) === parseInt(oldState.startPoint.row) && parseInt(y) === parseInt(oldState.startPoint.index)){
						oldState.calendar.month[x].week[y].price=oldState.actionPrice;
						prices.push({'tmsp':oldState.calendar.month[x].week[y].tmsp,'price':oldState.actionPrice});
					}
				}else if(oldState.selectionStep === 2){
					if(parseInt(x) === parseInt(oldState.startPoint.row)){
						if(parseInt(x) === parseInt(oldState.endPoint.row)){
							if(parseInt(y) >= parseInt(oldState.startPoint.index) && parseInt(y) <= parseInt(oldState.endPoint.index)){
								oldState.calendar.month[x].week[y].price=oldState.actionPrice;
								prices.push({'tmsp':oldState.calendar.month[x].week[y].tmsp,'price':oldState.actionPrice});
							}
						}else{
							if(parseInt(y) >= parseInt(oldState.startPoint.index)){
								oldState.calendar.month[x].week[y].price=oldState.actionPrice;
								prices.push({'tmsp':oldState.calendar.month[x].week[y].tmsp,'price':oldState.actionPrice});
							}
						}								
					}else if(parseInt(x) > parseInt(oldState.startPoint.row) && parseInt(x) < parseInt(oldState.endPoint.row)){
						oldState.calendar.month[x].week[y].price=oldState.actionPrice;
						prices.push({'tmsp':oldState.calendar.month[x].week[y].tmsp,'price':oldState.actionPrice});
					}else if(parseInt(x) > parseInt(oldState.startPoint.row) && parseInt(x) === parseInt(oldState.endPoint.row)){
						if(parseInt(y) <= parseInt(oldState.endPoint.index)){
							oldState.calendar.month[x].week[y].price=oldState.actionPrice;
							prices.push({'tmsp':oldState.calendar.month[x].week[y].tmsp,'price':oldState.actionPrice});
						}
					}
				}
			}
		}
		let obj={'prices':prices};
		obj.property_id=this.props.property_id;
		Helpers.doRequest('post','property/calendar_price',obj)
            .then(data=>{
				this.setState(oldState);
            }).catch(error=>{
            	Helpers.showAlerts(this,error,true);
            });
	}

	render(){
		let weeks;
		if(this.state.calendar && this.state.calendar.month){
			weeks=this.state.calendar.month.map((week, key)=>
					<div className="planning_row clearfix" key={key}>
						{
							week.week.map((day,index)=>
								<div className={"planning_cell_wrap float-left "+(day.selected && day.selected === true ? "selected" : "")+(day.preselected && day.preselected === true ? " preselected" : "")} key={index} onMouseEnter={()=>this.showSelectionInterval(key,index)}>
									<div className={"planning_cell "+(day.blocked && day.blocked === true ? " blocked" : "")} onClick={()=>this.doSelection(key,index)}>
										<span className={"planning_date "+(day.not_current_month ? "text-danger" : "")}>{ day.day }</span>
										{ (day.price && day.price !== null && day.price>0 ? <span className="planning_price text-success font-weight-bold">{ day.price } €</span> : null) }
									</div>
								</div>
							)
						}					
					</div>
				)
		}	
		return(
			<div className="overflow-auto">
				<div className="mt-4 overflow-auto">
					<div className="col-sm-12 text-center">
						<div className="btn-group btn-group-calendar">
							<Button className="btn btn-square btn-plain" onClick={()=>this.navigateMonth(-1)}><i className="fas fa-chevron-left"></i></Button>
							<span className="calendar-date">{Helpers.translate(this.state.calendar.month_txt,this.props.translations.general,'general')}</span>
							<Button className="btn btn-square btn-plain" onClick={()=>this.navigateMonth(1)}><i className="fas fa-chevron-right"></i></Button>
						</div>
						<div className="d-inline ml-3">
							<p className="d-inline font-weight-bold">{ this.state.calendar.year_txt }</p>	
						</div>								
					</div>
					<div className={"float-left "+(this.state.rightPanelOpen ? "col-sm-10" : "col-sm-12") }>
						<div className="row">
							<div className="planning planning_month">
								<div className="planning_header clearfix">
									<div className="small float-left">
					                    <div><span>{Helpers.translate('Mon',this.props.translations.general,'general')}</span></div>                  
					                </div>
					                <div className="small float-left">
					                    <div><span>{Helpers.translate('Tue',this.props.translations.general,'general')}</span></div>                  
					                </div>
					                <div className="small float-left">
					                    <div><span>{Helpers.translate('Wed',this.props.translations.general,'general')}</span></div>                  
					                </div>
					                <div className="small float-left">
					                    <div><span>{Helpers.translate('Thu',this.props.translations.general,'general')}</span></div>                  
					                </div>
					                <div className="small float-left">
					                    <div><span>{Helpers.translate('Fri',this.props.translations.general,'general')}</span></div>                  
					                </div>
					                <div className="small float-left">
					                    <div><span>{Helpers.translate('Sat',this.props.translations.general,'general')}</span></div>                  
					                </div>
					                <div className="small float-left">
					                    <div><span>{Helpers.translate('Sun',this.props.translations.general,'general')}</span></div>                  
					                </div>
								</div>
								<div className="planning_body mb-4">
									{ weeks }					
								</div>
							</div>
						</div>
					</div>
					{this.state.rightPanelOpen && <div className="col-sm-2 float-left calendar_options" onMouseEnter={()=>this.resetSelectionInterval()}>
						<div className="page-header">
							<div className="col-sm-12">
				                <h2>{Helpers.translate('Actions',this.props.translations.general,'general')}</h2>
				            </div>
				        </div>
				        <div className="mt-5">
				        	<Button variant="dark" onClick={()=>this.blockItems(true)}>{Helpers.translate('Set as blocked',this.props.translations.general,'general')}</Button>
				        </div>
				        <div className="mt-2">
				        	<Button variant="light" onClick={()=>this.blockItems(false)}>{Helpers.translate('Set as unblocked',this.props.translations.general,'general')}</Button>
				        </div>
				        <div className="mt-5">
				        	<Form>
								<Form.Group controlId="priceInput">
								    <Form.Label>{Helpers.translate('Price',this.props.translations.general,'general')}</Form.Label>
								    <Form.Control type="text" placeholder={Helpers.translate('Enter price',this.props.translations.general,'general')} 
								    autoComplete="off" 
								    value={ this.state.actionPrice }
								    onChange={(e)=>this.updateStateValue(e.target.value,'actionPrice')}/>
									{this.state.errors.actionPrice && <p className="text-danger mt-1">{this.state.alertsField.actionPrice}</p>}
								</Form.Group>
							</Form>
							<div>
								<OverlayTrigger								
								    placement="left"
								    delay={{ show: 100, hide: 100 }}
								    overlay={<Tooltip>{Helpers.translate('Clear selection price',this.props.translations.general,'general')}</Tooltip>}
								  >
								    <Button className="mr-2" variant="outline-danger" onClick={()=>this.resetPrice() }><i className="fas fa-times"></i></Button>
								</OverlayTrigger>			
								<Button variant="outline-primary" onClick={()=>this.setPrice() }>{Helpers.translate('Set',this.props.translations.general,'general')}</Button>
							</div>
				        </div>
				        <div className="mt-5">
				        	<p><Button variant="outline-primary" onClick={()=>this.newSelection(false)}>{Helpers.translate('New Selection',this.props.translations.general,'general')}</Button></p>
				        	<p><Button variant="outline-dark" onClick={()=>this.newSelection(true)}>{Helpers.translate('Close',this.props.translations.general,'general')}</Button></p>
				        </div>
					</div>}			
				</div>			
			</div>
		);
	}
}