import React from 'react';
import Helpers from './../../services/Helpers.js';
import Store from './../../services/Store.js';
import Jumbotron from 'react-bootstrap/Jumbotron';
import Button from 'react-bootstrap/Button';
import Register from './../../components/Register.js';
import { withRouter } from 'react-router-dom';

class FacebookAuth extends React.Component{

	constructor(props) {
	  	super(props);
	  	this.state = { 			
	  		errors:{},
		  	alerts:[],
	      	alertsField:[],
	      	registration:{},
	      	step:null
	  	};	  	
	}

	componentDidMount(){
		if(this.props.location.search.length){
			Helpers.doRequest('get','login/facebook/callback'+this.props.location.search)
	 			.then(data=>{
	 				if(!data.main_page){
	 					let oldState=Object.assign({}, this.state);
	 					oldState.step="registration";
	 					oldState.registration=data.registration;
	 					this.setState(oldState);
	 				}else{
	 					Store.saveLoginData(data);
						window.ee.emit('storeChange');
						this.props.history.push("/"+data.main_page);
	 				}
	 			}).catch(error=>{
	            	Store.clearData();
	 				Helpers.showAlerts(this,error);		
	        	});
		}else{
			window.ee.emit('storeChange');
			this.props.history.push("/");	
		}
	}

	registrationComplete(data){
		Store.saveLoginData(data);
		window.ee.emit('storeChange');
		this.props.history.push("/"+data.main_page);
	}

	render(){
		let bodyContent;
		if(this.state.step !== null){
			bodyContent=<Register registration={this.state.registration} social_provider="Facebook" onSuccess={(data)=>this.registrationComplete(data)}/>;
		}else{
			const generalMsg = this.state.alerts.map((item, key) =>
				<Jumbotron fluid key={key}>
				  <h1>{item.msg}</h1>
				  <p>
				    <Button variant="primary" onClick={()=>this.props.history.push("/")}>Back to login</Button>
				  </p>
				</Jumbotron>
		    );
		    bodyContent=<div>{generalMsg}</div>
		}
		
		return (
			<div className="content">				
				{bodyContent}
			</div>
		);		
	}
}
export default withRouter(FacebookAuth);