import React from 'react';
import { withRouter } from 'react-router-dom';
import DismissibleAlert from './../../components/DismissibleAlert.js';
import Helpers from './../../services/Helpers.js';
import LineChart from './../../components/LineChart.js';

class Home extends React.Component{

	constructor(props) {
	  	super(props);
	  	this.state={
	  		property_id:null,
	  		errors:{},
		  	alerts:[],  	
	      	alertsField:[],
	      	translations:{},
	      	chart:{
	      		'labels':[],
            	'datasets':[
            		{
            			'data':[],
            			'borderWidth':2,
            			'borderColor':'rgb(127, 127, 168)'
            		}
            	]
	      	}
	  	};
	  	this.moduleName='home';
	}

	componentDidMount(){
		let oldState=Object.assign({}, this.state);
		Helpers.doRequest('get','home')
			.then(data=>{  	       	                 	            	
        	oldState.property_id=data.property_id;
        	oldState.chart=data.chart;
        	Helpers.getTranslations(this.moduleName)
	            .then(data=>{
	            	oldState.translations=data;			                						                
	                this.setState(oldState);
	            }).catch(error=>{
	            	oldState.translations=error;
	            	this.setState(oldState);
	            });
			}).catch(error=>{
	        	//	
	    	});
	}

	componentDidUpdate(prevProps,prevState){
		if(prevProps.location.state === undefined && this.props.location.state && this.props.location.state.msg){
			Helpers.showAlerts(this,this.props.location.state.msg);
			delete this.props.location.state.msg;
		}
	}

	closeAlert(index){
		let oldState=Object.assign({}, this.state);
		oldState.alerts.splice(index,1);
		this.setState(oldState);
	}

	render(){
		const generalMsg = this.state.alerts.map((item, key) =>
	        <DismissibleAlert key={key} index={key} type={item.type} message={item.msg} dismissAlert={(e) => this.closeAlert(e)}/>
	    );
	    return (		
			<div className="content">
				{generalMsg}
				{this.state.property_id &&
					<div>
						<div className="mt-5 col-lg-3 col-md-6 col-sm-9 col-xs-12">
							<LineChart
			                    data={this.state.chart}
			                    label_x={Helpers.translate('Months',this.state.translations.general,'general')}
			                    label_y={Helpers.translate('Values',this.state.translations.general,'general')}
			                    display_legend={true}
			                    aspect_ratio="1"
			                />
						</div>
					</div>				
				}			
		   	</div>
		)
	}
}
export default withRouter(Home);