import React from 'react';
import Helpers from './../services/Helpers.js';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import { formatDate, parseDate } from 'react-day-picker/moment';
import FormValidation from './../services/FormValidation.js';
import DismissibleAlert from './DismissibleAlert.js';
import Select from 'react-select';

export default class AccountantEditModal extends React.Component{

	constructor(props) {
	  	super(props);
	  	this.state={
	  		create:true,
	  		errors:{},
	  		success:{},
		  	alerts:[],
	      	alertsField:[],
	      	data:{},
	  	};
	}

	componentDidMount(){
		let oldState=Object.assign({}, this.state);
		Helpers.doRequest('get','financial/accountant')
 			.then(data=>{
            	oldState.data=data.data;
            	this.setState(oldState);
 			}).catch(error=>{
            	//
        	});
	}

	closeModal(){
		this.props.onHide();
	}

	updateStateValue(value,model,parent){
        let oldState=Object.assign({}, this.state);
        if(parent){
        	if(model === 'period'){
        		if(value !== null){
	        		oldState[parent][model]=[];
	        		oldState[parent][model].push(value);
	        		oldState[parent]['period_text']=value.period;
	        		oldState[parent]['period_id']=value.id;
	        	}else{
	        		oldState[parent][model]=value;
	        		oldState[parent]['period_text']='';
	        		oldState[parent]['period_id']=null;
	        	}
        	}else{
        		oldState[parent][model]=value;
        	}
        }else{
        	oldState[model]=value;
        }
        this.setState(oldState);
    }

    saveData(){
		let fieldTranslations={
    		"email":Helpers.translate('Email',this.props.translations.general,'general'),
    		"period":Helpers.translate('Period',this.props.translations.general,'general')
    	};
    	let validation_data={
      		"email":["required","email"],
      		"period":["required","not_empty"]
      	};
    	if(this.state.data.period_id === 2){
    		fieldTranslations.from_date=Helpers.translate('From',this.props.translations.general,'general');
    		fieldTranslations.to_date=Helpers.translate('To',this.props.translations.general,'general');
    		validation_data.from_date=["required"];
    		validation_data.to_date=["required"];
    	}
    	let formErrors=FormValidation.checkValidity(validation_data,this.state.data,fieldTranslations,this.props.translations.general);
		if(formErrors.has_errors){
			Helpers.showAlerts(this,formErrors,true);
			return false;
		}
		let obj=Object.assign({},this.state.data);
		if(Helpers.isValidDate(obj.from_date)){
			obj.from_date=Math.round(Helpers.localAsUtc(obj.from_date).getTime()/1000);
		}else{
			obj.from_date=Math.round(Helpers.localAsUtc(new Date(obj.from_date)).getTime()/1000);
		}
		if(Helpers.isValidDate(obj.to_date)){
			obj.to_date=Math.round(Helpers.localAsUtc(obj.to_date).getTime()/1000);
		}else{
			obj.to_date=Math.round(Helpers.localAsUtc(new Date(obj.to_date)).getTime()/1000);
		}
		if(Array.isArray(this.state.data.period) && this.state.data.period.length>0){
			obj.period=obj.period[0].id;
		}
		obj.send_invitation=this.state.data.send_invitation ? '1' : '0';
		Helpers.doRequest('post','financial/accountant',obj)
            .then(data=>{
            	this.props.onUpdate(data);
            }).catch(error=>{
            	Helpers.showAlerts(this,error,true);
            });
	}

	closeAlert(index){
		let oldState=Object.assign({}, this.state);
		oldState.alerts.splice(index,1);
		this.setState(oldState);
	}

	render(){
		const generalMsg = this.state.alerts.map((item, key) =>
	        <DismissibleAlert modal="true" key={key} index={key} type={item.type} message={item.msg} dismissAlert={(e) => this.closeAlert(e)}/>
	    );
		return(
			<Modal
				show={this.props.show}
		      	size="medium"
		      	aria-labelledby="contained-modal-accountant-edit"
		      	centered
		      	backdrop="static"
		      	onHide={()=>this.closeModal()}
		    >
		    	<Modal.Header closeButton>
		    		<Modal.Title>{ this.props.title }</Modal.Title>
		    	</Modal.Header>
		      	<Modal.Body>
		      		{generalMsg}
							<div className="row justify-content-md-center">
								<div className="col-md-10">
		      			<Form className="form-control-Modal-Accountant">
		      				<Form.Group >
						    	<Form.Label className="ft-16">{Helpers.translate('Period',this.props.translations.general,'general')}</Form.Label>
						    	<Select options={this.state.data.periods}
						    		value={this.state.data.period && this.state.data.period.length ? this.state.data.period : []}
										components={{ IndicatorSeparator: () => null }}
						    		className={"selectize "+(this.state.errors.period ? 'border-error' : '')}
	          						isClearable={true}
	          						isSearchable={true}
	          						placeholder={Helpers.translate('Select',this.props.translations.general,'general')}
						    		getOptionLabel={option => option.name}
						    		getOptionValue={option => option.id}
						    		onChange={(value)=>this.updateStateValue(value,'period','data')}
						    	/>
						    	{this.state.errors.period && <p className="text-danger mt-1">{this.state.alertsField.period}</p>}
						  	</Form.Group>
						  	{ this.state.data.period_id < 2 &&
						  	<div>
						  		<Form.Group>
							    	<Form.Label className="ft-16">{this.state.data.period_text}</Form.Label>
							  	</Form.Group>
						  	</div>
						  	}
						  	{this.state.data.period_id === 2 &&
						  	<div>
								<div className="row" >
									<div className="col align-self-start">
							  	<Form.Group>
							    	<Form.Label className="ft-16">{Helpers.translate('From',this.props.translations.general,'general')}</Form.Label>
							    	<div>
							    		<DayPickerInput
												format= "DD/MM/YYY"
							    			value={ this.state.data.from_date }
								    		placeholder="D/M/YYYY"
								  			dayPickerProps={{ firstDayOfWeek: 1, showOutsideDays:true }}
								  			formatDate={formatDate}
		        							parseDate={parseDate}
		        							inputProps={{ className:"form-control", readOnly: true }}
		        							onDayChange={(selectedDay, modifiers, dayPickerInput)=>this.updateStateValue(selectedDay,'from_date','data')}
		        						/>
							    	</div>
							    	{this.state.errors.from_date && <p className="text-danger mt-1">{this.state.alertsField.from_date}</p>}
							  	</Form.Group>
								</div>
								<div className="col align-self-end">
							  	<Form.Group>
							    	<Form.Label className="ft-16">{Helpers.translate('To',this.props.translations.general,'general')}</Form.Label>
							    	<div>
							    		<DayPickerInput
											format= "DD/MM/YYY"
							    			value={ this.state.data.to_date }
								    		placeholder="D/M/YYYY"
								  			dayPickerProps={{ firstDayOfWeek: 1, showOutsideDays:true }}
								  			formatDate={formatDate}
		        							parseDate={parseDate}
		        							inputProps={{ className:"form-control", readOnly: true }}
		        							onDayChange={(selectedDay, modifiers, dayPickerInput)=>this.updateStateValue(selectedDay,'to_date','data')}
		        						/>
							    	</div>
							    	{this.state.errors.to_date && <p className="text-danger mt-1">{this.state.alertsField.to_date}</p>}
							  	</Form.Group>
								</div>
								</div>
							</div>}
						  	<Form.Group>
						    	<Form.Label className="ft-16">{Helpers.translate('Email',this.props.translations.general,'general')}</Form.Label>
						    	<Form.Control type="text" value={this.state.data.email || ''} name="email" onChange={(e)=>this.updateStateValue(e.target.value,'email','data')} placeholder={Helpers.translate('Email',this.props.translations.general,'general')} autoComplete="off"/>
						  		{this.state.errors.email && <p className="text-danger mt-1">{this.state.alertsField.email}</p>}
						  	</Form.Group>
								<Form.Group>
						    	<Form.Label className="ft-15">{Helpers.translate('Accountant name',this.props.translations.financial,'financial')}:</Form.Label>
						    	<Form.Label className="ft-15 ml-2">{this.state.data.accountant_name}</Form.Label>
						  	</Form.Group>
						  	<Form.Group controlId="send_invitation">
							    <Form.Check
							    	type="checkbox"
							    	custom
							    	disabled
							    	label={Helpers.translate('Send invitation',this.props.translations.financial,'financial')}
							    	onChange={(e)=>this.updateStateValue(e.target.checked,'send_invitation','data')}
							    	checked={this.state.data.send_invitation && this.state.data.send_invitation === true ? 'checked' : ''}
							    />
							</Form.Group>
						</Form>
		      		</div>
								</div>
		      	</Modal.Body>
		      	<Modal.Footer>
		      		<Button variant="Light" onClick={()=>this.closeModal()}>{Helpers.translate('Close',this.props.translations.general,'general')}</Button>
		        	<Button className="ButtonAdd" variant="outline-secondary" onClick={()=>this.saveData()}>{Helpers.translate('Save',this.props.translations.general,'general')}</Button>
		      	</Modal.Footer>
		    </Modal>
		)
	}

}
