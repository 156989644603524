export default{
	state: {
		logged:false,
		accessToken:'',
		accessTokenExpires:'',
		name:'',
		step:'',
		main_page:''
	},
	retrieveStatus(){
		return this.state.logged;
	},
	retrieveAccessToken(){
		return this.state.accessToken;
	},
	retrieveRefreshToken(){
		return this.state.refreshToken;
	},
	retrieveUserName(){
		return this.state.name;
	},
	retrieveStep(){
		return this.state.step;
	},
	retrieveMainPage(){
		return this.state.main_page;
	},
	setStep(step){
		this.state.step=step;
		let storedData=JSON.parse(localStorage.HRData);
		storedData.step=step;
		localStorage.setItem('HRData',JSON.stringify(storedData));
	},
	setMainPage(page){
		this.state.main_page=page;
		let storedData=JSON.parse(localStorage.HRData);
		storedData.main_page=page;
		localStorage.setItem('HRData',JSON.stringify(storedData));
	},
	saveLoginData(payload){
		this.state.logged=true;
		this.state.accessToken=payload.token;
		this.state.accessTokenExpires=parseInt(payload.expiresIn);
		this.state.name=payload.name;
		this.state.step=payload.step;
		this.state.main_page=payload.main_page;
		localStorage.setItem('HRData',JSON.stringify(payload));
	},
	updateLoginData(payload,bool){
		this.state.logged=true;
		this.state.accessToken=payload.token;
		this.state.accessTokenExpires=parseInt(payload.expiresIn);
		this.state.name=payload.name;
		this.state.step=payload.step;
		this.state.main_page=payload.main_page;
		if(bool){
			localStorage.setItem('HRData',JSON.stringify(payload));
		}	
	},
	clearData(state){
		this.state.logged=false;
		this.state.accessToken='';
		this.state.accessTokenExpires='';
		this.state.name='';
		this.state.step='';
		this.state.main_page='';
		localStorage.removeItem('HRData');
	}
}
