import React from 'react';
import Helpers from './../services/Helpers.js';
import FormValidation from './../services/FormValidation.js';
import DismissibleAlert from './DismissibleAlert.js';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Select from 'react-select';

export default class Register extends React.Component{

	constructor(props) {
	  	super(props);
	  	this.state = { 			
	  		errors:{},
		  	alerts:[],
		  	success:{},
	      	alertsField:[],
	      	registration:this.props.registration,
	      	translations:{"general":{}},
	      	countryLoading:false
	  	};
	  	this.validations={
	  		"name":["required"],
	  		"country":["required"],
	  		"phone_number":["required"]
	  	};   	
	}

	updateStateValue(value,model,parent){
        let oldState=Object.assign({}, this.state);      
        if(parent){
        	if(model==='country'){
        		if(value !== null){
	        		oldState[parent][model]=[];
	        		oldState[parent][model].push(value);
	        	}else{
	        		oldState[parent][model]=value;
	        	}
        	}else{
        		oldState[parent][model]=value;    
        	}      	   	
        }else{
        	oldState[model]=value;
        }   
        this.setState(oldState);  
    }

    countrySearch(search,action){
    	if(action === 'input-change'){
    		if(this.searchPromise){
	            clearTimeout(this.searchPromise);
	        }
	        let oldState=Object.assign({}, this.state);
	        this.searchPromise=setTimeout(()=>{  
	        	this.setState({countryLoading:true}) 
	            Helpers.doRequest('get','countries?search='+search,{},true)
		            .then(data=>{
		            	oldState.countryLoading=false;
		                oldState.registration.countries=data.countries;
		                this.setState(oldState);
		            }).catch(error=>{});
		    },500);
	    }
    }

	saveRegistration(){
		let fieldTranslations={
    		"name":"This",
    		"country":"This",
    		"phone_number":"This"
    	};
		let formErrors=FormValidation.checkValidity(this.validations,this.state.registration,fieldTranslations,this.state.translations.general,true);
		if(formErrors.has_errors){
			Helpers.showAlerts(this,formErrors,true);
			return false;
		}
		let obj=Object.assign({},this.state.registration);
		obj.country=obj.country[0].code;
		Helpers.doRequest('post','register',obj)
            .then(data=>{
            	this.props.onSuccess(data);
            }).catch(error=>{
            	Helpers.showAlerts(this,error,true);
            });
	}

	render(){
		const generalMsg = this.state.alerts.map((item, key) =>
			<DismissibleAlert key={key} index={key} type={item.type} message={item.msg} dismissAlert={(e) => Helpers.closeAlert(this,e)}/>
	    );
		return (
			<div className="page-login registration">
				<div className="page-login-vertical">
					<div className="page-login-box">
						<div className="row">
							<div className="col-sm-12">
								<h4 className="text-center">
									<p>Create your account</p>
								</h4>
							</div>
						</div>
						<div className="row">
							<div className="page-login-panel">
								{generalMsg}
								<Form className="text-left">
									<Form.Group>
								    	<Form.Label className="ft-16">
								    		<span>We'll use your details from <span>{this.props.social_provider}</span> to create an account for:</span>
								    	</Form.Label>
										<Form.Label className="ft-16 font-weight-bold">{this.state.registration.email}</Form.Label>
								  	</Form.Group>	
									<Form.Group>
								    	<Form.Label className="ft-16">Name <i className="ft-11 fas fa-asterisk text-danger"></i></Form.Label>
										<Form.Control className="pl-0" type="text" value={this.state.registration.name || ''} 
										name="name" placeholder="Name" onChange={(e)=>this.updateStateValue(e.target.value,'name','registration')} autoComplete="off"/>
								  		{this.state.errors.name && <p className="text-danger mt-1">{this.state.alertsField.name}</p>}
								  	</Form.Group>
								  	<Form.Group>
								    	<Form.Label className="ft-16">Address</Form.Label>
										<Form.Control className="pl-0" type="text" value={this.state.registration.address || ''} 
										name="address" placeholder="Address" onChange={(e)=>this.updateStateValue(e.target.value,'address','registration')} autoComplete="off"/>
								  	</Form.Group>
								  	<Form.Group>
								    	<Form.Label className="ft-16">Country <i className="ft-11 fas fa-asterisk text-danger"></i></Form.Label>
										<Select options={this.state.registration.countries}
								    		value={this.state.registration.country && this.state.registration.country.length ? this.state.registration.country : []} 
								    		className={"selectize "+(this.state.errors.country ? 'border-error' : '')}
			          						isClearable={true}
			          						isSearchable={true}
			          						isLoading={this.state.countryLoading}
			          						placeholder="Select"
								    		getOptionLabel={option => option.name}
								    		getOptionValue={option => option.code}
								    		onChange={(value)=>this.updateStateValue(value,'country','registration')}
								    		onInputChange={(str,action)=>this.countrySearch(str,action.action)}
								    	/>
								  		{this.state.errors.country && <p className="text-danger mt-1">{this.state.alertsField.country}</p>}
								  	</Form.Group>
								  	<Form.Group>
								    	<Form.Label className="ft-16">Phone Number <i className="ft-11 fas fa-asterisk text-danger"></i></Form.Label>
										<Form.Control className="pl-0" type="text" value={this.state.registration.phone_number || ''} 
										name="phone_number" placeholder="Phone Number" onChange={(e)=>this.updateStateValue(e.target.value,'phone_number','registration')} autoComplete="off"/>
								  		{this.state.errors.phone_number && <p className="text-danger mt-1">{this.state.alertsField.phone_number}</p>}
								  	</Form.Group>
								  	<Form.Group controlId="accept_terms">
									    <Form.Check 
									    	type="checkbox" 
									    	custom 
									    	label={<span>By creating an account I accept <span className="text-info">Terms</span> & <span className="text-info">Privacy Policy</span></span>} 
									    	onChange={(e)=>this.updateStateValue(e.target.checked,'accept_terms','registration')} 
									    	checked={this.state.registration.accept_terms && this.state.registration.accept_terms === true ? 'checked' : ''}
									    />
									    {this.state.errors.accept_terms && <p className="text-danger mt-1">{this.state.alertsField.accept_terms}</p>}
									</Form.Group>							  	
								</Form>
								<div className="col-sm-12 mt-4">
									<Button type="submit" onClick={()=>this.saveRegistration()} disabled={!this.state.registration.accept_terms}>Create your account</Button>
								</div>
							</div>
						</div>
					</div>
			   	</div>
		   	</div>
		)		
	}

}