import React from 'react';
import Helpers from './../services/Helpers.js';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import CreatableSelect from 'react-select/creatable';
import Form from 'react-bootstrap/Form';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import { formatDate, parseDate } from 'react-day-picker/moment';
import FormValidation from './../services/FormValidation.js';
import DismissibleAlert from './DismissibleAlert.js';

export default class RevenueEditModal extends React.Component{

	constructor(props) {
	  	super(props);
	  	this.state={
	  		create:true,
	  		errors:{},
	  		success:{},
		  	alerts:[],
	      	alertsField:[],
	      	revenue:{},
	      	loadingSelectGuest:false
	  	};
	  	this.validation_revenue={
      		"guest":["required","not_empty"],
      		"from_date":["required"],
      		"to_date":["required"],
      		"amount":["required"]
      	};
	}

	componentDidMount(){
		let oldState=Object.assign({}, this.state);
		Helpers.doRequest('get','financial/revenue?property_id='+this.props.property_id+"&revenue_id="+this.props.revenue_id)
 			.then(data=>{
            	oldState.create=this.props.revenue_id && !isNaN(this.props.revenue_id) ? true : false;
            	oldState.revenue=data.revenue;
            	this.setState(oldState);
 			}).catch(error=>{
            	//
        	});
	}

	closeModal(){
		this.props.onHide();
	}

	updateStateValue(value,model,parent){
        let oldState=Object.assign({}, this.state);
        if(parent){
        	if(model === 'guest'){
        		if(value !== null){
	        		oldState[parent][model]=[];
	        		oldState[parent][model].push(value);
	        	}else{
	        		oldState[parent][model]=value;
	        	}
        	}else{
        		oldState[parent][model]=value;
        	}
        }else{
        	oldState[model]=value;
        }
        this.setState(oldState);
    }

    guestSearch(search,action){
    	if(action === 'input-change'){
    		this.setState({ loadingSelectGuest:true });
    		if(this.searchPromise){
	            clearTimeout(this.searchPromise);
	        }
	        let oldState=Object.assign({}, this.state);
	        this.searchPromise=setTimeout(()=>{
	            Helpers.doRequest('get','financial/guest?search='+search,{},true)
		            .then(data=>{
		                oldState.revenue.guests=data.guests;
		                oldState.loadingSelectGuest=false;
		                this.setState(oldState);
		            }).catch(error=>{});
		    },500);
	    }
    }

    createGuest(value){
    	if(this.searchPromise){
            clearTimeout(this.searchPromise);
        }
    	let tmp_option={'id':"tmp"+(new Date().getTime()),'name':value};
    	let oldState=Object.assign({}, this.state);
    	oldState.revenue.guests.push(tmp_option);
    	oldState.revenue.guest=[];
    	oldState.revenue.guest.push(tmp_option);
    	this.setState(oldState);
    }

    saveRevenue(){
		let fieldTranslations={
    		"guest":Helpers.translate('Guest',this.props.translations.financial,'financial'),
    		"from_date":Helpers.translate('From',this.props.translations.general,'general'),
    		"to_date":Helpers.translate('To',this.props.translations.general,'general'),
    		"amount":Helpers.translate('Amount',this.props.translations.financial,'financial')
    	};
    	let formErrors=FormValidation.checkValidity(this.validation_revenue,this.state.revenue,fieldTranslations,this.props.translations.general);
		if(formErrors.has_errors){
			Helpers.showAlerts(this,formErrors,true);
			return false;
		}
		let obj=Object.assign({},this.state.revenue);
		obj.property_id=this.props.property_id;
		obj.revenue_id=this.props.revenue_id;
		if(Array.isArray(obj.guest) && obj.guest.length>0){
			obj.guest_id=obj.guest[0].id;
			obj.guest_name=obj.guest[0].name;
		}
		if(Helpers.isValidDate(obj.from_date)){
			console.log(obj.from_date + " before")
			obj.from_date=Math.round(Helpers.localAsUtc(obj.from_date).getTime()/1000);
		}else{
			obj.from_date=Math.round(Helpers.localAsUtc(new Date(obj.from_date)).getTime()/1000);
		}
		if(Helpers.isValidDate(obj.to_date)){
			obj.to_date=Math.round(Helpers.localAsUtc(obj.to_date).getTime()/1000);
		}else{
			obj.to_date=Math.round(Helpers.localAsUtc(new Date(obj.to_date)).getTime()/1000);
		}
		obj.is_public=this.state.revenue.is_public ? '1' : '0';
		Helpers.doRequest('post','financial/revenue',obj)
            .then(data=>{
            	this.props.onUpdate(data);
            }).catch(error=>{
            	Helpers.showAlerts(this,error,true);
            });
	}

	closeAlert(index){
		let oldState=Object.assign({}, this.state);
		oldState.alerts.splice(index,1);
		this.setState(oldState);
	}

	render(){
		const generalMsg = this.state.alerts.map((item, key) =>
	        <DismissibleAlert modal="true" key={key} index={key} type={item.type} message={item.msg} dismissAlert={(e) => this.closeAlert(e)}/>
	    );
		return(
			<Modal
				show={this.props.show}
		      	size="medium"
		      	aria-labelledby="contained-modal-revenue-edit"
		      	centered
		      	backdrop="static"
		      	onHide={()=>this.closeModal()}
		    >
		    	<Modal.Header closeButton>
		    		<Modal.Title>{ this.props.title }</Modal.Title>
		    	</Modal.Header>
		      	<Modal.Body>
		      		{generalMsg}
							<div className="row justify-content-md-center">
								<div className="col-md-10">
		      			<Form className="form-control-Modal-Revenue">
						  	<Form.Group>
						    	<Form.Label className="ft-16">{Helpers.translate('Guest',this.props.translations.financial,'financial')}</Form.Label>
						    	<CreatableSelect options={this.state.revenue.guests}
						    		value={this.state.revenue.guest && this.state.revenue.guest.length ? this.state.revenue.guest : []}
										components={{ IndicatorSeparator: () => null }}
						    		className={"selectize "+(this.state.errors.guest ? 'border-error' : '')}
	          						isClearable={true}
	          						isLoading={this.state.loadingSelectGuest}
	          						placeholder={Helpers.translate('Select',this.props.translations.general,'general')}
						    		getOptionLabel={option => option.name}
						    		getOptionValue={option => option.id}
						    		onChange={(value)=>this.updateStateValue(value,'guest','revenue')}
						    		onInputChange={(str,action)=>this.guestSearch(str,action.action)}
						    		onCreateOption={(value)=>this.createGuest(value)}
						    		getNewOptionData={(inputValue, optionLabel) => ({
								        id: optionLabel,
								        name: Helpers.translate('Create',this.props.translations.general,'general')+" "+inputValue,
								        __isNew__: true
								    })}
						    	/>
						    	{this.state.errors.guest && <p className="text-danger mt-1">{this.state.alertsField.guest}</p>}
						  	</Form.Group>
						<div className="row" >
							<div className="col align-self-start">
						  	<Form.Group>
						    	<Form.Label className="ft-16">{Helpers.translate('From',this.props.translations.general,'general')}</Form.Label>
						    	<div>
						    		<DayPickerInput
										format="DD/MM/YYYY"
						    			value={ this.state.revenue.from_date }
							    		placeholder="D/M/YYYY"
							  			dayPickerProps={{ firstDayOfWeek: 1, showOutsideDays:true }}
							  			formatDate={formatDate}
	        							parseDate={parseDate}
	        							inputProps={{ className:"form-control", readOnly: true }}
	        							onDayChange={(selectedDay, modifiers, dayPickerInput)=>this.updateStateValue(selectedDay,'from_date','revenue')}
	        						/>
						    	</div>
						    	{this.state.errors.from_date && <p className="text-danger mt-1">{this.state.alertsField.from_date}</p>}
						  	</Form.Group>
							</div>
							<div className="col align-self-end">
						  	<Form.Group>
						    	<Form.Label className="ft-16">{Helpers.translate('To',this.props.translations.general,'general')}</Form.Label>
						    	<div>
						    		<DayPickerInput
										format="DD/MM/YYYY"
						    			value={ this.state.revenue.to_date }
							    		placeholder="D/M/YYYY"
							  			dayPickerProps={{ firstDayOfWeek: 1, showOutsideDays:true }}
							  			formatDate={formatDate }
	        							parseDate={parseDate}
	        							inputProps={{ className:"form-control", readOnly: true }}
	        							onDayChange={(selectedDay, modifiers, dayPickerInput)=>this.updateStateValue(selectedDay,'to_date','revenue')}
	        						/>
						    	</div>
						    	{this.state.errors.to_date && <p className="text-danger mt-1">{this.state.alertsField.to_date}</p>}
						  	</Form.Group>
							</div>
						</div>
						<div className="row" >
							<div className="col align-self-start">
						  	<Form.Group>
						    	<Form.Label className="ft-16">{Helpers.translate('Amount',this.props.translations.financial,'financial')}</Form.Label>
						    	<Form.Control type="number" min="0" value={this.state.revenue.amount || ''} name="amount" onChange={(e)=>this.updateStateValue(e.target.value,'amount','revenue')} placeholder={Helpers.translate('Amount',this.props.translations.financial,'financial')} autoComplete="off"/>
						  		{this.state.errors.amount && <p className="text-danger mt-1">{this.state.alertsField.amount}</p>}
						  	</Form.Group>
							</div>
						<div className="col align-self-end">
						  	<Form.Group>
						    	<Form.Label className="ft-16">{Helpers.translate('Cleaning fee',this.props.translations.financial,'financial')}</Form.Label>
						    	<Form.Control type="number" min="0" value={this.state.revenue.cleaning_fee || ''} name="cleaning_fee" onChange={(e)=>this.updateStateValue(e.target.value,'cleaning_fee','revenue')} placeholder={Helpers.translate('Cleaning fee',this.props.translations.financial,'financial')} autoComplete="off"/>
						  		{this.state.errors.cleaning_fee && <p className="text-danger mt-1">{this.state.alertsField.cleaning_fee}</p>}
						  	</Form.Group>
							</div>
						</div>
						  	<Form.Group controlId="public_availability">
							    <Form.Check className="accountCheckbox"
							    	type="checkbox"
							    	custom
							    	label={Helpers.translate('Visible for accountant',this.props.translations.financial,'financial')}
							    	onChange={(e)=>this.updateStateValue(e.target.checked,'is_public','revenue')}
							    	checked={this.state.revenue.is_public && this.state.revenue.is_public === true ? 'checked' : ''}
							    />
							</Form.Group>
						</Form>
					</div>
		      		</div>
		      	</Modal.Body>
		      	<Modal.Footer>
		      		<Button variant="Light" onClick={()=>this.closeModal()}>{Helpers.translate('Close',this.props.translations.general,'general')}</Button>
		        	<Button className="ButtonAdd" variant="outline-secondary" onClick={()=>this.saveRevenue()}>{Helpers.translate('Save',this.props.translations.general,'general')}</Button>
		      	</Modal.Footer>
		    </Modal>
		)
	}

}
