import React from 'react';
import Helpers from './../services/Helpers.js';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

export default class PropertyPhotos extends React.Component{

	constructor(props) {
    	super();
    	this.state={
    		photo_index:0,
    		photo_label:'',
    		show_label_modal:false
    	};
    	this.dragId = '';
  	}

  	imageDragStart(ev){
  		this.dragId = ev.target.id;
  	}

  	dropImage(ev){
  		ev.preventDefault();
  		const dragElement = this.dragId.split('-');
	    let dragIndex = '';
	    if (dragElement.length > 1) {
	      	dragIndex = dragElement[0];
	    }

	    const dropElement = ev.target.id.split('-');
	    let dropIndex = '';
	    if (dropElement.length > 1) {
	      	dropIndex = dropElement[0];
	    }
	    if (dragIndex !== '' && !isNaN(dragIndex) && dropIndex !== '' && !isNaN(dropIndex)) {
	    	this.props.photosReorder(dragIndex,dropIndex);
	    }
  	}

  	updateStateValue(value,model){
        let oldState=Object.assign({}, this.state);      
        oldState[model]=value;  
        this.setState(oldState);  
    }

  	openPhotoLabelModal(index){
  		let oldState=Object.assign({}, this.state); 
  		oldState.photo_index=parseInt(index);
  		oldState.photo_label=this.props.photos.images[index].label;
  		oldState.show_label_modal=true;
  		this.setState(oldState);
  	}

  	updateLabel(value){
  		let oldState=Object.assign({}, this.state); 
  		oldState.photo_label=value;
  		this.setState(oldState);
  	}

  	savePhotoLabel(){
  		this.setState({show_label_modal:false});
  		this.props.updatePhotoLabel(this.state.photo_index,this.state.photo_label);
  	}

	removeItem(index){
		if(this.props.photos.images[index].is_banner){
			confirmAlert({
			  customUI: ({ onClose }) => {
			    return (
			      <div className='custom-ui'>
			        <h1>{Helpers.translate('Info',this.props.translations.general,'general')}</h1>
			        <p>{Helpers.translate('Photo is set as banner. Change first banner photo!',this.props.translations.general,'general')}</p>
			        <button onClick={onClose}>{Helpers.translate('Close',this.props.translations.general,'general')}</button>
			      </div>
			    );
			  }
			});
		}else{
			confirmAlert({
			  customUI: ({ onClose }) => {
			    return (
			      <div className='custom-ui'>
			        <h1>{Helpers.translate('Confirm',this.props.translations.general,'general')}</h1>
			        <p>{Helpers.translate('Are you sure you want to delete selected line?',this.props.translations.general,'general')}</p>
			        <button onClick={onClose}>{Helpers.translate('No',this.props.translations.general,'general')}</button>
			        <button
			          onClick={() => {
			            this.props.deleteAvailablePhoto(index);
			            onClose();
			          }}
			        >
			          {Helpers.translate('Yes',this.props.translations.general,'general')}
			        </button>
			      </div>
			    );
			  }
			});
		}	
	}

	render(){
		let availablePhotos=this.props.photos.images.map((item, key)=>
				<div className="photosListThumb" key={key}>
			      <div id={key+"-photoParent"} className="thumbInner pointer" onDrop={(ev)=>this.dropImage(ev)} onDragOver={(ev)=>ev.preventDefault()}>
			        <img id={key+"-imageChild"} draggable={true} src={item.url} alt="" onClick={()=>this.props.openLightbox(key)} onDragStart={(ev)=>this.imageDragStart(ev)}/>
			      </div>
			      <div className="position-absolute delete_picture">
					<i className="fas fa-pencil-alt ft-16 pointer" onClick={()=>this.openPhotoLabelModal(key)}></i>		      	
				   </div>
			      <div className="position-absolute delete_picture ml-4">
					<i className="fas fa-trash-alt ft-16 pointer" onClick={()=>this.removeItem(key)}></i>		      	
				   </div>
			    </div>
			)
		return (
			<div>
				{availablePhotos}
				<Modal
					show={this.state.show_label_modal}
			      	size="lg"
			      	aria-labelledby="contained-modal-label"
			      	centered
			      	backdrop="static"
			      	onHide={()=>this.updateStateValue(false,'show_label_modal')}
			    >
			    	<Modal.Header closeButton>{Helpers.translate('Add photo label',this.props.translations.general,'general')}</Modal.Header>
				    <Modal.Body>
				    	<Form>
				    		<Form.Group>
						    	<Form.Control type="text" maxLength="191" value={this.state.photo_label} name="label" onChange={(e)=>this.updateLabel(e.target.value)} autoComplete="off"/>
						  	</Form.Group>
				    	</Form>
				    </Modal.Body>
				    <Modal.Footer>
		        		<Button onClick={()=>this.savePhotoLabel()}>{Helpers.translate('Save',this.props.translations.general,'general')}</Button>
		      		</Modal.Footer>
			    </Modal>
			</div>
		)
	}

}