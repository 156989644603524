import React from 'react';
import { withRouter } from 'react-router-dom';
import DismissibleAlert from './../../components/DismissibleAlert.js';
import Helpers from './../../services/Helpers.js';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Button from 'react-bootstrap/Button';
import FormValidation from './../../services/FormValidation.js';
import PropertyPhotos from './../../components/PropertyPhotos.js';
import PropertyOtherSpaces from './../../components/PropertyOtherSpaces.js';
import PropertySetupLinkPicturesModal from './../../components/PropertySetupLinkPicturesModal.js';
import PropertyRegularSpace from './../../components/PropertyRegularSpace.js';
import PropertyPhotosAddModal from './../../components/PropertyPhotosAddModal.js';
import Form from 'react-bootstrap/Form';
import Store from './../../services/Store.js';
import Script from 'react-load-script';
import Select from 'react-select';
import Dropzone from 'react-dropzone';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import PropertyCalendar from './../../components/PropertyCalendar.js';
import Iframe from './../../components/Iframe.js';
import * as loadImage from 'blueimp-load-image';
import Modal from 'react-bootstrap/Modal';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import FsLightbox from 'fslightbox-react';

class Property extends React.Component{

	constructor(props) {
	  	super(props);
	  	this.state={
	  		property_id:null,
	  		create:true,
	  		errors:{},
	  		success:{},
		  	alerts:[],
	      	alertsField:[],
	      	dataChange:false,
	      	translations:{},
	      	activeTab:"descriptions",
	      	descriptions:{},
	      	photos:{},
	      	update_banner_modal:false,
	      	add_photos_modal:false,
	      	language_name:null,
	      	link_images_modal:false,
	      	setup_data:{},
	      	property_stored_images:[],
	      	property_setup:{},
	      	amenities:[],
	      	property_amenities:{},
	      	g_key:'',
	      	public_g_key:'',
	      	map_address_base:'',
	      	map_address:'',
	      	gAutocompleteInput:'',
	      	property_location:{},
	      	property_location_select:[],
	      	property_location_select_value:[],
	      	countries:[],
	      	logo_links:[],
	      	property_logo:null,
	      	languages:[],
	      	property_publish:{},
	      	property_subscription:{},
	      	photosLightBoxOpen:false,
	      	photosLightBoxIndex:0
	  	};
	  	this.moduleName='property';
	  	this.validation_descriptions={
      		"property_description":["required"],
      		"area_description":["required"]
      	};
      	this.temp_image_data={'type':'','index':0};
      	this.handlePlaceChanged = this.handlePlaceChanged.bind(this);
      	this.validation_location={
      		"city":["required"],
      		"country":["required"]
      	};
      	this.validation_publish={
      		"language":["required"]
      	};
      	this.validation_subdomain={
      		"free_subdomain_input":["required"]
      	};
      	this.validation_domain={
      		"own_domain_input":["required"]
      	};
	}

	componentDidMount(){
		if(this.props.match.params.id){
			let oldState=Object.assign({}, this.state);
			if(!isNaN(parseInt(this.props.match.params.id))){
				Helpers.doRequest('get','property/descriptions?property_id='+this.props.match.params.id)
		 			.then(data=>{
		            	oldState.property_id=this.props.match.params.id;
		            	oldState.create=false;
		            	oldState.descriptions=data.descriptions;
		            	oldState.language_name=data.language_name;
		            	Helpers.getTranslations(this.moduleName)
				            .then(data=>{
				            	oldState.translations=data;
				                this.setState(oldState);
				            }).catch(error=>{
				            	oldState.translations=error;
				            	this.setState(oldState);
				            });
		 			}).catch(error=>{
		            	//
		        	});
			}else{
				oldState.property_id=this.props.match.params.id;
				oldState.create=true;
				Helpers.getTranslations(this.moduleName)
		            .then(data=>{
		            	oldState.translations=data;
		                this.setState(oldState);
		            }).catch(error=>{
		            	oldState.translations=error;
		            	this.setState(oldState);
		            });
			}

		}
	}

	componentWillUnmount(){
		this.revokePropertySetupUrls();
		this.revokePropertyPhotosUrls();
		if(this.state.property_logo !== null){
			URL.revokeObjectURL(this.state.property_logo.preview);
		}
	}

	revokePropertySetupUrls(){
		for(let x in this.state.property_setup){
			if(x === 'other' && Array.isArray(this.state.property_setup[x])){
				for(let y in this.state.property_setup[x]){
					if(this.state.property_setup[x][y]['images'] && Array.isArray(this.state.property_setup[x][y]['images'])){
						for(let z in this.state.property_setup[x][y]['images']){
							if(this.state.property_setup[x][y]['images'][z] instanceof File || this.state.property_setup[x][y]['images'][z] instanceof Blob){
								URL.revokeObjectURL(this.state.property_setup[x][y]['images'][z].url);
							}
						}
					}
				}
			}else{
				if(this.state.property_setup[x]['images']){
					for(let y in this.state.property_setup[x]['images']){
						if(this.state.property_setup[x]['images'][y] instanceof File || this.state.property_setup[x]['images'][y] instanceof Blob){
							URL.revokeObjectURL(this.state.property_setup[x]['images'][y].url);
						}
					}
				}
			}
		}
	}

	revokePropertyPhotosUrls(){
		for(let x in this.state.photos.images){
			if(this.state.photos.images[x] instanceof File || this.state.photos.images[x] instanceof Blob){
				URL.revokeObjectURL(this.state.photos.images[x].url);
			}
		}
	}

	updateStateValue(value,model,parent){
		let dataChangeExclude=['update_banner_modal','add_photos_modal','link_images_modal'];
        let oldState=Object.assign({}, this.state);
        if(parent){
        	if(model === 'font'){
        		if(value !== null){
	        		oldState[parent][model]=[];
	        		oldState[parent][model].push(value);
	        	}else{
	        		oldState[parent][model]=value;
	        	}
        	}else{
        		oldState[parent][model]=value;
        	}
        }else{
        	oldState[model]=value;
        }
        if(dataChangeExclude.indexOf(model) === -1){
        	oldState.dataChange=true;
        }
        this.setState(oldState);
    }

    confirmChangeTab(tab){
    	confirmAlert({
		  customUI: ({ onClose }) => {
		    return (
		      <div className='custom-ui'>
		        <h1>{Helpers.translate('Confirm',this.state.translations.general,'general')}</h1>
		        <p>{Helpers.translate('There are changes that will be lost without save. Do you want to continue and abort all changes?',this.state.translations.general,'general')}</p>
		        <button onClick={onClose}>{Helpers.translate('No',this.state.translations.general,'general')}</button>
		        <button
		          onClick={() => {
		            this.renderTab(tab);
		            onClose();
		          }}
		        >
		          {Helpers.translate('Yes',this.state.translations.general,'general')}
		        </button>
		      </div>
		    );
		  }
		});
    }

    renderTab(tab){
    	let oldState=Object.assign({}, this.state);
    	oldState.dataChange=false;
    	oldState.activeTab=tab;
    	oldState.errors={};
		oldState.alerts=[];
	    oldState.alertsField=[];
    	switch(tab){
    		case 'descriptions':
    			Helpers.doRequest('get','property/descriptions?property_id='+this.state.property_id)
		 			.then(data=>{
		            	oldState.descriptions=data.descriptions;
		            	oldState.language_name=data.language_name;
		            	this.setState(oldState);
		 			}).catch(error=>{
		            	//
		        	});
    			break;
    		case 'photos':
    			Helpers.doRequest('get','property/photos?property_id='+this.state.property_id)
		 			.then(data=>{
		            	oldState.photos=data.photos;
		            	this.setState(oldState);
		 			}).catch(error=>{
		            	//
		        	});
    			break;
    		case 'property_setup':
    			Helpers.doRequest('get','property/setup?property_id='+this.state.property_id)
		 			.then(data=>{
		            	oldState.setup_data=data.setup_data;
		            	oldState.property_setup=data.property_setup;
		            	oldState.property_stored_images=data.images;
		            	this.setState(oldState);
		 			}).catch(error=>{
		            	//
		        	});
    			break;
    		case 'amenities':
    			Helpers.doRequest('get','property/amenities?property_id='+this.state.property_id)
		 			.then(data=>{
		            	oldState.amenities=data.amenities;
		            	oldState.property_amenities=data.property_amenities;
		            	this.setState(oldState);
		 			}).catch(error=>{
		            	//
		        	});
    			break;
    		case 'location':
    			Helpers.doRequest('get','property/location?property_id='+this.state.property_id)
		 			.then(data=>{
		            	oldState.countries=data.countries;
		            	oldState.g_key=data.g_key;
		            	oldState.public_g_key=data.public_g_key;
		            	oldState.map_address_base=data.map_address_base;
		            	oldState.map_address=data.map_address;
		            	oldState.property_location=data.property_location;
		            	this.setState(oldState);
		 			}).catch(error=>{
		            	//
		        	});
    			break;
    		case 'logo':
    			Helpers.doRequest('get','property/logo?property_id='+this.state.property_id)
		 			.then(data=>{
		            	oldState.logo_links=data.logo_links;
		            	oldState.property_logo=data.property_logo;
		            	this.setState(oldState);
		 			}).catch(error=>{
		            	//
		        	});
    			break;
    		case 'language':
    			Helpers.doRequest('get','property/language?property_id='+this.state.property_id)
		 			.then(data=>{
		            	oldState.languages=data.languages;
		            	oldState.property_publish=data.property_publish;
		            	this.setState(oldState);
		 			}).catch(error=>{
		            	//
		        	});
    			break;
    		case 'subscription':
    			Helpers.doRequest('get','property/subscription?property_id='+this.state.property_id)
		 			.then(data=>{
		            	oldState.property_subscription=data.property_subscription;
		            	this.setState(oldState);
		 			}).catch(error=>{
		            	//
		        	});
    			break;
    		default:
    			this.setState(oldState);
    	}
    }

	closeAlert(index){
		let oldState=Object.assign({}, this.state);
		oldState.alerts.splice(index,1);
		this.setState(oldState);
	}

	saveDescriptions(){
		let fieldTranslations={
    		"property_description":Helpers.translate('Description',this.state.translations.property,'property'),
    		"area_description":Helpers.translate('Description',this.state.translations.property,'property')
    	};
    	let formErrors=FormValidation.checkValidity(this.validation_descriptions,this.state.descriptions,fieldTranslations,this.state.translations.general);
		if(formErrors.has_errors){
			Helpers.showAlerts(this,formErrors,true);
			return false;
		}
		let obj=Object.assign({},this.state.descriptions);
		obj.property_id=this.state.property_id;
		if(Array.isArray(obj.font) && obj.font.length>0){
			obj.font=obj.font[0].name;
		}
		Helpers.doRequest('post','property/descriptions',obj)
            .then(data=>{
            	let genThis=this;
            	let oldState=Object.assign({}, genThis.state);
            	oldState.dataChange=false;
            	genThis.setState(oldState);
            	setTimeout(function(){
            		Helpers.showAlerts(genThis,data,true);
            	},100);
            }).catch(error=>{
            	Helpers.showAlerts(this,error,true);
            });
	}

	openPhotosLightbox(index){
		let oldState=Object.assign({}, this.state);
		oldState.photosLightBoxIndex=index;
		oldState.photosLightBoxOpen=(oldState.photosLightBoxOpen ? false : true);
		this.setState(oldState);
	}

	updateBannerPhoto(item){
		let genThis=this;
		let oldState=Object.assign({}, this.state);
		oldState.dataChange=true;
		oldState.photos.banner=item;
		oldState.update_banner_modal=false;
		this.setState(oldState);
		setTimeout(function(){
			genThis.savePhotos();
		},100);
	}

	photosReorder(from,to){
		let genThis=this;
		let oldState=Object.assign({}, this.state);
		oldState.dataChange=true;
		const dragImage = oldState.photos.images[from];
		oldState.photos.images.splice(from, 1);
      	oldState.photos.images.splice(to, 0, dragImage);
		this.setState(oldState);
		setTimeout(function(){
			genThis.savePhotos();
		},100);
	}

	deleteAvailablePhoto(index){
		let genThis=this;
		let oldState=Object.assign({}, this.state);
		oldState.dataChange=true;
		oldState.photos.images.splice(index, 1);
		this.setState(oldState);
		setTimeout(function(){
			genThis.savePhotos();
		},100);
	}

	updatePhotoLabel(index,value){
		let genThis=this;
		let oldState=Object.assign({}, this.state);
		oldState.photos.images[index].label=value;
		this.setState(oldState);
		setTimeout(function(){
			genThis.savePhotos();
		},100);
	}

	updatePhotos(photos){
		let genThis=this;
		let oldState=Object.assign({}, this.state);
		for(let x in photos){
			oldState.photos.images.push(photos[x]);
		}
		oldState.dataChange=true;
		oldState.add_photos_modal=false;
		this.setState(oldState);
		setTimeout(function(){
			genThis.savePhotos();
		},100);
	}

	savePhotos(){
		let genThis=this;
		let formData = new FormData();
		let photosNr=0;
		formData.append("property_id", this.state.property_id);
		if(this.state.photos.banner && this.state.photos.banner.id){
			formData.append("banner", this.state.photos.banner.id);
		}
		for(let x in this.state.photos.images){
			if(this.state.photos.images[x] instanceof File || this.state.photos.images[x] instanceof Blob){
				formData.append("files[]", this.state.photos.images[x]);
			}else{
				formData.append("file_ids[]", this.state.photos.images[x].id);
				formData.append("file_labels[]", this.state.photos.images[x].label);
			}
			photosNr++;
		}
		if(!photosNr){
			Helpers.showAlerts(this,{'errors':{'error':(Helpers.translate('Do selection first',this.state.translations.general,'general'))}});
			return false;
		}
		let oReq = new XMLHttpRequest();
	    oReq.open("POST", process.env.REACT_APP_API_URL+'property/photos', true);
	    oReq.setRequestHeader("Authorization","Bearer " + Store.retrieveAccessToken());
	    Helpers.showLoading();
	    oReq.onload = function(oEvent) {
	        if(oReq.status === 200){
	        	Helpers.stopLoading();
	        	let data=JSON.parse(oEvent.target.response);
	        	genThis.revokePropertyPhotosUrls();
	        	let oldState=Object.assign({},genThis.state);
	        	oldState.photos=data.photos;
	        	oldState.dataChange=false;
	        	genThis.setState(oldState);
            	setTimeout(function(){
            		Helpers.showAlerts(genThis,data);
            	},200);
	        }else{
	        	Helpers.stopLoading();
	        	Helpers.showAlerts(genThis,JSON.parse(oEvent.target.response));
	        }
	    };
	    oReq.send(formData);
	}

	updatePropertySetup(item,operator){
		let oldState=Object.assign({}, this.state);
		if(!oldState.property_setup[item.type]){
			oldState.property_setup[item.type]={'number':0};
		}
		oldState.dataChange=true;
		oldState.property_setup[item.type].number=operator==='plus' ? parseInt(oldState.property_setup[item.type].number)+1 : (parseInt(oldState.property_setup[item.type].number)>0 ? parseInt(oldState.property_setup[item.type].number)-1 : 0);
		this.setState(oldState);
	}

	addOtherSpace(){
		let oldState=Object.assign({}, this.state);
		if(!oldState.property_setup['other']){
			oldState.property_setup['other']=[];
		}
		oldState.dataChange=true;
		oldState.property_setup['other'].push({'name':'Space','type':'other','number':0, 'images':[]});
		this.setState(oldState);
	}

	updatePropertySetupOther(index,model,data){
		let oldState=Object.assign({}, this.state);
		if(model === 'number'){
			oldState.property_setup['other'][index].number=data==='plus' ? parseInt(oldState.property_setup['other'][index].number)+1 : (parseInt(oldState.property_setup['other'][index].number)>0 ? parseInt(oldState.property_setup['other'][index].number)-1 : 0);
		}else{
			oldState.property_setup['other'][index][model]=data;
		}
		oldState.dataChange=true;
		this.setState(oldState);
	}

	removePropertySetupOther(index){
		let oldState=Object.assign({}, this.state);
		if(oldState.property_setup['other'][index]['images']){
			for(let x in oldState.property_setup['other'][index]['images']){
				if(oldState.property_setup['other'][index]['images'][x] instanceof File || oldState.property_setup['other'][index]['images'][x] instanceof Blob){
					URL.revokeObjectURL(oldState.property_setup['other'][index]['images'][x].url);
				}else{
					oldState.property_stored_images.push(oldState.property_setup['other'][index]['images'][x]);
				}
			}
		}
		oldState.dataChange=true;
		oldState.property_setup['other'].splice(index,1);
		this.setState(oldState);
	}

	openLinkImageModal(type,index){
		this.temp_image_data.type=type;
		if(index !== undefined){
			this.temp_image_data.index=parseInt(index);
		}
		let oldState=Object.assign({}, this.state);
		if(!oldState.property_setup[type] && type !== 'other'){
			oldState.property_setup[type]={'number':0};
		}
		oldState.link_images_modal=true;
		this.setState(oldState);
	}

	removePhoto(type,photoIndex,index){
		let oldState=Object.assign({}, this.state);
		if(type === 'other'){
			if(oldState.property_setup[type][index]['images'][photoIndex] instanceof File || oldState.property_setup[type][index]['images'][photoIndex] instanceof Blob){
				URL.revokeObjectURL(oldState.property_setup[type][index]['images'][photoIndex].url);
			}else{
				oldState.property_stored_images.push(oldState.property_setup[type][index]['images'][photoIndex]);
			}
			oldState.property_setup[type][index]['images'].splice(photoIndex,1);
		}else{
			if(oldState.property_setup[type]['images'][photoIndex] instanceof File || oldState.property_setup[type]['images'][photoIndex] instanceof Blob){
				URL.revokeObjectURL(oldState.property_setup[type]['images'][photoIndex].url);
			}else{
				oldState.property_stored_images.push(oldState.property_setup[type]['images'][photoIndex]);
			}
			oldState.property_setup[type]['images'].splice(photoIndex,1);
		}
		oldState.dataChange=true;
		this.setState(oldState);
	}

	updateLinkedImages(images){
		let pictureIndexesToRemove=[];
		let oldState=Object.assign({}, this.state);
		for(let x in images.stored){
			if(images.stored[x]){
				if(this.temp_image_data.type === 'other'){
					if(oldState.property_setup['other'][this.temp_image_data.index]['images'] === undefined){
						oldState.property_setup['other'][this.temp_image_data.index]['images']=[];
					}
					oldState.property_setup['other'][this.temp_image_data.index]['images'].push(oldState.property_stored_images[x]);
				}else{
					if(oldState.property_setup[this.temp_image_data.type]['images'] === undefined){
						oldState.property_setup[this.temp_image_data.type]['images']=[];
					}
					oldState.property_setup[this.temp_image_data.type]['images'].push(oldState.property_stored_images[x]);
				}
				pictureIndexesToRemove.push(parseInt(x));
			}
		}
		if(pictureIndexesToRemove.length){
			for(let x=pictureIndexesToRemove.length-1;x>=0;x--){
				oldState.property_stored_images.splice(pictureIndexesToRemove[x],1);
			}
		}
		for(let x in images.added){
			if(images.added[x].checked){
				if(this.temp_image_data.type === 'other'){
					if(oldState.property_setup['other'][this.temp_image_data.index]['images'] === undefined){
						oldState.property_setup['other'][this.temp_image_data.index]['images']=[];
					}
					oldState.property_setup['other'][this.temp_image_data.index]['images'].push(images.added[x]);
				}else{
					if(oldState.property_setup[this.temp_image_data.type]['images'] === undefined){
						oldState.property_setup[this.temp_image_data.type]['images']=[];
					}
					oldState.property_setup[this.temp_image_data.type]['images'].push(images.added[x]);
				}
			}
		}
		oldState.dataChange=true;
		oldState.link_images_modal=false;
		this.setState(oldState);
	}

	setAsBanner(type,photoIndex,index){
		let oldState=Object.assign({}, this.state);
		for(let x in oldState.property_setup){
			if(x === 'other' && Array.isArray(oldState.property_setup[x])){
				for(let y in oldState.property_setup[x]){
					for(let z in oldState.property_setup[x][y]['images']){
						oldState.property_setup[x][y]['images'][z].is_banner=(x===type && parseInt(index) === parseInt(y) && parseInt(photoIndex) === parseInt(z) ? true : false);
					}
				}
			}else{
				for(let y in oldState.property_setup[x]['images']){
					oldState.property_setup[x]['images'][y].is_banner= (x===type && parseInt(photoIndex) === parseInt(y) ? true : false);
				}
			}
		}
		oldState.dataChange=true;
		this.setState(oldState);
	}

	savePropertySetup(){
		let genThis=this;
		let formData = new FormData();
		formData.append("property_id", this.state.property_id);
		let placesSelected=0;
		for(let x in this.state.property_setup){
			if(x === 'other' && Array.isArray(this.state.property_setup[x])){
				for(let y in this.state.property_setup[x]){
					if(this.state.property_setup[x][y]['number']){
						formData.append("types["+x+"]["+y+"][number]", this.state.property_setup[x][y]['number']);
						formData.append("types["+x+"]["+y+"][name]", this.state.property_setup[x][y]['name']);
						if(this.state.property_setup[x][y]['images'] && Array.isArray(this.state.property_setup[x][y]['images'])){
							for(let z in this.state.property_setup[x][y]['images']){
								if(this.state.property_setup[x][y]['images'][z] instanceof File || this.state.property_setup[x][y]['images'][z] instanceof Blob){
									formData.append("types["+x+"]["+y+"][files][]", this.state.property_setup[x][y]['images'][z]);
									formData.append("types["+x+"]["+y+"][banner_files][]", (this.state.property_setup[x][y]['images'][z].is_banner ? 1 : 0));
								}else{
									formData.append("types["+x+"]["+y+"][file_ids][]", this.state.property_setup[x][y]['images'][z].id);
									formData.append("types["+x+"]["+y+"][banner_ids][]", (this.state.property_setup[x][y]['images'][z].is_banner ? 1 : 0));
								}
							}
						}
						placesSelected++;
					}
				}
			}else{
				if(this.state.property_setup[x]['number']){
					formData.append("types["+x+"][number]", this.state.property_setup[x]['number']);
					if(this.state.property_setup[x]['images'] && Array.isArray(this.state.property_setup[x]['images'])){
						for(let y in this.state.property_setup[x]['images']){
							if(this.state.property_setup[x]['images'][y] instanceof File || this.state.property_setup[x]['images'][y] instanceof Blob){
								formData.append("types["+x+"][files][]", this.state.property_setup[x]['images'][y]);
								formData.append("types["+x+"][banner_files][]", (this.state.property_setup[x]['images'][y].is_banner ? 1 : 0));
							}else{
								formData.append("types["+x+"][file_ids][]", this.state.property_setup[x]['images'][y].id);
								formData.append("types["+x+"][banner_ids][]", (this.state.property_setup[x]['images'][y].is_banner ? 1 : 0));
							}
						}
					}
					placesSelected++;
				}
			}
		}
		if(!placesSelected){
			Helpers.showAlerts(this,{'errors':{'error':(Helpers.translate('Do selection first',this.state.translations.general,'general'))}});
			return false;
		}
		let oReq = new XMLHttpRequest();
	    oReq.open("POST", process.env.REACT_APP_API_URL+'property/setup', true);
	    oReq.setRequestHeader("Authorization","Bearer " + Store.retrieveAccessToken());
	    Helpers.showLoading();
	    oReq.onload = function(oEvent) {
	        if(oReq.status === 200){
	        	Helpers.stopLoading();
	        	let data=JSON.parse(oEvent.target.response);
	        	genThis.revokePropertySetupUrls();
	        	let oldState=Object.assign({},genThis.state);
	        	oldState.setup_data=data.setup_data;
		        oldState.property_setup=data.property_setup;
		        oldState.property_stored_images=data.images;
		        oldState.dataChange=false;
	        	genThis.setState(oldState);
            	setTimeout(function(){
            		Helpers.showAlerts(genThis,data);
            	},500);
	        }else{
	        	Helpers.stopLoading();
	        	Helpers.showAlerts(genThis,JSON.parse(oEvent.target.response));
	        }
	    };
	    oReq.send(formData);
	}

	savePropertyAmenities(){
		let placesSelected=0;
		for(let x in this.state.property_amenities){
			if(this.state.property_amenities[x]){
				placesSelected++;
			}
		}
		if(!placesSelected){
			Helpers.showAlerts(this,{'errors':{'error':(Helpers.translate('Do selection first',this.state.translations.general,'general'))}});
			return false;
		}
		let obj={};
		obj.amenities=Object.assign({},this.state.property_amenities);
		obj.property_id=this.state.property_id;
		Helpers.doRequest('post','property/amenities',obj)
            .then(data=>{
            	let genThis=this;
            	let oldState=Object.assign({}, genThis.state);
            	oldState.dataChange=false;
            	genThis.setState(oldState);
            	setTimeout(function(){
            		Helpers.showAlerts(genThis,data);
            	},100);
            }).catch(error=>{
            	Helpers.showAlerts(this,error,true);
            });
	}

	generateMapAddress(data){
		let map_address="";
		if(data.address){
			map_address+=encodeURI(data.address)+',';
		}
		if(data.zip){
			map_address+=encodeURI(data.zip)+',';
		}
		if(data.city){
			map_address+=encodeURI(data.city)+',';
		}
		if(data.county){
			map_address+=encodeURI(data.county)+',';
		}
		if(data.country){
			map_address+=encodeURI(data.country_name)+',';
		}
		map_address=map_address.replace(/~+$/,',');
		if(map_address){
			map_address=this.state.map_address_base+"?key="+this.state.public_g_key+"&q="+map_address;
		}
		return map_address;
	}

	updateMap(){
		let oldState=Object.assign({}, this.state);
		oldState.map_address=this.generateMapAddress({address:this.state.property_location.address,city:this.state.property_location.city,zip:this.state.property_location.zip,county:this.state.property_location.county,country_name:(this.state.property_location.country && this.state.property_location.country.length ? this.state.property_location.country[0].name : '')});
		this.setState(oldState);
	}

	async checkCoordinates(){
		Helpers.showLoading();
		await navigator.geolocation.getCurrentPosition(
			position => {
				Helpers.stopLoading();
				Helpers.doRequest('get','geocode?latitude='+position.coords.latitude+'&longitude='+position.coords.longitude)
		 			.then(data=>{
		            	if(data.address_data && Array.isArray(data.address_data) && data.address_data.length){
		            		if(data.address_data.length>1){
		            			let oldState=Object.assign({}, this.state);
		            			oldState.property_location_select=data.address_data;
		            			this.setState(oldState);
		            		}else{
		            			let oldState=Object.assign({}, this.state);
		            			oldState.property_location.address=data.address_data[0].street;
		            			oldState.property_location.city=data.address_data[0].city;
		            			oldState.property_location.zip=data.address_data[0].zip;
		            			oldState.property_location.county=data.address_data[0].county;
		            			oldState.dataChange=true;
		            			if(data.address_data[0].country_name){
		            				Helpers.doRequest('get','countries?search='+data.address_data[0].country_name)
							            .then(data=>{
							                oldState.countries=data.countries;
							                if(data.countries.length){
							                	oldState.property_location.country=[data.countries[0]];
							                }
							                oldState.map_address=this.generateMapAddress({address:oldState.property_location.address,city:oldState.property_location.city,zip:oldState.property_location.zip,county:oldState.property_location.county,country_name:data.address_data[0].country_name});
							                this.setState(oldState);
							            }).catch(error=>{});
		            			}else{
		            				this.setState(oldState);
		            			}
		            		}
		            	}
		 			}).catch(error=>{
		            	Helpers.showAlerts(this,error);
		        	});
			},
			error => {
				Helpers.stopLoading();
				Helpers.showAlerts(this,{'errors':{'error':(Helpers.translate('Could not find location',this.state.translations.property,'property'))}});
			},
			{ enableHighAccuracy: true, timeout: 20000, maximumAge: 1000 }
		);
	}

	handleGScriptLoad(){
		const options = {
	      types: ['geocode','establishment'],
	    };

	    /*global google*/ // To disable any eslint 'google not defined' errors
	    this.gautocomplete = new google.maps.places.Autocomplete(
	      	document.getElementById('gautocomplete'),
	      	options,
	    );
	    this.gautocomplete.setFields(['address_components']);
	    this.gautocomplete.addListener('place_changed', this.handlePlaceChanged);
	}

	handlePlaceChanged(){
		const addressObject = this.gautocomplete.getPlace();
		if(addressObject){
			let composedAddress={'street_number':'','street':'','city':'','zip':'','county':'','country_code':'', 'country_name':''};
        	for(let x in addressObject.address_components){
        		switch(addressObject.address_components[x].types[0]){
        			case 'street_number':
        				composedAddress['street_number']=addressObject.address_components[x].long_name;
        				break;
        			case 'route':
        				composedAddress['street']=addressObject.address_components[x].long_name;
        				break;
        			case 'locality':
        				composedAddress['city']=addressObject.address_components[x].long_name;
        				break;
        			case 'administrative_area_level_1':
        				composedAddress['county']=addressObject.address_components[x].long_name;
        				break;
        			case 'country':
        				composedAddress['country_code']=addressObject.address_components[x].short_name;
        				composedAddress['country_name']=addressObject.address_components[x].long_name;
        				break;
        			default:
        		}
        	}
        	let oldState=Object.assign({}, this.state);
			oldState.property_location.address=composedAddress.street_number ? composedAddress.street+' '+composedAddress.street_number : composedAddress.street;
			oldState.property_location.city=composedAddress.city;
			oldState.property_location.zip=composedAddress.zip;
			oldState.property_location.county=composedAddress.county;
			oldState.dataChange=true;
			if(composedAddress.country_name){
				Helpers.doRequest('get','countries?search='+composedAddress.country_name)
		            .then(data=>{
		                oldState.countries=data.countries;
		                if(data.countries.length){
		                	oldState.property_location.country=[data.countries[0]];
		                }
		                oldState.map_address=this.generateMapAddress({address:oldState.property_location.address,city:oldState.property_location.city,zip:oldState.property_location.zip,county:oldState.property_location.county,country_name:composedAddress.country_name});
		                this.setState(oldState);
		            }).catch(error=>{});

			}
  		}
  	}

  	updatePropertyLocationValue(e,model){
        let oldState=Object.assign({}, this.state);
        if(model==='country'){
        	if(e !== null){
        		oldState.property_location[model]=[];
        		oldState.property_location[model].push(e);
        	}else{
        		oldState.property_location[model]=e;
        	}
        }else{
        	oldState.property_location[model]=e.target.value;
        }
        oldState.dataChange=true;
        this.setState(oldState);
    }

    countrySearch(search,action){
    	if(action === 'input-change'){
    		if(this.searchPromise){
	            clearTimeout(this.searchPromise);
	        }
	        let oldState=Object.assign({}, this.state);
	        this.searchPromise=setTimeout(()=>{
	            Helpers.doRequest('get','countries?search='+search)
		            .then(data=>{
		                oldState.countries=data.countries;
		                this.setState(oldState);
		            }).catch(error=>{});
		    },500);
	    }
    }

    updatePropertyData(value){
    	let oldState=Object.assign({}, this.state);
    	oldState.property_location_select_value=[value];
		oldState.property_location.address=value.street;
		oldState.property_location.city=value.city;
		oldState.property_location.zip=value.zip;
		oldState.property_location.county=value.county;
		oldState.dataChange=true;
		if(value.country_name){
			Helpers.doRequest('get','countries?search='+value.country_name)
	            .then(data=>{
	                oldState.countries=data.countries;
	                if(data.countries.length){
	                	oldState.property_location.country=[data.countries[0]];
	                }
	                oldState.map_address=this.generateMapAddress({address:oldState.property_location.address,city:oldState.property_location.city,zip:oldState.property_location.zip,county:oldState.property_location.county,country_name:value.country_name});
	                this.setState(oldState);
	            }).catch(error=>{});
		}else{
			this.setState(oldState);
		}
    }

    saveLocationData(){
    	let fieldTranslations={
    		"city":Helpers.translate('City',this.state.translations.property,'property'),
      		"country":Helpers.translate('Country',this.state.translations.property,'property')
    	};
    	let formErrors=FormValidation.checkValidity(this.validation_location,this.state.property_location,fieldTranslations,this.state.translations.general);
		if(formErrors.has_errors){
			Helpers.showAlerts(this,formErrors,true);
			return false;
		}
		let obj=Object.assign({},this.state.property_location);
		obj.property_id=this.state.property_id;
		obj.country_id=obj.country[0].id;
		obj.country=obj.country[0].code;
		Helpers.doRequest('post','property/location',obj)
            .then(data=>{
            	let genThis=this;
            	let oldState=Object.assign({}, genThis.state);
            	oldState.dataChange=false;
            	genThis.setState(oldState);
            	setTimeout(function(){
            		Helpers.showAlerts(genThis,data);
            	},100);
            }).catch(error=>{
            	Helpers.showAlerts(this,error,true);
            });
    }

    onLogoDrop(acceptedFiles){
		if(acceptedFiles && Array.isArray(acceptedFiles)){
	    	if(acceptedFiles.length){
	    		Helpers.showLoading();
				let oldState=Object.assign({},this.state);
				const loadImageOptions = { canvas: true };
				loadImage.parseMetaData(acceptedFiles[0], (data) => {
				  	if (data.exif && data.exif.get('Orientation')) {
				    	loadImageOptions.orientation = data.exif.get('Orientation');
				  	}
				  	loadImage(acceptedFiles[0], (canvas) => {
				  		let convertedImage=Helpers.converterDataURItoBlob(canvas.toDataURL(acceptedFiles[0].type));
				  		convertedImage.preview=URL.createObjectURL(convertedImage);
				  		Helpers.stopLoading();
				  		oldState.property_logo=convertedImage;
				  		oldState.dataChange=true;
				    	this.setState(oldState);
				  	}, loadImageOptions)
				});
	    	}
	    }
	}

	savePropertyLogo(){
		let genThis=this;
		let formData = new FormData();
		formData.append("property_id", this.state.property_id);
		if(this.state.property_logo !== null){
			formData.append("logo", this.state.property_logo);
		}
		let oReq = new XMLHttpRequest();
	    oReq.open("POST", process.env.REACT_APP_API_URL+'property/logo', true);
	    oReq.setRequestHeader("Authorization","Bearer " + Store.retrieveAccessToken());
	    Helpers.showLoading();
	    oReq.onload = function(oEvent) {
	        if(oReq.status === 200){
	        	Helpers.stopLoading();
	        	let data=JSON.parse(oEvent.target.response);
	        	let oldState=Object.assign({}, genThis.state);
            	oldState.dataChange=false;
            	genThis.setState(oldState);
            	setTimeout(function(){
            		Helpers.showAlerts(genThis,data);
            	},100);
	        }else{
	        	Helpers.stopLoading();
	        	Helpers.showAlerts(genThis,JSON.parse(oEvent.target.response));
	        }
	    };
	    oReq.send(formData);
	}

	updatePropertyPublishValue(e,model){
    	let oldState=Object.assign({}, this.state);
        if(model==='language'){
        	if(e !== null){
        		oldState.property_publish[model]=[];
        		oldState.property_publish[model].push(e);
        	}else{
        		oldState.property_publish[model]=e;
        	}
        }else{
        	oldState.property_publish[model]=e.target.value;
        }
        oldState.dataChange=true;
        this.setState(oldState);
    }

    publishProperty(){
    	let fieldTranslations={
    		"language":Helpers.translate('Language',this.state.translations.general,'general')
    	};
    	let formErrors=FormValidation.checkValidity(this.validation_publish,this.state.property_publish,fieldTranslations,this.state.translations.general);
		if(formErrors.has_errors){
			Helpers.showAlerts(this,formErrors,true);
			return false;
		}
    	let obj=Object.assign({},this.state.property_publish);
		obj.property_id=this.state.property_id;
		obj.language_id=obj.language[0].id;
		obj.language=obj.language[0].code;
		Helpers.doRequest('post','property/language',obj)
            .then(data=>{
            	let genThis=this;
            	let oldState=Object.assign({}, genThis.state);
            	oldState.dataChange=false;
            	genThis.setState(oldState);
            	setTimeout(function(){
            		Helpers.showAlerts(genThis,data);
            	},100);
            }).catch(error=>{
            	Helpers.showAlerts(this,error,true);
            });
    }

    updateSubscriptionState(value,model){
		let oldState=Object.assign({}, this.state);
		oldState.property_subscription.free_subdomain=false;
		oldState.property_subscription.own_domain=false;
		oldState.property_subscription[model]=value;
		oldState.dataChange=true;
		this.setState(oldState);
	}

	checkSubdomainAvailability(){
		let fieldTranslations={
    		"free_subdomain_input":Helpers.translate('Subdomain',this.state.translations.general,'general'),
    	};
    	let formErrors=FormValidation.checkValidity(this.validation_subdomain,this.state.property_subscription,fieldTranslations,this.state.translations.general);
		if(formErrors.has_errors){
			Helpers.showAlerts(this,formErrors,true);
			return false;
		}
		let obj=Object.assign({},this.state.property_subscription);
		obj.property_id=this.state.property_id;
		Helpers.doRequest('post','property/subdomain',obj)
            .then(data=>{
            	Helpers.showAlerts(this,data,true);
            }).catch(error=>{
            	Helpers.showAlerts(this,error,true);
            });
	}

	savePropertySubscription(){
		if(this.state.property_subscription['free_subdomain']){
			let fieldTranslations={
	    		"free_subdomain_input":Helpers.translate('Subdomain',this.state.translations.general,'general'),
	    	};
	    	let formErrors=FormValidation.checkValidity(this.validation_subdomain,this.state.property_subscription,fieldTranslations,this.state.translations.general);
			if(formErrors.has_errors){
				Helpers.showAlerts(this,formErrors,true);
				return false;
			}
		}else if(this.state.property_subscription['own_domain']){
			let fieldTranslations={
	    		"own_domain_input":Helpers.translate('Domain',this.state.translations.general,'general'),
	    	};
	    	let formErrors=FormValidation.checkValidity(this.validation_domain,this.state.property_subscription,fieldTranslations,this.state.translations.general);
			if(formErrors.has_errors){
				Helpers.showAlerts(this,formErrors,true);
				return false;
			}
		}
		let obj=Object.assign({},this.state.property_subscription);
		obj.property_id=this.state.property_id;
		Helpers.doRequest('post','property/subscription',obj)
            .then(data=>{
            	let genThis=this;
            	let oldState=Object.assign({}, genThis.state);
            	oldState.dataChange=false;
            	genThis.setState(oldState);
            	setTimeout(function(){
            		Helpers.showAlerts(genThis,data);
            	},100);
            }).catch(error=>{
            	Helpers.showAlerts(this,error,true);
            });
	}

	render(){
		const generalMsg = this.state.alerts.map((item, key) =>
	        <DismissibleAlert key={key} index={key} type={item.type} message={item.msg} dismissAlert={(e) => this.closeAlert(e)}/>
	    );
	    let propertyDescriptions;
	    if(this.state.activeTab === 'descriptions'){
	    	propertyDescriptions=<div>
	    		<div className="form-group col-sm-8 text-left mt-4">
		        	<label htmlFor="bannerDescriptionText">{Helpers.translate('Banner description',this.state.translations.property,'property')}</label>
		            <textarea className={"form-control " + (this.state.errors.banner_description ? 'border-error' : '')} id="bannerDescriptionText" rows="5" value={this.state.descriptions.banner_description || ''} onChange={(e)=>this.updateStateValue(e.target.value,'banner_description','descriptions')}/>
		        	{this.state.errors.banner_description && <p className="text-danger mt-1">{this.state.alertsField.banner_description}</p>}
		        </div>
		        <div className="form-group col-sm-8 text-left mt-4">
		        	<label htmlFor="propertyDescriptionText">{Helpers.translate('Property description',this.state.translations.property,'property')} {this.state.language_name} <i className="ft-11 fas fa-asterisk text-danger"></i></label>
		            <textarea className={"form-control " + (this.state.errors.property_description ? 'border-error' : '')} id="propertyDescriptionText" rows="5" value={this.state.descriptions.property_description || ''} onChange={(e)=>this.updateStateValue(e.target.value,'property_description','descriptions')}/>
		        	{this.state.errors.property_description && <p className="text-danger mt-1">{this.state.alertsField.property_description}</p>}
		        </div>
		        <div className="form-group col-sm-8 text-left mt-4">
		        	<label htmlFor="areaDescriptionText">{Helpers.translate('Area description',this.state.translations.property,'property')} {this.state.language_name} <i className="ft-11 fas fa-asterisk text-danger"></i></label>
		            <textarea className={"form-control " + (this.state.errors.area_description ? 'border-error' : '')} id="areaDescriptionText" rows="5" value={this.state.descriptions.area_description || ''} onChange={(e)=>this.updateStateValue(e.target.value,'area_description','descriptions')}/>
		        	{this.state.errors.area_description && <p className="text-danger mt-1">{this.state.alertsField.area_description}</p>}
		        </div>
		        <div className="form-group col-sm-8 text-left mt-4">
		        	<label htmlFor="guestsNumber">{Helpers.translate('Guests number (adults and children)',this.state.translations.property,'property')}</label>
		            <input type="number" min="1" className={"form-control " + (this.state.errors.guests_number ? 'border-error' : '')} id="guestsNumber" rows="5" value={this.state.descriptions.guests_number || ''} onChange={(e)=>this.updateStateValue(e.target.value,'guests_number','descriptions')}/>
		        	{this.state.errors.guests_number && <p className="text-danger mt-1">{this.state.alertsField.guests_number}</p>}
		        </div>
		        <div className="form-group col-sm-8 text-left mt-4">
		        	<label htmlFor="fontFamily">{Helpers.translate('Font Family',this.state.translations.property,'property')}</label>
		            <Select options={this.state.descriptions.fonts}
			    		value={this.state.descriptions.font && this.state.descriptions.font.length ? this.state.descriptions.font : []}
			    		className={"selectize "+(this.state.errors.font ? 'border-error' : '')}
			    		isClearable={true}
  						isSearchable={true}
			    		getOptionLabel={option => option.name}
			    		getOptionValue={option => option.code}
			    		onChange={(value)=>this.updateStateValue(value,'font','descriptions')}
			    	/>
		        	{this.state.errors.font && <p className="text-danger mt-1">{this.state.alertsField.font}</p>}
		        </div>
		        <div className="col-sm-12 text-left">
					<Button variant="info" onClick={()=>this.saveDescriptions()}>{Helpers.translate('Save',this.state.translations.general,'general')}</Button>
				</div>
	    	</div>
	    }
	    let propertyPhotos;
	    if(this.state.activeTab === 'photos'){
	    	let bannerLightboxIndex=0;
	    	let photosLightBoxSources=[];
	    	for(let x in this.state.photos.images){
	    		photosLightBoxSources.push(this.state.photos.images[x].url);
	    		if(this.state.photos.images[x].is_banner){
	    			bannerLightboxIndex=parseInt(x);
	    		}
	    	}
	    	propertyPhotos=<div className="mt-3 mb-4">
						<div>
							<div>
								<h3>{Helpers.translate('Property banner',this.state.translations.general,'general')}</h3>
							</div>
							<div className="photosListThumb">
						       <div className="thumbInner pointer" >
						        	{ this.state.photos.banner && this.state.photos.banner instanceof Object ? <img src={this.state.photos.banner.url} alt="" onClick={()=>this.openPhotosLightbox(bannerLightboxIndex)} /> : null }
						       </div>
						       <div className="position-absolute banner_update">
									<Button variant="light" onClick={()=>this.updateStateValue(true,'update_banner_modal')}>{Helpers.translate('Update banner',this.state.translations.general,'general')}</Button>
							   </div>
						    </div>
						</div>
						<div>
							<h3>{Helpers.translate('Property photos',this.state.translations.general,'general')}</h3>
						</div>
	    				<PropertyPhotos
	    				photos={ this.state.photos }
	    				photosReorder={(from,to)=>this.photosReorder(from,to)}
	    				deleteAvailablePhoto={(index)=>this.deleteAvailablePhoto(index)}
	    				updatePhotoLabel={(index,value)=>this.updatePhotoLabel(index,value)}
	    				openLightbox={(index)=>this.openPhotosLightbox(index)}
	    				translations={this.state.translations}/>
	    				<FsLightbox
							toggler={ this.state.photosLightBoxOpen }
							sources={ photosLightBoxSources }
							sourceIndex={ this.state.photosLightBoxIndex }
							type="image"
						/>
	    				<div className="col-sm-12 mt-4">
					<Button variant="info" onClick={()=>this.updateStateValue(true,'add_photos_modal')}>{Helpers.translate('Add photos',this.state.translations.general,'general')}</Button>
				</div>
				<Modal
					show={this.state.update_banner_modal}
			      	size="lg"
			      	aria-labelledby="contained-modal-banner"
			      	centered
			      	backdrop="static"
			      	onHide={()=>this.updateStateValue(false,'update_banner_modal')}
			    >
			    	<Modal.Header closeButton>{Helpers.translate('Click to select',this.state.translations.general,'general')}</Modal.Header>
				    <Modal.Body>
				    	{
				    		this.state.photos.images.map((item, key)=>
		        				<div className="dropZoneListThumb" key={key}>
							      <div className="thumbInner pointer">
							        <img src={item.url} alt="" onClick={()=>this.updateBannerPhoto(item)}/>
							      </div>
							    </div>
							)
				    	}
				    </Modal.Body>
			    </Modal>
			    {this.state.add_photos_modal && <PropertyPhotosAddModal
			        show={this.state.add_photos_modal}
			        onHide={() => this.updateStateValue(false,'add_photos_modal')}
			        updateImages={(images)=>this.updatePhotos(images)}
			        translations={this.state.translations}
			        max_post_size={this.state.photos.max_post_size}
			    />}
			</div>
	    }
	    let propertySetup;
	    if(this.state.activeTab === 'property_setup'){
	    	let propertySpaces=this.state.setup_data.map((item, key)=>
    			<Form.Group key={key} className="row">
    				<Form.Label className="col-sm-3 font-weight-bold ft-16">{Helpers.translate(item.name,this.state.translations.property,'property')}</Form.Label>
    				{
    					item.type!=='other' ? (<PropertyRegularSpace key={key}
    											item={item}
    											property_setup={this.state.property_setup}
    											updatePropertySetup={(operation)=>this.updatePropertySetup(item,operation)}
    											openLinkImageModal={()=>this.openLinkImageModal(item.type)}
    											removePhoto={(photoIndex)=>this.removePhoto(item.type,photoIndex)}
    											setAsBanner={(photoIndex)=>this.setAsBanner(item.type,photoIndex)}
    											translations={this.state.translations}
    											/>)
				            				:
				            				(<PropertyOtherSpaces
				            					addOtherSpace={()=>this.addOtherSpace()}
				            					updatePropertySetupOther={(key,model,data)=>this.updatePropertySetupOther(key,model,data)}
				            					removePropertySetupOther={(key)=>this.removePropertySetupOther(key)}
				            					openLinkImageModal={(key)=>this.openLinkImageModal(item.type,key)}
				            					removePhoto={(photoIndex,index)=>this.removePhoto(item.type,photoIndex,index)}
				            					setAsBanner={(photoIndex,index)=>this.setAsBanner(item.type,photoIndex,index)}
				            					property_setup={this.state.property_setup[item.type]}
				            					translations={this.state.translations} />)

    				}
    			</Form.Group>
    		)
	    	propertySetup=<div>
		            <div className="col-sm-8 text-left mt-4">
		            	<Form>
		            		{propertySpaces}
		            	</Form>
						{this.state.link_images_modal && <PropertySetupLinkPicturesModal
					        show={this.state.link_images_modal}
					        pictures={this.state.property_stored_images}
					        onHide={() => this.updateStateValue(false,'link_images_modal')}
					        updateImages={(images)=>this.updateLinkedImages(images)}
					        translations={this.state.translations}
					    />}
		            </div>
		            <div className="col-sm-12 text-left mb-2">
							<Button variant="info" onClick={()=>this.savePropertySetup()}>{Helpers.translate('Save',this.state.translations.general,'general')}</Button>
					</div>
	    		</div>
	    }
	    let amenities;
	    if(this.state.activeTab === 'amenities'){
	    	let propertyAmenities=this.state.amenities.map((item, key)=>
    			<Form.Group key={key}>
    				<Form.Group controlId={"amenity_check_"+key}>
					    <Form.Check
					    type="checkbox"
					    custom
					    label={Helpers.translate(item.name,this.state.translations.property,'property')}
					    onChange={(e)=>this.updateStateValue(e.target.checked,item.type,'property_amenities')}
					    checked={this.state.property_amenities[item.type] && this.state.property_amenities[item.type] === true ? 'checked' : ''}
					    />
					</Form.Group>
    			</Form.Group>
    		)
	    	amenities=<div>
		        <div className="col-sm-8 text-left mt-4">
		        	<Form>
		        		{propertyAmenities}
		        	</Form>
		        </div>
		        <div className="col-sm-12 text-left">
					<Button variant="info" onClick={()=>this.savePropertyAmenities()}>{Helpers.translate('Save',this.state.translations.general,'general')}</Button>
				</div>
	    	</div>
	    }
	    let location_data;
		if(this.state.activeTab === 'location'){
			location_data=<div>
				<Script url={"https://maps.googleapis.com/maps/api/js?key="+this.state.g_key+"&libraries=places"} onLoad={()=>this.handleGScriptLoad()} />
				<div className="col-sm-12 text-left mt-2">
					<Button variant="primary" type="submit" onClick={()=>this.checkCoordinates()}>
				    	{Helpers.translate('Get current position',this.state.translations.property,'property')}
				  	</Button>
				</div>
				<div className="col-sm-6 text-left mt-2">
					<p className="ft-16">{Helpers.translate('or',this.state.translations.property,'property')}</p>
				</div>
				<div className="col-sm-6 text-left mt-2">
					<input type="text" className="form-control" id="gautocomplete" placeholder={Helpers.translate('Search for address',this.state.translations.property,'property')}/>
				</div>
				{this.state.property_location_select.length>0 && <div className="col-sm-6 text-left mt-4">
					<Form>
						<Form.Group>
					    	<Form.Label className="ft-16">{Helpers.translate('Select your location',this.state.translations.property,'property')}</Form.Label>
					    	<Select options={this.state.property_location_select}
					    		value={this.state.property_location_select_value}
					    		placeholder={Helpers.translate('Select',this.state.translations.general,'general')}
					    		defaultMenuIsOpen={false}
					    		getOptionLabel={option => option.full_address}
					    		getOptionValue={option => option.id}
					    		onChange={(value)=>this.updatePropertyData(value)}
					    	/>
					  	</Form.Group>
					</Form>
				</div>}
				<div className="mt-4 mb-4">
					<div className="col-sm-6 text-left d-inline-block">
						<Form>
						  	<Form.Group>
						    	<Form.Label className="ft-16">{Helpers.translate('Address',this.state.translations.property,'property')}</Form.Label>
						    	<Form.Control type="text" value={this.state.property_location.address || ''} name="address" onChange={(e)=>this.updatePropertyLocationValue(e,'address')} placeholder={Helpers.translate('Address',this.state.translations.property,'property')} autoComplete="off"/>
						  	</Form.Group>
						  	<Form.Group>
						    	<Form.Label className="ft-16">{Helpers.translate('City',this.state.translations.property,'property')} <i className="ft-11 fas fa-asterisk text-danger"></i></Form.Label>
						    	<Form.Control className={this.state.errors.city ? 'border-error' : ''} type="text" value={this.state.property_location.city || ''} name="city" onChange={(e)=>this.updatePropertyLocationValue(e,'city')} placeholder={Helpers.translate('City',this.state.translations.property,'property')} autoComplete="off"/>
						  		{this.state.errors.city && <p className="text-danger mt-1">{this.state.alertsField.city}</p>}
						  	</Form.Group>
						  	<Form.Group>
						    	<Form.Label className="ft-16">{Helpers.translate('Zip',this.state.translations.property,'property')}</Form.Label>
						    	<Form.Control type="text" value={this.state.property_location.zip || ''} name="zip" onChange={(e)=>this.updatePropertyLocationValue(e,'zip')} placeholder={Helpers.translate('Zip',this.state.translations.property,'property')} autoComplete="off"/>
						  	</Form.Group>
						  	<Form.Group>
						    	<Form.Label className="ft-16">{Helpers.translate('County',this.state.translations.property,'property')}</Form.Label>
						    	<Form.Control type="text" value={this.state.property_location.county || ''} name="county" onChange={(e)=>this.updatePropertyLocationValue(e,'county')} placeholder={Helpers.translate('County',this.state.translations.property,'property')} autoComplete="off"/>
						  	</Form.Group>
						  	<Form.Group>
						    	<Form.Label className="ft-16">{Helpers.translate('Country',this.state.translations.property,'property')} <i className="ft-11 fas fa-asterisk text-danger"></i></Form.Label>
						    	<Select options={this.state.countries}
						    		value={this.state.property_location.country && this.state.property_location.country.length ? this.state.property_location.country : []}
						    		className={"selectize "+(this.state.errors.country ? 'border-error' : '')}
	          						isClearable={true}
	          						isSearchable={true}
	          						placeholder={Helpers.translate('Select',this.state.translations.general,'general')}
						    		getOptionLabel={option => option.name}
						    		getOptionValue={option => option.code}
						    		onChange={(value)=>this.updatePropertyLocationValue(value,'country')}
						    		onInputChange={(str,action)=>this.countrySearch(str,action.action)}
						    	/>
						    	{this.state.errors.country && <p className="text-danger mt-1">{this.state.alertsField.country}</p>}
						  	</Form.Group>
						</Form>
					</div>
					{ this.state.map_address &&
						<div className="col-sm-6 d-inline-block">
							<div>
								<Iframe title="map_address" height="350" src={this.state.map_address}/>
							</div>
							<div className="text-left mt-2">
								<Button variant="secondary" onClick={()=>this.updateMap()}>{Helpers.translate('Update map',this.state.translations.general,'general')}</Button>
							</div>
						</div>
					}
				</div>
				<div className="col-sm-12 text-left">
					<Button variant="info" onClick={()=>this.saveLocationData()}>{Helpers.translate('Save',this.state.translations.general,'general')}</Button>
				</div>
			</div>
		}
		let propertyLogo;
		if(this.state.activeTab === 'logo'){
	    	propertyLogo=<div>
		        <div className="col-sm-12 mt-3">
		        	<h4>{Helpers.translate('Here you have some links where you can create your custom logo or if you have already one upload it to your property',this.state.translations.general,'general')}</h4>
		        	{
		        		this.state.logo_links.map((item, key)=>
			    			<p key={key}>
			    				<a href={item.name} target="_blank" rel="noopener noreferrer">{item.name}</a>
			    			</p>
			    		)
		        	}
		        	<div className="dropZone col-sm-8 offset-md-2 mb-2">
	            		<Dropzone onDrop={(acceptedFiles)=>this.onLogoDrop(acceptedFiles)} accept={'image/*'} multiple={false}>
						  {({getRootProps, getInputProps}) => (
						    <section>
						      <div {...getRootProps()} className="dropZone_container">
						        <input {...getInputProps()} />
						        <p>{Helpers.translate('Drag and drop logo here or click to select file',this.state.translations.general,'general')}</p>
						      </div>
						    </section>
						  )}
						</Dropzone>
	            	</div>
	            	{this.state.property_logo !== null && <div className="dropZoneListThumb" >
				      <div className="thumbInner pointer">
				        <img src={this.state.property_logo.preview} alt="" onClick={()=>window.open(this.state.property_logo.preview,'_blank')}/>
				      </div>
				      <div className="position-absolute delete_picture">
						<i className="fas fa-trash-alt ft-16 pointer" onClick={()=>this.updateStateValue(null,'property_logo')}></i>
					   </div>
				    </div>}
		        </div>
		        <div className="col-sm-12 mt-4">
					<Button variant="info" onClick={()=>this.savePropertyLogo()}>{Helpers.translate('Save',this.state.translations.general,'general')}</Button>
				</div>
	    	</div>
	    }
	    let propertyLanguage;
	    if(this.state.activeTab === 'language'){
	    	propertyLanguage=<div>
		        <div className="col-sm-8 text-left mt-4">
					<Form>
						<Form.Group>
					    	<Form.Label className="ft-16">{Helpers.translate('Primary language',this.state.translations.general,'general')} <i className="ft-11 fas fa-asterisk text-danger"></i></Form.Label>
					    	<Select options={this.state.languages}
					    		value={this.state.property_publish.language && this.state.property_publish.language.length ? this.state.property_publish.language : []}
					    		className={"selectize "+(this.state.errors.language ? 'border-error' : '')}
					    		isClearable={true}
          						isSearchable={true}
					    		getOptionLabel={option => option.name}
					    		getOptionValue={option => option.code}
					    		onChange={(value)=>this.updatePropertyPublishValue(value,'language')}
					    	/>
					    	{this.state.errors.language && <p className="text-danger mt-1">{this.state.alertsField.language}</p>}
					  	</Form.Group>
					</Form>
				</div>
				<div className="col-sm-12 text-left">
					<Button variant="info" onClick={()=>this.publishProperty()}>{Helpers.translate('Save',this.state.translations.general,'general')}</Button>
				</div>
		    </div>
	    }
	    let propertySubscription;
	    if(this.state.activeTab === 'subscription'){
	    	propertySubscription=<div>
		        <div className="col-sm-8 text-left mt-4">
		        	<Form>
	    				<Form.Group controlId="free_subdomain_check">
	    					<Form.Label className="col-sm-3 font-weight-bold ft-16">{Helpers.translate('Free Subdomain',this.state.translations.general,'general')}</Form.Label>
						    <Form.Check className="d-inline"
						    type="radio"
						    custom
						    label=""
				    		name="subscription_type"
						    onChange={(e)=>this.updateSubscriptionState(e.target.checked,'free_subdomain')}
						    checked={this.state.property_subscription['free_subdomain'] && this.state.property_subscription['free_subdomain'] === true ? 'checked' : ''}
						    />
						    { this.state.property_subscription['free_subdomain'] && this.state.property_subscription['free_subdomain'] === true &&
						    	<div className="col-sm-6 offset-sm-3">
						    		<div className="row">
						    			<input className="form-control" type="text" value={this.state.property_subscription['free_subdomain_input'] || ''} onChange={(e)=>this.updateStateValue(e.target.value,'free_subdomain_input','property_subscription')} placeholder={Helpers.translate('Your value',this.state.translations.general,'general')}/>
						    		</div>
						    		{this.state.errors.free_subdomain_input && <div className="row"><p className="text-danger mt-1">{this.state.alertsField.free_subdomain_input}</p></div>}
						    		{this.state.success.free_subdomain_input && <div className="row"><p className="text-success mt-1">{this.state.alertsField.free_subdomain_input}</p></div>}
						    		<div className="row mt-2">
						    			<Button variant="info" onClick={()=>this.checkSubdomainAvailability()}>{Helpers.translate('Check availability',this.state.translations.general,'general')}</Button>
						    		</div>
								</div>
						    }
						</Form.Group>
		        	</Form>
		        </div>
		        <div className="col-sm-12 text-left">
		        	<OverlayTrigger
					    placement="right"
					    delay={{ show: 100, hide: 100 }}
					    overlay={<Tooltip>{Helpers.translate('Saving without selection will make your property use the current url',this.state.translations.general,'general')}</Tooltip>}
					  >
					    <Button variant="info" onClick={()=>this.savePropertySubscription()}>{Helpers.translate('Save',this.state.translations.general,'general')}</Button>
					</OverlayTrigger>
				</div>
	    	</div>
	    }
	    return (
			<div className="navbarContent">
				{generalMsg}
				{!this.state.create && <div className="col-sm-12">
					<Tabs id="property_tabs" activeKey={this.state.activeTab} onSelect={(k) => (k !== this.state.activeTab ? (k === 'back' ? this.props.history.push("/home") : (this.state.dataChange ? this.confirmChangeTab(k) : this.renderTab(k))) : null)}>
				      	<Tab eventKey="descriptions" title={Helpers.translate('General',this.state.translations.property,'property')}>
				      		{propertyDescriptions}
				      	</Tab>
				      	<Tab eventKey="photos" title={Helpers.translate('Photos',this.state.translations.property,'property')}>
				      		{propertyPhotos}
				      	</Tab>
				      	<Tab eventKey="property_setup" title={Helpers.translate('Rooms',this.state.translations.property,'property')}>
				      		{propertySetup}
				      	</Tab>
				      	<Tab eventKey="amenities" title={Helpers.translate('Amenities',this.state.translations.property,'property')}>
				      		{amenities}
				      	</Tab>
				      	<Tab eventKey="location" title={Helpers.translate('Location',this.state.translations.property,'property')}>
				      		{location_data}
				      	</Tab>
				      	<Tab eventKey="logo" title={Helpers.translate('Logo',this.state.translations.property,'property')}>
				      		{propertyLogo}
				      	</Tab>
				      	<Tab eventKey="language" title={Helpers.translate('Language',this.state.translations.general,'general')}>
				      		{propertyLanguage}
				      	</Tab>
				      	<Tab eventKey="subscription" title={Helpers.translate('Subscription',this.state.translations.property,'property')}>
				      		{propertySubscription}
				      	</Tab>
				      	<Tab eventKey="calendar" title={Helpers.translate('Calendar',this.state.translations.property,'property')}>
				      		{ this.state.activeTab === 'calendar' && <PropertyCalendar property_id={this.state.property_id} translations={this.state.translations} /> }
				      	</Tab>
				      	<Tab eventKey="back" title={Helpers.translate('Back',this.state.translations.general,'general')}></Tab>
				    </Tabs>
				</div>}
		   	</div>
		)
	}
}
export default withRouter(Property);
