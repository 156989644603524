import Helpers from './Helpers.js';

export default{

	checkValidity(rules,state,propertyTranslations,generalTranslations,noTranslation){
		let responseObj={'has_errors':false,'errors':{}};
		for(let x in rules){
			for(let y in rules[x]){			
				let error_masg=this.errorMessage(rules[x][y],x,state,propertyTranslations,generalTranslations,noTranslation);
				if(error_masg){
					if(!responseObj.has_errors){
	                    responseObj.has_errors=true;
	                }
	                if(!responseObj.errors[x]){
	                	responseObj.errors[x]=[];
	                }
	                responseObj.errors[x].push(error_masg);
					break;
				}
			}
		}
		return responseObj;
    },

    errorMessage(type,name,state,propertyTranslations,generalTranslations,noTranslation){
        let msg="";
        switch(type){
            case 'required':
            	if(!state[name]){
            		msg=propertyTranslations[name]+" "+(noTranslation ? 'field is required' : Helpers.translate('field is required',generalTranslations,'general'));
            	}                
                break;
            case 'float':
            	if(isNaN(state[name])){
            		msg=propertyTranslations[name]+" "+(noTranslation ? 'field needs to be a number' : Helpers.translate('field needs to be a number',generalTranslations,'general'));
            	}
            	break;
            case 'not_empty':
            	if(!state[name].length){
            		msg=propertyTranslations[name]+" "+(noTranslation ? 'field is required' : Helpers.translate('field is required',generalTranslations,'general'));
            	}
            	break;
            case 'email':
                let regTest=/\S+@\S+\.\S+/;
                if(!regTest.test(state[name])){
                    msg=propertyTranslations[name]+" "+(noTranslation ? 'field must be a valid email address' : Helpers.translate('field must be a valid email address',generalTranslations,'general'));
                }
                break;
            default:
        }
        return msg;
    }

}
