import React from 'react';
import Helpers from './../services/Helpers.js';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Dropzone from 'react-dropzone';
import * as loadImage from 'blueimp-load-image';
import DismissibleAlert from './DismissibleAlert.js';

export default class PropertyPhotosAddModal extends React.Component{

	constructor(props) {
	  	super(props);
	  	this.state={
	  		errors:{},
	  		success:{},
		  	alerts:[],  	
	      	alertsField:[],
	  		images:[],
	  		current_upload_size:0
	  	};
	}

	onDrop(acceptedFiles){
		if(acceptedFiles && Array.isArray(acceptedFiles)){
	    	if(acceptedFiles.length){
	    		Helpers.showLoading();
		    	this.createImage(acceptedFiles,0);
	    	}else{
	    		Helpers.showAlerts(this,{'errors':{'error':Helpers.translate('Wrong image file',this.props.translations.general,'general')}});
	    	}    	
	    }
	}

	createImage(images,index){
		if(index < images.length){
			let genThis=this;
			let oldState=Object.assign({},genThis.state);
			const loadImageOptions = { canvas: true };
			loadImage.parseMetaData(images[index], (data) => {
			  	if (data.exif && data.exif.get('Orientation')) {
			    	loadImageOptions.orientation = data.exif.get('Orientation');
			  	}
			  	loadImage(images[index], (canvas) => {
			  		let convertedImage=Helpers.converterDataURItoBlob(canvas.toDataURL(images[index].type));
			  		convertedImage.url=URL.createObjectURL(convertedImage);	
			  		if(oldState.current_upload_size+convertedImage.size <= this.props.max_post_size){
			  			oldState.images.push(convertedImage);
			  			oldState.current_upload_size+=convertedImage.size;
				    	genThis.setState(oldState);
				    	setTimeout(function(){
				    		genThis.createImage(images,index+1);
				    	},100);
			  		}else{
			  			Helpers.stopLoading();
			  			Helpers.showAlerts(genThis,{'errors':{'error':Helpers.translate('Maximum photos limit size achieved',this.props.translations.general,'general')+": "+(this.props.max_post_size/1000000)+" Mb.<br/>"+Helpers.translate('Reduce the size of the pictures or upload less pictures',this.props.translations.general,'general')}});
			  		}		    	
			  	}, loadImageOptions)
			});
		}else{
			Helpers.stopLoading();
		}		
	}

	savePictures(){
		this.props.updateImages(Object.assign({}, this.state.images));
	}

	closeModal(){
		this.props.onHide();
	}

	render(){
		const generalMsg = this.state.alerts.map((item, key) =>
	        <DismissibleAlert key={key} index={key} type={item.type} message={item.msg} modal={true} dismissAlert={(e) => Helpers.closeAlert(this,e)}/>
	    );
		return(
			<Modal
				show={this.props.show}
		      	size="lg"
		      	aria-labelledby="contained-modal-photo-add"
		      	centered
		      	backdrop="static"
		      	onHide={()=>this.closeModal()}
		    >
		    	<Modal.Header closeButton></Modal.Header>
		      	<Modal.Body>
		      		{generalMsg}
		        	<div className="dropZone mt-4">
	        			<Dropzone onDrop={(acceptedFiles)=>this.onDrop(acceptedFiles)} accept={'image/*'}>
						  {({getRootProps, getInputProps}) => (
						    <section>
						      <div {...getRootProps()} className="dropZone_container">
						        <input {...getInputProps()} />
						        <p>{Helpers.translate('Drag and drop files here or click to select file',this.props.translations.general,'general')}</p>
						      </div>
						    </section>
						  )}
						</Dropzone>       		
	            	</div>
	            	<div className="mt-2">
	            		{
	            			this.state.images.map((item, key)=>
		        				<div className="dropZoneListThumb" key={key}>
							      <div className="thumbInner pointer">
							        <img src={item.url} alt="" onClick={()=>window.open(item.url,'_blank')}/>
							      </div>
							    </div>
		        			)
	            		}
	            	</div>
		      	</Modal.Body>
		      	<Modal.Footer>
		        	<Button onClick={()=>this.savePictures()}>{Helpers.translate('Save',this.props.translations.general,'general')}</Button>
		      	</Modal.Footer>
		    </Modal>
		)
	}
}
