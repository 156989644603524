
import React from 'react';
import './Header.css';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Store from './../../services/Store.js';
import Helpers from './../../services/Helpers.js';
import { NavLink, withRouter } from "react-router-dom";


class Header extends React.Component{

	constructor(props) {
	  	super(props);
	  	this.state = {
	      	translations:{},
	      	property_id:null,
	      	navigationToggled:false,
	      	main_page:this.props.main_page
	  	};
	  	this.moduleName='header';
	}

	componentDidMount(){
		let oldState=Object.assign({}, this.state);
		if(this.state.main_page === 'home'){
			let oldState=Object.assign({}, this.state);
			Helpers.doRequest('get','property')
			.then(data=>{
        	oldState.property_id=data.property_id;
        	Helpers.getTranslations(this.moduleName)
	            .then(data=>{
	            	oldState.translations=data;
	                this.setState(oldState);
	            }).catch(error=>{
	            	oldState.translations=error;
	            	this.setState(oldState);
	            });
			}).catch(error=>{
	        	//
	    	});
		}else{
			Helpers.getTranslations(this.moduleName)
            .then(data=>{
            	oldState.translations=data;
                this.setState(oldState);
            }).catch(error=>{
            	oldState.translations=error;
            	this.setState(oldState);
            });
		}
	}

	componentDidUpdate(prevProps,prevState){
		if(prevProps.main_page === 'onboarding_more' && this.props.main_page === 'home'){
			let oldState=Object.assign({}, this.state);
			Helpers.doRequest('get','property')
			.then(data=>{
				oldState.main_page='home';
        		oldState.property_id=data.property_id;
        		this.setState(oldState);
			}).catch(error=>{
	        	//
	    	});
		}
	}

	logout(event){
		Helpers.doRequest('post','logout')
 			.then(data=>{
 				Helpers.clearTranslations();
            	Store.clearData();
				window.ee.emit('storeChange');
 			}).catch(error=>{
            	Store.clearData();
				window.ee.emit('storeChange');
        	});
	}

	handleNavigation(e,path){
		const pathname=this.props.location && this.props.location.pathname ? this.props.location.pathname : '';
		if(pathname.indexOf(path) !== -1){
			e.preventDefault();
		}
		if(this.state.navigationToggled){
			this.setState({navigationToggled:false});
		}
	}

	navigationToggled(value){
		this.setState({navigationToggled:value});
	}

	render(){
		return (
			<div className="headerTabs">
				<div className=" col-sm-10 zi-10">
		      			<Navbar expanded={this.state.navigationToggled} bg="light" expand="md" onToggle={(e)=>this.navigationToggled(e)}>
						  	<Navbar.Brand >
						  		{Helpers.translate('Rental',this.state.translations.header,'header')}
						  	</Navbar.Brand>
						  	<Navbar.Toggle aria-controls="header-navbar-nav" />
						  	<Navbar.Collapse id="header-navbar-nav" >
						  		{ (this.state.main_page === 'home' && this.state.property_id!==null ? <Nav className="mr-auto text-right">
						  			<NavLink className="nav-link" to="/home" onClick={(e)=>this.handleNavigation(e,'home')}>{Helpers.translate('Dashboard',this.state.translations.general,'general')}</NavLink>
						  			<NavLink className="nav-link" to={"/property/"+this.state.property_id} onClick={(e)=>this.handleNavigation(e,'property')}>{Helpers.translate('Property',this.state.translations.general,'general')}</NavLink>
						    		<NavLink className="nav-link" to={"/financial/"+this.state.property_id} onClick={(e)=>this.handleNavigation(e,'financial')}>{Helpers.translate('Financial',this.state.translations.general,'general')}</NavLink>
						    	</Nav> : null )}
						    	<Nav className="ml-auto text-right">
						      		<NavDropdown alignRight title={ Store.retrieveUserName() } id="logout">
						        		{this.state.main_page === 'home' && <NavDropdown.Item onClick={(e) => this.props.history.push("/myaccount")}>{Helpers.translate('My Account',this.state.translations.general,'general')}</NavDropdown.Item>}
						        		<NavDropdown.Item onClick={(e) => this.logout(e)}>{Helpers.translate('Sign Out',this.state.translations.header,'header')}</NavDropdown.Item>
						      		</NavDropdown>
						    	</Nav>
						  	</Navbar.Collapse>
						</Navbar>
		      		</div>
				</div>
		)
	}
}
export default withRouter(Header);
