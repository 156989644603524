import React from 'react';
import Form from 'react-bootstrap/Form';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Helpers from './../services/Helpers.js';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

export default class PicturesList extends React.Component{

	removePhoto(index){
		confirmAlert({
		  customUI: ({ onClose }) => {
		    return (
		      <div className='custom-ui'>
		        <h1>{Helpers.translate('Confirm',this.props.translations.general,'general')}</h1>
		        <p>{Helpers.translate('Are you sure you want to delete selected line?',this.props.translations.general,'general')}</p>
		        <button onClick={onClose}>{Helpers.translate('No',this.props.translations.general,'general')}</button>
		        <button
		          onClick={() => {
		            this.props.removePhoto(index);
		            onClose();
		          }}
		        >
		          {Helpers.translate('Yes',this.props.translations.general,'general')}
		        </button>
		      </div>
		    );
		  }
		});
	}

	render(){
		return (
			<tr>
				<td className="text-muted">
					<div className="dropZoneListThumb">
				      <div className="thumbInner">
				        <img src={this.props.data.preview} alt="" onClick={()=>window.open(this.props.data.preview,'_blank')}/>
				      </div>
				    </div>
				</td>
				<td className="text-muted">{this.props.data.name}</td>
				<td className="text-muted">{Math.floor(this.props.data.size/1000)} kb</td>
				<td>
					<Form.Group controlId={"custom_check_"+this.props.index}>
					    <Form.Check 
					    type="radio" 
					    custom 
					    label="" 
					    name="is_banner" 
					    onChange={()=>this.props.checkOption(this.props.index)} 
					    checked={this.props.data.is_banner && this.props.data.is_banner === true ? 'checked' : ''}
					    />
					</Form.Group>
				</td>
				<td className="text-center">
					<div>
						<DropdownButton alignRight variant="success" size="sm"
						title=''>
						  	<Dropdown.Item onClick={(event)=>this.removePhoto(this.props.index)}>{Helpers.translate('Delete',this.props.translations.general,'general')}</Dropdown.Item>
						</DropdownButton>
					</div>
				</td>
			</tr>
		)
	}

}
