import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import Helpers from './services/Helpers.js';
import Store from './services/Store.js';


function startApp(){
	ReactDOM.render(<App />, document.getElementById('root'));
}

if(localStorage.HRData){
	let storedData=JSON.parse(localStorage.HRData);
	if(parseInt(storedData.expiresIn) < Math.floor(Date.now() / 1000)){
		Store.clearData();
		startApp();
	}else{
		Helpers.showLoading();
		setTimeout(()=>{
			Store.updateLoginData(storedData);
            Helpers.doRequest('get','tryconnection')
				.then(data=>{
					storedData.step=data.step;
					storedData.main_page=data.main_page;
					Store.updateLoginData(storedData,true);
					startApp();
				}).catch(error=>{
					Store.clearData();
					startApp();
				});
        },300);
	}
}else{
	startApp();
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
