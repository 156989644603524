import React from 'react';
import './OnboardingMore.css';
import DismissibleAlert from './../../components/DismissibleAlert.js';
import Helpers from './../../services/Helpers.js';
import Store from './../../services/Store.js';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import ProgressBar from 'react-bootstrap/ProgressBar';
import FormValidation from './../../services/FormValidation.js';
import PropertyOtherSpaces from './../../components/PropertyOtherSpaces.js';
import PropertySetupLinkPicturesModal from './../../components/PropertySetupLinkPicturesModal.js';
import PropertyRegularSpace from './../../components/PropertyRegularSpace.js';
import Dropzone from 'react-dropzone';
import { withRouter } from 'react-router-dom';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

class OnboardingMore extends React.Component{

	constructor(props) {
	  	super(props);
	  	this.state = {			
	  		errors:{},
	  		success:{},
		  	alerts:[],  	
	      	alertsField:[],
	      	translations:{},
	      	step:null,
	      	property_id:null,
	      	language_name:null,
	      	show_content:false,
	      	completion_percentage:null,
	      	property_description:{},
	      	area_description:{},
	      	link_images_modal:false,
	      	setup_data:{},
	      	property_stored_images:[],
	      	property_setup:{},
	      	amenities:[],
	      	property_amenities:{},
	      	logo_links:[],
	      	property_logo:null,
	      	property_subscription:{}
	  	};
	  	this.moduleName='onboarding';
	  	this.validation_description={
      		"description":["required"]
      	};
      	this.temp_image_data={'type':'','index':0};
      	this.validation_subdomain={
      		"free_subdomain_input":["required"]
      	};
      	this.validation_domain={
      		"own_domain_input":["required"]
      	};
	}

	componentDidMount(){
		let oldState=Object.assign({}, this.state);
		const current_step=Store.retrieveStep();
		switch(current_step){
			case 'property_description':
				Helpers.doRequest('get','property_description')
		 			.then(data=>{  	       	                 	            	
		            	oldState.property_id=data.property_id;
		            	oldState.step=data.step;
		            	oldState.completion_percentage=data.completion_percentage;
		            	oldState.language_name=data.language_name;
		            	Helpers.getTranslations(this.moduleName)
				            .then(data=>{
				            	oldState.translations=data;			                						                
				                this.setState(oldState);
				            }).catch(error=>{
				            	oldState.translations=error;
				            	this.setState(oldState);
				            });
		 			}).catch(error=>{
		            	//	
		        	});
				break;
			case 'area_description':
				Helpers.doRequest('get','area_description')
		 			.then(data=>{  	       	                 	            	
		            	oldState.property_id=data.property_id;
		            	oldState.step=data.step;
		            	oldState.completion_percentage=data.completion_percentage;
		            	Helpers.getTranslations(this.moduleName)
				            .then(data=>{
				            	oldState.translations=data;			                						                
				                this.setState(oldState);
				            }).catch(error=>{
				            	oldState.translations=error;
				            	this.setState(oldState);
				            });
		 			}).catch(error=>{
		            	//	
		        	});
				break;
			case 'property_setup':
				Helpers.doRequest('get','property_setup')
		 			.then(data=>{  	       	                 	            	
		            	oldState.property_id=data.property_id;
		            	oldState.step=data.step;
		            	oldState.completion_percentage=data.completion_percentage;
		            	oldState.setup_data=data.setup_data;
		            	oldState.property_stored_images=data.images;
		            	Helpers.getTranslations(this.moduleName)
				            .then(data=>{
				            	oldState.translations=data;			                						                
				                this.setState(oldState);
				            }).catch(error=>{
				            	oldState.translations=error;
				            	this.setState(oldState);
				            });
		 			}).catch(error=>{
		            	//	
		        	});
				break;
			case 'amenities':
				Helpers.doRequest('get','amenities')
		 			.then(data=>{  	       	                 	            	
		            	oldState.property_id=data.property_id;
		            	oldState.step=data.step;
		            	oldState.completion_percentage=data.completion_percentage;
		            	oldState.amenities=data.amenities;
		            	Helpers.getTranslations(this.moduleName)
				            .then(data=>{
				            	oldState.translations=data;			                						                
				                this.setState(oldState);
				            }).catch(error=>{
				            	oldState.translations=error;
				            	this.setState(oldState);
				            });
		 			}).catch(error=>{
		            	//	
		        	});
				break;
			case 'property_logo':
				Helpers.doRequest('get','property_logo')
		 			.then(data=>{  	       	                 	            	
		            	oldState.property_id=data.property_id;
		            	oldState.step=data.step;
		            	oldState.completion_percentage=data.completion_percentage;
		            	oldState.logo_links=data.logos;
		            	Helpers.getTranslations(this.moduleName)
				            .then(data=>{
				            	oldState.translations=data;			                						                
				                this.setState(oldState);
				            }).catch(error=>{
				            	oldState.translations=error;
				            	this.setState(oldState);
				            });
		 			}).catch(error=>{
		            	//	
		        	});
				break;
			case 'property_subscription':
				Helpers.doRequest('get','property_subscription')
		 			.then(data=>{  	       	                 	            	
		            	oldState.property_id=data.property_id;
		            	oldState.step=data.step;
		            	oldState.completion_percentage=data.completion_percentage;
		            	Helpers.getTranslations(this.moduleName)
				            .then(data=>{
				            	oldState.translations=data;			                						                
				                this.setState(oldState);
				            }).catch(error=>{
				            	oldState.translations=error;
				            	this.setState(oldState);
				            });
		 			}).catch(error=>{
		            	//	
		        	});
				break;
			default:
				oldState.step=current_step; 
				Helpers.getTranslations(this.moduleName)
		            .then(data=>{
		            	oldState.translations=data;			                						                
		                this.setState(oldState);
		            }).catch(error=>{
		            	oldState.translations=error;
		            	this.setState(oldState);
		            });
		}
	}

	componentDidUpdate(prevProps,prevState){
		if(prevProps.location.state === undefined && this.props.location.state && this.props.location.state.msg){
			Helpers.showAlerts(this,this.props.location.state.msg);
			delete this.props.location.state.msg;
		}
	}

	componentWillUnmount(){
		this.revokePropertySetupUrls();
		if(this.state.property_logo !== null){
			URL.revokeObjectURL(this.state.property_logo.preview);
		}
	}

	revokePropertySetupUrls(){
		for(let x in this.state.property_setup){
			if(x === 'other' && Array.isArray(this.state.property_setup[x])){
				for(let y in this.state.property_setup[x]){
					if(this.state.property_setup[x][y]['images'] && Array.isArray(this.state.property_setup[x][y]['images'])){
						for(let z in this.state.property_setup[x][y]['images']){
							if(this.state.property_setup[x][y]['images'][z] instanceof File || this.state.property_setup[x][y]['images'][z] instanceof Blob){
								URL.revokeObjectURL(this.state.property_setup[x][y]['images'][z].url);
							}
						}
					}
				}
			}else{
				if(this.state.property_setup[x]['images']){
					for(let y in this.state.property_setup[x]['images']){
						if(this.state.property_setup[x]['images'][y] instanceof File || this.state.property_setup[x]['images'][y] instanceof Blob){
							URL.revokeObjectURL(this.state.property_setup[x]['images'][y].url);
						}
					}
				}
			}		
		}
	}

	updateStateValue(value,model,parent){
        let oldState=Object.assign({}, this.state);
        if(parent){
        	oldState[parent][model]=value;
        }else{
        	oldState[model]=value;
        }     
        this.setState(oldState);  
    }

	closeAlert(index){
		let oldState=Object.assign({}, this.state);
		oldState.alerts.splice(index,1);
		this.setState(oldState);
	}

	savePropertyDescription(){
		let fieldTranslations={
    		"description":Helpers.translate('Description',this.state.translations.onboarding,'onboarding'),
    	};
    	let formErrors=FormValidation.checkValidity(this.validation_description,this.state.property_description,fieldTranslations,this.state.translations.general);
		if(formErrors.has_errors){
			Helpers.showAlerts(this,formErrors,true);
			return false;
		}
		let obj=Object.assign({},this.state.property_description);
		obj.property_id=this.state.property_id;
		Helpers.doRequest('post','property_description',obj)
            .then(data=>{
            	let oldState=Object.assign({}, this.state);
            	oldState.errors={};
            	oldState.alerts=[];
            	oldState.alertsField=[];           	
            	oldState.language_name=null;
            	oldState.property_description={};
            	oldState.step=data.step;
		        oldState.completion_percentage=data.completion_percentage;
            	this.setState(oldState);
            	Store.setStep(data.step);
            }).catch(error=>{
            	Helpers.showAlerts(this,error,true);
            });
	}

	saveAreaDescription(){
		let fieldTranslations={
    		"description":Helpers.translate('Description',this.state.translations.onboarding,'onboarding'),
    	};
    	let formErrors=FormValidation.checkValidity(this.validation_description,this.state.area_description,fieldTranslations,this.state.translations.general);
		if(formErrors.has_errors){
			Helpers.showAlerts(this,formErrors,true);
			return false;
		}
		let obj=Object.assign({},this.state.area_description);
		obj.property_id=this.state.property_id;
		Helpers.doRequest('post','area_description',obj)
            .then(data=>{
            	let oldState=Object.assign({}, this.state);
            	oldState.errors={};
            	oldState.alerts=[];
            	oldState.alertsField=[];           	
            	oldState.area_description={};
            	oldState.step=data.step;
		        oldState.completion_percentage=data.completion_percentage;
		        oldState.setup_data=data.setup_data;
		        oldState.property_stored_images=data.images;
            	this.setState(oldState);
            	Store.setStep(data.step);
            }).catch(error=>{
            	Helpers.showAlerts(this,error,true);
            });
	}

	updatePropertySetup(item,operator){
		let oldState=Object.assign({}, this.state);
		if(!oldState.property_setup[item.type]){
			oldState.property_setup[item.type]={'number':0};
		}
		oldState.property_setup[item.type].number=operator==='plus' ? parseInt(oldState.property_setup[item.type].number)+1 : (parseInt(oldState.property_setup[item.type].number)>0 ? parseInt(oldState.property_setup[item.type].number)-1 : 0);
		this.setState(oldState);
	}

	addOtherSpace(){
		let oldState=Object.assign({}, this.state);
		if(!oldState.property_setup['other']){
			oldState.property_setup['other']=[];
		}
		oldState.property_setup['other'].push({'name':'Space','type':'other','number':0, 'images':[]});
		this.setState(oldState);
	}

	updatePropertySetupOther(index,model,data){
		let oldState=Object.assign({}, this.state);
		if(model === 'number'){
			oldState.property_setup['other'][index].number=data==='plus' ? parseInt(oldState.property_setup['other'][index].number)+1 : (parseInt(oldState.property_setup['other'][index].number)>0 ? parseInt(oldState.property_setup['other'][index].number)-1 : 0);
		}else{
			oldState.property_setup['other'][index][model]=data;
		}
		this.setState(oldState);
	}

	removePropertySetupOther(index){
		let oldState=Object.assign({}, this.state);
		if(oldState.property_setup['other'][index]['images']){
			for(let x in oldState.property_setup['other'][index]['images']){
				if(oldState.property_setup['other'][index]['images'][x] instanceof File || oldState.property_setup['other'][index]['images'][x] instanceof Blob){
					URL.revokeObjectURL(oldState.property_setup['other'][index]['images'][x].url);
				}else{
					oldState.property_stored_images.push(oldState.property_setup['other'][index]['images'][x]);
				}			
			}
		}
		oldState.property_setup['other'].splice(index,1);
		this.setState(oldState);
	}

	openLinkImageModal(type,index){
		this.temp_image_data.type=type;
		if(index !== undefined){
			this.temp_image_data.index=parseInt(index);
		}
		let oldState=Object.assign({}, this.state);
		if(!oldState.property_setup[type] && type !== 'other'){
			oldState.property_setup[type]={'number':0};
		}
		oldState.link_images_modal=true;
		this.setState(oldState);
	}

	updateLinkedImages(images){
		let pictureIndexesToRemove=[];
		let oldState=Object.assign({}, this.state);
		for(let x in images.stored){
			if(images.stored[x]){
				if(this.temp_image_data.type === 'other'){
					if(oldState.property_setup['other'][this.temp_image_data.index]['images'] === undefined){
						oldState.property_setup['other'][this.temp_image_data.index]['images']=[];
					}
					oldState.property_setup['other'][this.temp_image_data.index]['images'].push(oldState.property_stored_images[x]);
				}else{
					if(oldState.property_setup[this.temp_image_data.type]['images'] === undefined){
						oldState.property_setup[this.temp_image_data.type]['images']=[];
					}
					oldState.property_setup[this.temp_image_data.type]['images'].push(oldState.property_stored_images[x]);
				}
				pictureIndexesToRemove.push(parseInt(x));			
			}
		}
		if(pictureIndexesToRemove.length){
			for(let x=pictureIndexesToRemove.length-1;x>=0;x--){
				oldState.property_stored_images.splice(pictureIndexesToRemove[x],1);
			}
		}
		for(let x in images.added){
			if(images.added[x].checked){
				if(this.temp_image_data.type === 'other'){
					if(oldState.property_setup['other'][this.temp_image_data.index]['images'] === undefined){
						oldState.property_setup['other'][this.temp_image_data.index]['images']=[];
					}
					oldState.property_setup['other'][this.temp_image_data.index]['images'].push(images.added[x]);
				}else{
					if(oldState.property_setup[this.temp_image_data.type]['images'] === undefined){
						oldState.property_setup[this.temp_image_data.type]['images']=[];
					}
					oldState.property_setup[this.temp_image_data.type]['images'].push(images.added[x]);
				}
			}
		}	
		oldState.link_images_modal=false;
		this.setState(oldState);
	}

	removePhoto(type,photoIndex,index){
		let oldState=Object.assign({}, this.state);
		if(type === 'other'){
			if(oldState.property_setup[type][index]['images'][photoIndex] instanceof File || oldState.property_setup[type][index]['images'][photoIndex] instanceof Blob){
				URL.revokeObjectURL(oldState.property_setup[type][index]['images'][photoIndex].url);
			}else{
				oldState.property_stored_images.push(oldState.property_setup[type][index]['images'][photoIndex]);
			}
			oldState.property_setup[type][index]['images'].splice(photoIndex,1);
		}else{
			if(oldState.property_setup[type]['images'][photoIndex] instanceof File || oldState.property_setup[type]['images'][photoIndex] instanceof Blob){
				URL.revokeObjectURL(oldState.property_setup[type]['images'][photoIndex].url);
			}else{
				oldState.property_stored_images.push(oldState.property_setup[type]['images'][photoIndex]);
			}
			oldState.property_setup[type]['images'].splice(photoIndex,1);
		}
		this.setState(oldState);
	}

	setAsBanner(type,photoIndex,index){
		let oldState=Object.assign({}, this.state);
		for(let x in oldState.property_setup){
			if(x === 'other' && Array.isArray(oldState.property_setup[x])){			
				for(let y in oldState.property_setup[x]){				
					for(let z in oldState.property_setup[x][y]['images']){
						oldState.property_setup[x][y]['images'][z].is_banner=(x===type && parseInt(index) === parseInt(y) && parseInt(photoIndex) === parseInt(z) ? true : false);
					}					
				}
			}else{			
				for(let y in oldState.property_setup[x]['images']){
					oldState.property_setup[x]['images'][y].is_banner= (x===type && parseInt(photoIndex) === parseInt(y) ? true : false);
				}
			}
		}
		this.setState(oldState);
	}

	savePropertySetup(){
		let genThis=this;
		let formData = new FormData();
		formData.append("property_id", this.state.property_id);
		let placesSelected=0;
		for(let x in this.state.property_setup){
			if(x === 'other' && Array.isArray(this.state.property_setup[x])){			
				for(let y in this.state.property_setup[x]){				
					if(this.state.property_setup[x][y]['number']){
						formData.append("types["+x+"]["+y+"][number]", this.state.property_setup[x][y]['number']);
						formData.append("types["+x+"]["+y+"][name]", this.state.property_setup[x][y]['name']);
						if(this.state.property_setup[x][y]['images'] && Array.isArray(this.state.property_setup[x][y]['images'])){
							for(let z in this.state.property_setup[x][y]['images']){
								if(this.state.property_setup[x][y]['images'][z] instanceof File || this.state.property_setup[x][y]['images'][z] instanceof Blob){
									formData.append("types["+x+"]["+y+"][files][]", this.state.property_setup[x][y]['images'][z]);
									formData.append("types["+x+"]["+y+"][banner_files][]", (this.state.property_setup[x][y]['images'][z].is_banner ? 1 : 0));
								}else{
									formData.append("types["+x+"]["+y+"][file_ids][]", this.state.property_setup[x][y]['images'][z].id);
									formData.append("types["+x+"]["+y+"][banner_ids][]", (this.state.property_setup[x][y]['images'][z].is_banner ? 1 : 0));
								}
							}					
						}
						placesSelected++;
					}
				}
			}else{			
				if(this.state.property_setup[x]['number']){
					formData.append("types["+x+"][number]", this.state.property_setup[x]['number']);
					if(this.state.property_setup[x]['images'] && Array.isArray(this.state.property_setup[x]['images'])){
						for(let y in this.state.property_setup[x]['images']){
							if(this.state.property_setup[x]['images'][y] instanceof File || this.state.property_setup[x]['images'][y] instanceof Blob){
								formData.append("types["+x+"][files][]", this.state.property_setup[x]['images'][y]);
								formData.append("types["+x+"][banner_files][]", (this.state.property_setup[x]['images'][y].is_banner ? 1 : 0));
							}else{
								formData.append("types["+x+"][file_ids][]", this.state.property_setup[x]['images'][y].id);
								formData.append("types["+x+"][banner_ids][]", (this.state.property_setup[x]['images'][y].is_banner ? 1 : 0));
							}
						}
					}
					placesSelected++;
				}
			}
		}	
		if(!placesSelected){
			Helpers.showAlerts(this,{'errors':{'error':(Helpers.translate('Do selection first',this.state.translations.general,'general'))}});
			return false;
		}
		let oReq = new XMLHttpRequest();
	    oReq.open("POST", process.env.REACT_APP_API_URL+'property_setup', true);
	    oReq.setRequestHeader("Authorization","Bearer " + Store.retrieveAccessToken());
	    Helpers.showLoading();
	    oReq.onload = function(oEvent) {  
	        if(oReq.status === 200){
	        	Helpers.stopLoading();
	        	let data=JSON.parse(oEvent.target.response); 	        	       	
	        	genThis.revokePropertySetupUrls();
	        	let oldState=Object.assign({},genThis.state);
	        	oldState.step=data.step;
	        	oldState.completion_percentage=data.completion_percentage;
		        oldState.setup_data={};
		        oldState.property_stored_images=[];
		        oldState.property_setup={};
		        oldState.amenities=data.amenities;
            	genThis.setState(oldState);
            	Store.setStep(data.step);  
            	setTimeout(function(){
            		Helpers.showAlerts(genThis,data);
            	},500);            	
	        }else{
	        	Helpers.stopLoading();
	        	Helpers.showAlerts(genThis,JSON.parse(oEvent.target.response));
	        }          
	    };
	    oReq.send(formData);
	}

	savePropertyAmenities(){
		let placesSelected=0;
		for(let x in this.state.property_amenities){
			if(this.state.property_amenities[x]){
				placesSelected++;
			}
		}
		if(!placesSelected){
			Helpers.showAlerts(this,{'errors':{'error':(Helpers.translate('Do selection first',this.state.translations.general,'general'))}});
			return false;
		}
		let obj={};
		obj.amenities=Object.assign({},this.state.property_amenities);
		obj.property_id=this.state.property_id;
		Helpers.doRequest('post','amenities',obj)
            .then(data=>{
            	let oldState=Object.assign({}, this.state);
            	oldState.errors={};
            	oldState.alerts=[];
            	oldState.alertsField=[];           	
            	oldState.amenities=[];
            	oldState.property_amenities={};
            	oldState.step=data.step;
		        oldState.completion_percentage=data.completion_percentage;
		        oldState.logo_links=data.logos;
            	this.setState(oldState);
            	Store.setStep(data.step);
            }).catch(error=>{
            	Helpers.showAlerts(this,error,true);
            });
	}

	onLogoDrop(acceptedFiles){
		if(acceptedFiles && Array.isArray(acceptedFiles)){
	    	if(acceptedFiles.length){
	    		Helpers.showLoading();
				let oldState=Object.assign({},this.state);
				acceptedFiles[0].preview=URL.createObjectURL(acceptedFiles[0]);
				oldState.property_logo=acceptedFiles[0];
				Helpers.stopLoading();	
			    this.setState(oldState); 
	    	}    	
	    }
	}

	savePropertyLogo(){
		let genThis=this;
		let formData = new FormData();
		formData.append("property_id", this.state.property_id);
		if(this.state.property_logo !== null){
			formData.append("logo", this.state.property_logo);
		}	
		let oReq = new XMLHttpRequest();
	    oReq.open("POST", process.env.REACT_APP_API_URL+'property_logo', true);
	    oReq.setRequestHeader("Authorization","Bearer " + Store.retrieveAccessToken());
	    Helpers.showLoading();
	    oReq.onload = function(oEvent) {  
	        if(oReq.status === 200){
	        	Helpers.stopLoading();
	        	let data=JSON.parse(oEvent.target.response); 
	        	if(genThis.state.property_logo !== null){
	        		URL.revokeObjectURL(genThis.state.property_logo.preview);  
	        	}	        	       	        	   	
	        	let oldState=Object.assign({},genThis.state);
	        	oldState.step=data.step;
	        	oldState.completion_percentage=data.completion_percentage;
		        oldState.property_logo={};
		        oldState.logo_links=[];	        
            	genThis.setState(oldState);
            	Store.setStep(data.step);             	
	        }else{
	        	Helpers.stopLoading();
	        	Helpers.showAlerts(genThis,JSON.parse(oEvent.target.response));
	        }          
	    };
	    oReq.send(formData);
	}

	updateSubscriptionState(value,model){
		let oldState=Object.assign({}, this.state);
		oldState.property_subscription.free_subdomain=false;
		oldState.property_subscription.own_domain=false;
		oldState.property_subscription[model]=value;
		this.setState(oldState);
	}

	checkSubdomainAvailability(){
		let fieldTranslations={
    		"free_subdomain_input":Helpers.translate('Subdomain',this.state.translations.general,'general'),
    	};
    	let formErrors=FormValidation.checkValidity(this.validation_subdomain,this.state.property_subscription,fieldTranslations,this.state.translations.general);
		if(formErrors.has_errors){
			Helpers.showAlerts(this,formErrors,true);
			return false;
		}
		let obj=Object.assign({},this.state.property_subscription);
		obj.property_id=this.state.property_id;
		Helpers.doRequest('post','subdomain_availability',obj)
            .then(data=>{
            	Helpers.showAlerts(this,data,true);
            }).catch(error=>{
            	Helpers.showAlerts(this,error,true);
            });
	}

	savePropertySubscription(){
		if(this.state.property_subscription['free_subdomain']){
			let fieldTranslations={
	    		"free_subdomain_input":Helpers.translate('Subdomain',this.state.translations.general,'general'),
	    	};
	    	let formErrors=FormValidation.checkValidity(this.validation_subdomain,this.state.property_subscription,fieldTranslations,this.state.translations.general);
			if(formErrors.has_errors){
				Helpers.showAlerts(this,formErrors,true);
				return false;
			}
		}else if(this.state.property_subscription['own_domain']){
			let fieldTranslations={
	    		"own_domain_input":Helpers.translate('Domain',this.state.translations.general,'general'),
	    	};
	    	let formErrors=FormValidation.checkValidity(this.validation_domain,this.state.property_subscription,fieldTranslations,this.state.translations.general);
			if(formErrors.has_errors){
				Helpers.showAlerts(this,formErrors,true);
				return false;
			}
		}
		let obj=Object.assign({},this.state.property_subscription);
		obj.property_id=this.state.property_id;
		Helpers.doRequest('post','property_subscription',obj)
            .then(data=>{
            	Store.setStep(data.step);
            	Store.setMainPage(data.main_page);
	 			window.ee.emit('storeChange');
	 			this.props.history.push("/home",{"msg":data});
            }).catch(error=>{
            	Helpers.showAlerts(this,error,true);
            });
	}

	render(){
		const generalMsg = this.state.alerts.map((item, key) =>
	        <DismissibleAlert key={key} index={key} type={item.type} message={item.msg} dismissAlert={(e) => this.closeAlert(e)}/>
	    );
	    let propertyDescription;
	    if(this.state.show_content && this.state.step === 'property_description'){
	    	propertyDescription=<div>
	    		<div className="page-header">
					<div className="col-sm-12 text-left">
		                <h1>{Helpers.translate('Property description',this.state.translations.onboarding,'onboarding')}</h1>
		            </div>
		        </div>
		        <div className="form-group col-sm-8 text-left mt-4">
		        	<label htmlFor="propertyDescriptionText">{Helpers.translate('Description',this.state.translations.onboarding,'onboarding')} {this.state.language_name} <i className="ft-11 fas fa-asterisk text-danger"></i></label>
		            <textarea className={"form-control " + (this.state.errors.description ? 'border-error' : '')} id="propertyDescriptionText" rows="5" value={this.state.property_description.description || ''} onChange={(e)=>this.updateStateValue(e.target.value,'description','property_description')}/>
		        	{this.state.errors.description && <p className="text-danger mt-1">{this.state.alertsField.description}</p>}
		        </div>
		        <div className="col-sm-12 text-left">
					<Button variant="info" onClick={()=>this.savePropertyDescription()}>{Helpers.translate('Continue',this.state.translations.general,'general')}</Button>
				</div>
	    	</div>
	    }
	    let areaDescription;
	    if(this.state.show_content && this.state.step === 'area_description'){
	    	areaDescription=<div>
	    		<div className="page-header">
					<div className="col-sm-12 text-left">
		                <h1>{Helpers.translate('Area description',this.state.translations.onboarding,'onboarding')}</h1>
		            </div>
		        </div>
		        <div className="form-group col-sm-8 text-left mt-4">
		        	<label htmlFor="areaDescriptionText">{Helpers.translate('Description',this.state.translations.onboarding,'onboarding')} {this.state.language_name} <i className="ft-11 fas fa-asterisk text-danger"></i></label>
		            <textarea className={"form-control " + (this.state.errors.description ? 'border-error' : '')} id="areaDescriptionText" rows="5" value={this.state.area_description.description || ''} onChange={(e)=>this.updateStateValue(e.target.value,'description','area_description')}/>
		        	{this.state.errors.description && <p className="text-danger mt-1">{this.state.alertsField.description}</p>}
		        </div>
		        <div className="col-sm-12 text-left">
					<Button variant="info" onClick={()=>this.saveAreaDescription()}>{Helpers.translate('Continue',this.state.translations.general,'general')}</Button>
				</div>
	    	</div>
	    }
	    let propertySetup;
	    if(this.state.show_content && this.state.step === 'property_setup'){
	    	let propertySpaces=this.state.setup_data.map((item, key)=>
    			<Form.Group key={key} className="row">
    				<Form.Label className="col-sm-3 font-weight-bold ft-16">{Helpers.translate(item.name,this.state.translations.onboarding,'onboarding')}</Form.Label>
    				{
    					item.type!=='other' ? (<PropertyRegularSpace key={key}
    											item={item}
    											property_setup={this.state.property_setup}
    											updatePropertySetup={(operation)=>this.updatePropertySetup(item,operation)}
    											openLinkImageModal={()=>this.openLinkImageModal(item.type)}
    											removePhoto={(photoIndex)=>this.removePhoto(item.type,photoIndex)}
    											setAsBanner={(photoIndex)=>this.setAsBanner(item.type,photoIndex)}
    											translations={this.state.translations}
    											/>) 
				            				:
				            				(<PropertyOtherSpaces 
				            					addOtherSpace={()=>this.addOtherSpace()} 
				            					updatePropertySetupOther={(key,model,data)=>this.updatePropertySetupOther(key,model,data)} 
				            					removePropertySetupOther={(key)=>this.removePropertySetupOther(key)} 
				            					openLinkImageModal={(key)=>this.openLinkImageModal(item.type,key)}
				            					removePhoto={(photoIndex,index)=>this.removePhoto(item.type,photoIndex,index)}
				            					setAsBanner={(photoIndex,index)=>this.setAsBanner(item.type,photoIndex,index)}
				            					property_setup={this.state.property_setup[item.type]} 
				            					translations={this.state.translations} />)
				            				
    				}  				
    			</Form.Group>
    		)
	    	propertySetup=<div>
	    			<div className="page-header">
						<div className="col-sm-12 text-left">
			                <h1>{Helpers.translate('Property Details',this.state.translations.onboarding,'onboarding')}</h1>
			            </div>
			        </div>
		            <div className="col-sm-8 text-left mt-4">
		            	<Form>
		            		{propertySpaces}
		            	</Form>
						{this.state.link_images_modal && <PropertySetupLinkPicturesModal
					        show={this.state.link_images_modal}
					        pictures={this.state.property_stored_images}
					        onHide={() => this.updateStateValue(false,'link_images_modal')}
					        updateImages={(images)=>this.updateLinkedImages(images)}
					        translations={this.state.translations}
					    />}	            	
		            </div>
		            <div className="col-sm-12 text-left">
						<Button variant="info" onClick={()=>this.savePropertySetup()}>{Helpers.translate('Continue',this.state.translations.general,'general')}</Button>
					</div>
	    		</div>
	    }
	    let amenities;
	    if(this.state.show_content && this.state.step === 'amenities'){
	    	let propertyAmenities=this.state.amenities.map((item, key)=>
    			<Form.Group key={key}>
    				<Form.Group controlId={"amenity_check_"+key}>
					    <Form.Check 
					    type="checkbox" 
					    custom 
					    label={Helpers.translate(item.name,this.state.translations.onboarding,'onboarding')} 
					    onChange={(e)=>this.updateStateValue(e.target.checked,item.type,'property_amenities')} 
					    checked={this.state.property_amenities[item.type] && this.state.property_amenities[item.type] === true ? 'checked' : ''}
					    />
					</Form.Group> 				
    			</Form.Group>
    		)
	    	amenities=<div>
	    		<div className="page-header">
					<div className="col-sm-12 text-left">
		                <h1>{Helpers.translate('Property Amenities',this.state.translations.onboarding,'onboarding')}</h1>
		            </div>
		        </div>
		        <div className="col-sm-8 text-left mt-4">
		        	<Form>
		        		{propertyAmenities}
		        	</Form>
		        </div>
		        <div className="col-sm-12 text-left">
					<Button variant="info" onClick={()=>this.savePropertyAmenities()}>{Helpers.translate('Continue',this.state.translations.general,'general')}</Button>
				</div>	
	    	</div>
	    }	
	    let propertyLogo;
	    if(this.state.show_content && this.state.step === 'property_logo'){
	    	propertyLogo=<div>
	    		<div className="page-header">
					<div className="col-sm-12 text-left">
		                <h1>{Helpers.translate('Property Logo',this.state.translations.onboarding,'onboarding')}</h1>
		            </div>
		        </div>
		        <div className="col-sm-12">
		        	<h4>{Helpers.translate('Here you have some links where you can create your custom logo or if you have already one upload it to your property',this.state.translations.general,'general')}</h4>
		        	{
		        		this.state.logo_links.map((item, key)=>
			    			<p key={key}>
			    				<a href={item.name} target="_blank" rel="noopener noreferrer">{item.name}</a> 				
			    			</p>
			    		)
		        	}
		        	<div className="dropZone col-sm-8 offset-md-2 mb-2">
	            		<Dropzone onDrop={(acceptedFiles)=>this.onLogoDrop(acceptedFiles)} accept={'image/*'} multiple={false}>
						  {({getRootProps, getInputProps}) => (
						    <section>
						      <div {...getRootProps()} className="dropZone_container">
						        <input {...getInputProps()} />
						        <p>{Helpers.translate('Drag and drop logo here or click to select file',this.state.translations.general,'general')}</p>
						      </div>
						    </section>
						  )}
						</Dropzone>
	            	</div>
	            	{this.state.property_logo && <div className="dropZoneListThumb" >
				      <div className="thumbInner pointer">
				        <img src={this.state.property_logo.preview} alt="" onClick={()=>window.open(this.state.property_logo.preview,'_blank')}/>
				      </div>
				      <div className="position-absolute delete_picture">
						<i className="fas fa-trash-alt ft-16 pointer" onClick={()=>this.updateStateValue(null,'property_logo')}></i>		      	
					   </div>
				    </div>}			    
		        </div>
		        <div className="col-sm-12 mt-4">
					<Button variant="info" onClick={()=>this.savePropertyLogo()}>{Helpers.translate('Continue',this.state.translations.general,'general')}</Button>
				</div>
	    	</div>
	    }
	    let propertySubscription;
	    if(this.state.show_content && this.state.step === 'property_subscription'){
	    	propertySubscription=<div>
	    		<div className="page-header">
					<div className="col-sm-12 text-left">
		                <h1>{Helpers.translate('Property Subscription',this.state.translations.onboarding,'onboarding')}</h1>
		            </div>
		        </div>
		        <div className="col-sm-8 text-left mt-4">
		        	<Form>
	    				<Form.Group controlId="free_subdomain_check">
	    					<Form.Label className="col-sm-3 font-weight-bold ft-16">{Helpers.translate('Free Subdomain',this.state.translations.general,'general')}</Form.Label>
						    <Form.Check className="d-inline"
						    type="radio" 
						    custom 
						    label="" 
				    		name="subscription_type"
						    onChange={(e)=>this.updateSubscriptionState(e.target.checked,'free_subdomain')} 
						    checked={this.state.property_subscription['free_subdomain'] && this.state.property_subscription['free_subdomain'] === true ? 'checked' : ''}
						    />
						    { this.state.property_subscription['free_subdomain'] && this.state.property_subscription['free_subdomain'] === true &&
						    	<div className="col-sm-6 offset-sm-3">
						    		<div className="row">
						    			<input className="form-control" type="text" value={this.state.property_subscription.free_subdomain_input || ''} onChange={(e)=>this.updateStateValue(e.target.value,'free_subdomain_input','property_subscription')} placeholder={Helpers.translate('Your value',this.state.translations.general,'general')}/>
						    		</div>
						    		{this.state.errors.free_subdomain_input && <div className="row"><p className="text-danger mt-1">{this.state.alertsField.free_subdomain_input}</p></div>}
						    		{this.state.success.free_subdomain_input && <div className="row"><p className="text-success mt-1">{this.state.alertsField.free_subdomain_input}</p></div>}
						    		<div className="row mt-2">
						    			<Button variant="info" onClick={()=>this.checkSubdomainAvailability()}>{Helpers.translate('Check availability',this.state.translations.general,'general')}</Button>
						    		</div>				    									
								</div>
						    }				    
						</Form.Group> 							
		        	</Form>
		        </div>
		        <div className="col-sm-12 text-left">
		        	<OverlayTrigger
					    placement="right"
					    delay={{ show: 100, hide: 100 }}
					    overlay={<Tooltip>{Helpers.translate('Saving without selection will make your property use the current url',this.state.translations.general,'general')}</Tooltip>}
					  >
					    <Button variant="info" onClick={()=>this.savePropertySubscription()}>{Helpers.translate('Save',this.state.translations.general,'general')}</Button>
					</OverlayTrigger>				
				</div>
	    	</div>
	    }
		return (		
			<div className="content">
				{generalMsg}
				<div className="col-sm-8 offset-md-2 text-center">
					<h5 className="text-success">{Helpers.translate('Overall completion percentage',this.state.translations.onboarding,'onboarding')}</h5>
					<ProgressBar animated now={this.state.completion_percentage} label={this.state.completion_percentage+"%"}/>
					<Button variant="info mt-2" onClick={()=>this.updateStateValue(true,'show_content')}>{Helpers.translate('Update property info',this.state.translations.onboarding,'onboarding')}</Button>
				</div>
				{propertyDescription}
				{areaDescription}
				{propertySetup}
				{amenities}
				{propertyLogo}
				{propertySubscription}
		   	</div>
		)
	}
}
export default withRouter(OnboardingMore);