import React from 'react';
import { withRouter } from 'react-router-dom';
import DismissibleAlert from './../../components/DismissibleAlert.js';
import Helpers from './../../services/Helpers.js';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Button from 'react-bootstrap/Button';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import ExpenseEditModal from './../../components/ExpenseEditModal.js';
import RevenueEditModal from './../../components/RevenueEditModal.js';
import Table from 'react-bootstrap/Table';
import ReactPaginate from 'react-paginate';
import AccountantEditModal from './../../components/AccountantEditModal.js';
import Form from 'react-bootstrap/Form';
import FormValidation from './../../services/FormValidation.js';

import IconButton from '@material-ui/core/IconButton';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import Collapse from 'react-bootstrap/Collapse'


class Financial extends React.Component{

	constructor(props) {
		super(props);
		this.state={
			property_id:null,
	  		errors:{},
	  		success:{},
		  	alerts:[],
	      	alertsField:[],
	      	currentPage: 1,
	      	show_content:false,
	      	dataChange:false,
	      	translations:{},
	      	activeTab:"expenses",
	      	expenses:{},
	      	revenues:{},
	      	expense_edit_modal:false,
	      	expense_edit_id:"",
	      	revenue_edit_modal:false,
	      	revenue_edit_id:"",
	      	accountant:{},
	      	accountant_edit_modal:false,
				open:false,
		};
		this.options = {
			defaultSortName: 'date_formatted',
			defaultSortOrder: 'desc'
		};
		this.moduleName='financial';
		this.validation_accountant={
      		"accountant_name":["required"],
      		"accountant_email":["required","email"]
      	};
	}

	componentDidMount(){
		if(this.props.match.params.id){
			let oldState=Object.assign({}, this.state);
			if(!isNaN(parseInt(this.props.match.params.id))){
				Helpers.doRequest('get','financial/expenses?property_id='+this.props.match.params.id+"&page="+oldState.currentPage)
		 			.then(data=>{
		            	oldState.property_id=this.props.match.params.id;
		            	oldState.show_content=true;
		            	oldState.expenses=data.expenses;
		            	Helpers.getTranslations(this.moduleName)
				            .then(data=>{
				            	oldState.translations=data;
				                this.setState(oldState);
				            }).catch(error=>{
				            	oldState.translations=error;
				            	this.setState(oldState);
				            });
		 			}).catch(error=>{
		            	//
		        	});
			}
		}
	}

	updateStateValue(value,model,parent){
		let dataChangeExclude=['expense_edit_modal','revenue_edit_modal','accountant_edit_modal'];
        let oldState=Object.assign({}, this.state);
        if(parent){
        	if(model === 'font'){
        		if(value !== null){
	        		oldState[parent][model]=[];
	        		oldState[parent][model].push(value);
	        	}else{
	        		oldState[parent][model]=value;
	        	}
        	}else{
        		oldState[parent][model]=value;
        	}
        }else{
        	oldState[model]=value;
        }
        if(dataChangeExclude.indexOf(model) === -1){
        	oldState.dataChange=true;
        }
        this.setState(oldState);
    }

	confirmChangeTab(tab){
    	confirmAlert({
		  customUI: ({ onClose }) => {
		    return (
		      <div className='custom-ui'>
		        <h1>{Helpers.translate('Confirm',this.state.translations.general,'general')}</h1>
		        <p>{Helpers.translate('There are changes that will be lost without save. Do you want to continue and abort all changes?',this.state.translations.general,'general')}</p>
		        <button onClick={onClose}>{Helpers.translate('No',this.state.translations.general,'general')}</button>
		        <button
		          onClick={() => {
		            this.renderTab(tab);
		            onClose();
		          }}
		        >
		          {Helpers.translate('Yes',this.state.translations.general,'general')}
		        </button>
		      </div>
		    );
		  }
		});
    }

    renderTab(tab){
    	let oldState=Object.assign({}, this.state);
    	oldState.dataChange=false;
    	oldState.activeTab=tab;
    	oldState.errors={};
		oldState.alerts=[];
	    oldState.alertsField=[];
	    oldState.currentPage=1;
    	switch(tab){
    		case 'expenses':
    			Helpers.doRequest('get','financial/expenses?property_id='+this.state.property_id+"&page="+oldState.currentPage)
		 			.then(data=>{
		            	oldState.expenses=data.expenses;
		            	this.setState(oldState);
		 			}).catch(error=>{
		            	//
		        	});
    			break;
    		case 'revenues':
    			Helpers.doRequest('get','financial/revenues?property_id='+this.state.property_id)
		 			.then(data=>{
		            	oldState.revenues=data.revenues;
		            	this.setState(oldState);
		 			}).catch(error=>{
		            	//
		        	});
    			break;
    		case 'accountant':
    			Helpers.doRequest('get','financial/accountants')
		 			.then(data=>{
		            	oldState.accountant=data.accountant;
		            	this.setState(oldState);
		 			}).catch(error=>{
		            	//
		        	});
    			break;
    		default:
    			this.setState(oldState);
    	}
    }

    updateExpensesList(d){
    	let oldState=Object.assign({}, this.state);
    	oldState.expense_edit_modal=false;
    	oldState.dataChange=false;
    	oldState.errors={};
		oldState.alerts=[];
	    oldState.alertsField=[];
	    Helpers.doRequest('get','financial/expenses?property_id='+this.state.property_id+"&page="+oldState.currentPage)
 			.then(data=>{
            	oldState.expenses=data.expenses;
            	this.setState(oldState);
            	let genThis=this;
            	setTimeout(function(){
            		Helpers.showAlerts(genThis,d);
            	},200);
 			}).catch(error=>{
            	//
        	});
    }

    addExpense(){
    	let oldState=Object.assign({}, this.state);
    	oldState.expense_edit_modal=true;
    	oldState.expense_edit_id="";
    	this.setState(oldState);
    }

    editExpense(index){
    	let oldState=Object.assign({}, this.state);
    	oldState.expense_edit_modal=true;
    	oldState.expense_edit_id=oldState.expenses.list[index].id;
    	this.setState(oldState);
    }

    removeExpense(index){
    	confirmAlert({
		  customUI: ({ onClose }) => {
		    return (
		      <div className='custom-ui'>
		        <h1>{Helpers.translate('Confirm',this.state.translations.general,'general')}</h1>
		        <p>{Helpers.translate('Are you sure you want to delete selected line?',this.state.translations.general,'general')}</p>
		        <button onClick={onClose}>{Helpers.translate('No',this.state.translations.general,'general')}</button>
		        <button
		          onClick={() => {
		            onClose();
		            let genThis=this;
		            let oldState=Object.assign({}, genThis.state);
		            Helpers.showLoading();
		            Helpers.doRequest('delete','financial/expense',{'property_id':oldState.property_id,'expense_id':oldState.expenses.list[index].id,'page':oldState.currentPage})
		 			.then(data=>{
		            	oldState.expenses=data.expenses;
		            	genThis.setState(oldState);
		            	setTimeout(function(){
		            		Helpers.showAlerts(genThis,data);
		            	},200);
		 			}).catch(error=>{
		            	//
		        	});
		          }}
		        >
		          {Helpers.translate('Yes',this.state.translations.general,'general')}
		        </button>
		      </div>
		    );
		  }
		});
    }

    handlePageExpenses(data){
    	let oldState=Object.assign({}, this.state);
    	oldState.expense_edit_modal=false;
    	oldState.dataChange=false;
    	oldState.errors={};
		oldState.alerts=[];
	    oldState.alertsField=[];
	    oldState.currentPage=data.selected+1;
	    Helpers.doRequest('get','financial/expenses?property_id='+this.state.property_id+"&page="+oldState.currentPage)
 			.then(data=>{
            	oldState.expenses=data.expenses;
            	this.setState(oldState);
 			}).catch(error=>{
            	//
        	});
    }

    showExpenseLineFile(index,bool){
    	let oldState=Object.assign({}, this.state);
    	oldState.expenses.list[index].expanded=bool;
    	this.setState(oldState);
    }

    addRevenue(){
    	let oldState=Object.assign({}, this.state);
    	oldState.revenue_edit_modal=true;
    	oldState.revenue_edit_id="";
    	this.setState(oldState);
    }

    editRevenue(index){
    	let oldState=Object.assign({}, this.state);
    	oldState.revenue_edit_modal=true;
    	oldState.revenue_edit_id=oldState.revenues.list[index].id;
    	this.setState(oldState);
    }

    removeRevenue(index){
    	confirmAlert({
		  customUI: ({ onClose }) => {
		    return (
		      <div className='custom-ui'>
		        <h1>{Helpers.translate('Confirm',this.state.translations.general,'general')}</h1>
		        <p>{Helpers.translate('Are you sure you want to delete selected line?',this.state.translations.general,'general')}</p>
		        <button onClick={onClose}>{Helpers.translate('No',this.state.translations.general,'general')}</button>
		        <button
		          onClick={() => {
		            onClose();
		            let genThis=this;
		            let oldState=Object.assign({}, genThis.state);
		            Helpers.showLoading();
		            Helpers.doRequest('delete','financial/revenue',{'property_id':oldState.property_id,'revenue_id':oldState.revenues.list[index].id,'page':oldState.currentPage})
		 			.then(data=>{
		            	oldState.revenues=data.revenues;
		            	genThis.setState(oldState);
		            	setTimeout(function(){
		            		Helpers.showAlerts(genThis,data);
		            	},200);
		 			}).catch(error=>{
		            	//
		        	});
		          }}
		        >
		          {Helpers.translate('Yes',this.state.translations.general,'general')}
		        </button>
		      </div>
		    );
		  }
		});
	}

    updateRevenuesList(d){
    	let oldState=Object.assign({}, this.state);
    	oldState.revenue_edit_modal=false;
    	oldState.dataChange=false;
    	oldState.errors={};
		oldState.alerts=[];
	    oldState.alertsField=[];
	    Helpers.doRequest('get','financial/revenues?property_id='+this.state.property_id+"&page="+oldState.currentPage)
 			.then(data=>{
            	oldState.revenues=data.revenues;
            	this.setState(oldState);
            	let genThis=this;
            	setTimeout(function(){
            		Helpers.showAlerts(genThis,d);
            	},200);
 			}).catch(error=>{
            	//
        	});
    }

    handlePageRevenues(data){
    	let oldState=Object.assign({}, this.state);
    	oldState.revenue_edit_modal=false;
    	oldState.dataChange=false;
    	oldState.errors={};
		oldState.alerts=[];
	    oldState.alertsField=[];
	    oldState.currentPage=data.selected+1;
	    Helpers.doRequest('get','financial/revenues?property_id='+this.state.property_id+"&page="+oldState.currentPage)
 			.then(data=>{
            	oldState.revenues=data.revenues;
            	this.setState(oldState);
 			}).catch(error=>{
            	//
        	});
    }

    updateAccountantData(){
    	let fieldTranslations={
    		"accountant_name":Helpers.translate('Name',this.state.translations.general,'general'),
    		"accountant_email":Helpers.translate('Email',this.state.translations.general,'general')
    	};
    	let formErrors=FormValidation.checkValidity(this.validation_accountant,this.state.accountant,fieldTranslations,this.state.translations.general);
		if(formErrors.has_errors){
			Helpers.showAlerts(this,formErrors,true);
			return false;
		}
		let obj=Object.assign({},this.state.accountant);
		delete obj.list;
		Helpers.doRequest('post','financial/accountants',obj)
            .then(data=>{
            	let genThis=this;
            	let oldState=Object.assign({}, genThis.state);
            	oldState.dataChange=false;
            	genThis.setState(oldState);
            	setTimeout(function(){
            		Helpers.showAlerts(genThis,data,true);
            	},100);
            }).catch(error=>{
            	Helpers.showAlerts(this,error,true);
            });
    }

    updateAccountantList(d){
    	let oldState=Object.assign({}, this.state);
    	oldState.accountant_edit_modal=false;
    	oldState.dataChange=false;
    	oldState.errors={};
		oldState.alerts=[];
	    oldState.alertsField=[];
	    Helpers.doRequest('get','financial/accountants?&page='+oldState.currentPage)
 			.then(data=>{
            	oldState.accountant=data.accountant;
            	this.setState(oldState);
            	let genThis=this;
            	setTimeout(function(){
            		Helpers.showAlerts(genThis,d);
            	},200);
 			}).catch(error=>{
            	//
        	});
    }

    addAccountantLine(){
    	let oldState=Object.assign({}, this.state);
    	oldState.accountant_edit_modal=true;
    	this.setState(oldState);
    }

    handlePageAcountant(data){
    	let oldState=Object.assign({}, this.state);
    	oldState.accountant_edit_modal=false;
    	oldState.dataChange=false;
    	oldState.errors={};
		oldState.alerts=[];
	    oldState.alertsField=[];
	    oldState.currentPage=data.selected+1;
	    Helpers.doRequest('get','financial/accountants?&page='+oldState.currentPage)
 			.then(data=>{
            	oldState.accountant=data.accountant;
            	this.setState(oldState);
 			}).catch(error=>{
            	//
        	});
    }



    removeAccountantLIne(index){
    	confirmAlert({
		  customUI: ({ onClose }) => {
		    return (
		      <div className='custom-ui'>
		        <h1>{Helpers.translate('Confirm',this.state.translations.general,'general')}</h1>
		        <p>{Helpers.translate('Are you sure you want to delete selected line?',this.state.translations.general,'general')}</p>
		        <button onClick={onClose}>{Helpers.translate('No',this.state.translations.general,'general')}</button>
		        <button
		          onClick={() => {
		            onClose();
		            let genThis=this;
		            let oldState=Object.assign({}, genThis.state);
		            Helpers.showLoading();
		            Helpers.doRequest('delete','financial/accountant',{'id':oldState.accountant.list[index].id,'page':oldState.currentPage})
		 			.then(data=>{
		            	oldState.accountant=data.accountant;
		            	genThis.setState(oldState);
		            	setTimeout(function(){
		            		Helpers.showAlerts(genThis,data);
		            	},200);
		 			}).catch(error=>{
		            	//
		        	});
		          }}
		        >
		          {Helpers.translate('Yes',this.state.translations.general,'general')}
		        </button>
		      </div>
		    );
		  }
		});
    }

	closeAlert(index){
		let oldState=Object.assign({}, this.state);
		oldState.alerts.splice(index,1);
		this.setState(oldState);
	}

	render(){
		const { open } = this.state;
			const generalMsg = this.state.alerts.map((item, key) =>
		        <DismissibleAlert key={key} index={key} type={item.type} message={item.msg} dismissAlert={(e) => this.closeAlert(e)}/>
		    );
		    let propertyExpenses;
		    if(this.state.activeTab === 'expenses'){
		    	let expensesContent;
		    	if(this.state.expenses.list){
		    		expensesContent=this.state.expenses.list.map((item, key) =>
		    			<React.Fragment key={key}>
					        <tr>
					        	<td>
					        		{
					        			(item.files.length ? (!item.expanded ? <Button variant="link" className="pt-0 pb-0 pl-0" onClick={(event)=>this.showExpenseLineFile(key,true)}><i className="far fa-plus-square"></i></Button> : <Button variant="link" className="pt-0 pb-0 pl-0" onClick={(event)=>this.showExpenseLineFile(key,false)}><i className="far fa-minus-square"></i></Button>) : null)
					        		}
					        		<span>{ item.expense_category_name }</span>
					        	</td>
					        	<td>{ item.supplier_name }</td>
					        	<td>{ item.amount }</td>
					        	<td>{ item.date_formatted }</td>
										<td>{ (item.is_public ? Helpers.translate('Yes',this.state.translations.general,'general') : Helpers.translate('No',this.state.translations.general,'general')) }

										</td>


										<td className="text-center">

					        		<div>
											<IconButton aria-label="delete" size="small" onClick={(event)=>this.editExpense(key)}>{Helpers.translate(this.state.translations.general,'general')}
												<EditOutlinedIcon fontSize="inherit" />
											</IconButton>
											<IconButton aria-label="delete" size="small" onClick={(event)=>this.removeExpense(key)}>{Helpers.translate(this.state.translations.general,'general')}
												<DeleteOutlineIcon fontSize="inherit" />
											</IconButton>
											</div>

											</td>
										</tr>
					        {
					        	(item.files.length ?
						        	<tr className={ (!item.expanded ? "hidden" : "") }>
							        	<td colSpan="7" className="text-center">
							        		{ (item.files[0].file_type===2 ? <object data={item.files[0].url} type="application/pdf" width="100%" height="600px"><embed src={item.files[0].url} type="application/pdf" /></object> : <img alt="" src={item.files[0].url} />) }
							        	</td>
							        </tr>
						        : null)
					        }
				        </React.Fragment>
				    );
		    	}

	    	let paginationExpenses;
	    	if(this.state.expenses.page_count>1){
	    		paginationExpenses=<ReactPaginate
					        previousLabel={'<'}
					        nextLabel={'>'}
					        breakLabel={'...'}
					        pageCount={this.state.expenses.page_count}
					        marginPagesDisplayed={2}
					        pageRangeDisplayed={5}
					        onPageChange={(data)=>this.handlePageExpenses(data)}
					        containerClassName={'pagination pagination-sm'}
					        pageClassName={'page-item'}
					        pageLinkClassName={'page-link'}
					        previousClassName={'page-item'}
					        nextClassName={'page-item'}
					        previousLinkClassName={'page-link'}
					        nextLinkClassName={'page-link'}
					        activeClassName={'active'} />
	    	}
	    	propertyExpenses=<div>
	    		{this.state.expense_edit_modal && <ExpenseEditModal
	    			expense_id={this.state.expense_edit_id}
	    			property_id={this.state.property_id}
	    			title={ (!this.state.expense_edit_id ? Helpers.translate('Add',this.state.translations.general,'general') : Helpers.translate('Edit',this.state.translations.general,'general')) }
			        show={this.state.expense_edit_modal}
			        onHide={() => this.updateStateValue(false,'expense_edit_modal')}
			        onUpdate={(data) => this.updateExpensesList(data)}
			        translations={this.state.translations}
			    />}
					<div className="row justify-content-md-center">
						<div className= "col-sm-9">
		    			<div className="buttonAlignment text-left mt-2 mb-2"  >
								<Button className="ButtonAdd" variant="outline-info" onClick={()=>this.addExpense()}>{Helpers.translate('Add new',this.state.translations.general,'general')}</Button>
							</div>
						</div>
					</div>
				<div className="row justify-content-md-center">
				<div className= "col-sm-8">
						<Table responsive="sm text-left">
			  			<thead>
					      <tr>
					        <th>{Helpers.translate('Category',this.state.translations.financial,'financial')}</th>
					        <th>{Helpers.translate('Supplier',this.state.translations.financial,'financial')}</th>
					        <th>{Helpers.translate('Amount',this.state.translations.financial,'financial')}</th>
					        <th>{Helpers.translate('Date',this.state.translations.general,'general')}</th>
					        <th>{Helpers.translate('Visibility',this.state.translations.financial,'financial')}</th>
					        <th></th>
					      </tr>
					    </thead>
					    <tbody>
					    	{ expensesContent }
					    </tbody>
			  		</Table>
						</div>
				</div>
				<div>{ paginationExpenses }</div>
	    	</div>
	    }

// Format the amoutn number into comma decimal
			function formatNumber(num) {
  			return( num.toFixed(2) // always two decimal digits
      		.replace('.', ',') // replace decimal point character with ,
      		.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
)}
	    let propertyRevenues;
	    if(this.state.activeTab === 'revenues'){
	    	let revenuesContent;
	    	if(this.state.revenues.list){
	    		revenuesContent=this.state.revenues.list.map((item, key)  =>
			        <tr key={key}>
			        	<td>{ item.guest_name }</td>
			        	<td>{ item.from_date_formatted }</td>
			        	<td>{ item.to_date_formatted } </td>
			        	<td>{ item.amount }</td>
			        	<td>{ item.amount_net }</td>
			        	<td>{ formatNumber(item.amount_per_day) }</td>
			        	<td>{ item.cleaning_fee }</td>
			        	<td>{ (item.is_public ? Helpers.translate('Yes',this.state.translations.general,'general') : Helpers.translate('No',this.state.translations.general,'general')) }</td>
			        	<td className="text-center">
			        		<div>
									<IconButton aria-label="edit" size="small" onClick={(event)=>this.editRevenue(key)}>{Helpers.translate(this.state.translations.general,'general')}
										<EditOutlinedIcon fontSize="inherit" />
									</IconButton>
									<IconButton aria-label="delete" size="small" onClick={(event)=>this.removeRevenue(key)}>{Helpers.translate(this.state.translations.general,'general')}
										<DeleteOutlineIcon fontSize="inherit" />
									</IconButton>
							</div>
			        	</td>
			        </tr>
			    );
	    	}
	    	let paginationRevenues;
	    	if(this.state.revenues.page_count>1){
	    		paginationRevenues=<ReactPaginate
					        previousLabel={'<'}
					        nextLabel={'>'}
					        breakLabel={'...'}
					        pageCount={this.state.revenues.page_count}
					        marginPagesDisplayed={2}
					        pageRangeDisplayed={5}
					        onPageChange={(data)=>this.handlePageRevenues(data)}
					        containerClassName={'pagination pagination-sm'}
					        pageClassName={'page-item'}
					        pageLinkClassName={'page-link'}
					        previousClassName={'page-item'}
					        nextClassName={'page-item'}
					        previousLinkClassName={'page-link'}
					        nextLinkClassName={'page-link'}
					        activeClassName={'active'} />
	    	}
	    	propertyRevenues=<div>
	    		{this.state.revenue_edit_modal && <RevenueEditModal
	    			revenue_id={this.state.revenue_edit_id}
	    			property_id={this.state.property_id}
	    			title={ (!this.state.revenue_edit_id ? Helpers.translate('Add',this.state.translations.general,'general') : Helpers.translate('Edit',this.state.translations.general,'general')) }
			        show={this.state.revenue_edit_modal}
			        onHide={() => this.updateStateValue(false,'revenue_edit_modal')}
			        onUpdate={(data) => this.updateRevenuesList(data)}
			        translations={this.state.translations}
			    />}
					<div className="row justify-content-md-center">
						<div className= "col-sm-9">
			    		<div className="buttonAlignment text-left mt-2 mb-2">
								<Button className="ButtonAdd" variant="outline-info" onClick={()=>this.addRevenue()}>{Helpers.translate('Add new',this.state.translations.general,'general')}</Button>
							</div>
						</div>
					</div>
				<div>
			<div className="row justify-content-md-center">
				<div className= "col-sm-8">
					<Table responsive="sm text-left">
			  			<thead>
					      <tr>
					        <th>{Helpers.translate('Name',this.state.translations.general,'general')}</th>
					        <th>{Helpers.translate('From',this.state.translations.general,'general')}</th>
					        <th>{Helpers.translate('To',this.state.translations.general,'general')}</th>
					        <th>{Helpers.translate('Amount',this.state.translations.financial,'financial')}</th>
					        <th>{Helpers.translate('Amount Net',this.state.translations.financial,'financial')}</th>
					        <th>{Helpers.translate('Amount per day',this.state.translations.financial,'financial')}</th>
					        <th>{Helpers.translate('Cleaning fee',this.state.translations.financial,'financial')}</th>
					        <th>{Helpers.translate('Visible',this.state.translations.financial,'financial')}</th>
					        <th></th>
					      </tr>
					    </thead>
					    <tbody>
					    	{ revenuesContent }
					    </tbody>
			  		</Table>
				</div>
					</div>
					</div>
					<div>{ paginationRevenues }</div>
		    	</div>
	    }
	    let propertyAccountant;
	    if(this.state.activeTab === 'accountant'){
	    	let accountantContent;
	    	if(this.state.accountant.list){
	    		accountantContent=this.state.accountant.list.map((item, key) =>
			        <tr key={key}>
			        	<td>{ item.from_date_formatted } - { item.to_date_formatted }</td>
			        	<td>{ item.accountant_name }</td>
			        	<td>{ item.email }</td>
			        	<td><a href={item.full_code} target="_blank" rel="noopener noreferrer"><i className="fa fa-link" style={{color:"#17a2b8", fontSize:"14px"}}aria-hidden="true"></i></a>
									&nbsp;
									<IconButton aria-label="delete" size="small" onClick={(event)=>this.removeAccountantLIne(key)}>{Helpers.translate(this.state.translations.general,'general')}
										<DeleteOutlineIcon fontSize="inherit" />
									</IconButton>

			        	</td>
			        </tr>
			    );
	    	}
	    	let paginationAccountant;
	    	if(this.state.accountant.page_count>1){
	    		paginationAccountant=<ReactPaginate
					        previousLabel={'<'}
					        nextLabel={'>'}
					        breakLabel={'...'}
					        pageCount={this.state.accountant.page_count}
					        marginPagesDisplayed={2}
					        pageRangeDisplayed={5}
					        onPageChange={(data)=>this.handlePageAcountant(data)}
					        containerClassName={'pagination pagination-sm'}
					        pageClassName={'page-item'}
					        pageLinkClassName={'page-link'}
					        previousClassName={'page-item'}
					        nextClassName={'page-item'}
					        previousLinkClassName={'page-link'}
					        nextLinkClassName={'page-link'}
					        activeClassName={'active'} />
	    	}
	    	propertyAccountant=




				<div>

				<Button className="accountantDataCollapse"
					onClick={() => this.setState({ open: !open })}
					aria-controls="example-collapse-text"
					aria-expanded={open}
					variant="link"
				>
					Accountant Data
				</Button>
				<Collapse in={this.state.open}>
				<div className="row justify-content-md-center" >
					<div className="accountantData col-sm-8" >
								<div className="col-sm-4">
									<Form.Group>
										<Form.Label className="ft-16">{Helpers.translate('Accountant name',this.state.translations.financial,'financial')} <i className="ft-11 fas fa-asterisk text-danger"></i></Form.Label>
										<Form.Control className={this.state.errors.accountant_name ? 'border-error' : ''} type="text" maxLength="191" value={this.state.accountant.accountant_name || ''} name="accountant_name" onChange={(e)=>this.updateStateValue(e.target.value,'accountant_name','accountant')} placeholder={Helpers.translate('Accountant name',this.state.translations.financial,'financial')} autoComplete="off"/>
										{this.state.errors.accountant_name && <p className="text-danger mt-1">{this.state.alertsField.accountant_name}</p>}
									</Form.Group>
								</div>
								<div className="col-sm-4">
									<Form.Group>
										<Form.Label className="ft-16">{Helpers.translate('Accountant email',this.state.translations.financial,'financial')} <i className="ft-11 fas fa-asterisk text-danger"></i></Form.Label>
										<Form.Control className={this.state.errors.accountant_email ? 'border-error' : ''} type="text" maxLength="191" value={this.state.accountant.accountant_email || ''} name="accountant_email" onChange={(e)=>this.updateStateValue(e.target.value,'accountant_email','accountant')} placeholder={Helpers.translate('Accountant email',this.state.translations.financial,'financial')} autoComplete="off"/>
										{this.state.errors.accountant_email && <p className="text-danger mt-1">{this.state.alertsField.accountant_email}</p>}
									</Form.Group>
								</div>
									<div className="accountSaveButton col-sm-4"   >
										<Button className="ButtonAddAccountant" variant="outline-info" onClick={()=>this.updateAccountantData()}>{Helpers.translate('Save',this.state.translations.general,'general')}</Button>
									</div>
						</div>
					</div>
				</Collapse>




	    		{this.state.accountant_edit_modal && <AccountantEditModal
	    			title={Helpers.translate('Add',this.state.translations.general,'general')}
			        show={this.state.accountant_edit_modal}
			        onHide={() => this.updateStateValue(false,'accountant_edit_modal')}
			        onUpdate={(data) => this.updateAccountantList(data)}
			        translations={this.state.translations}
			    />}
					<div className="row justify-content-md-center">
						<div className= "col-sm-9">
			    		<div className="buttonAlignment text-left mt-2 mb-2">
								<Button className="ButtonAdd" variant="outline-info" onClick={()=>this.addAccountantLine()}>{Helpers.translate('Add new',this.state.translations.general,'general')}</Button>
							</div>
						</div>
					</div>
				<div className="row justify-content-md-center">
				<div className= "col-sm-8">
					<Table responsive="sm text-left">
			  			<thead>
					      <tr>
					        <th>{Helpers.translate('Period',this.state.translations.general,'general')}</th>
					        <th>{Helpers.translate('Accountant name',this.state.translations.financial,'financial')}</th>
					        <th>{Helpers.translate('Email',this.state.translations.general,'general')}</th>
					        <th>{Helpers.translate('Link',this.state.translations.general,'general')}</th>
					        <th></th>
					      </tr>
					    </thead>
					    <tbody>
					    	{ accountantContent }
					    </tbody>
			  		</Table>
						</div>
			</div>
				<div>{ paginationAccountant }</div>
	    	</div>
	    }
		return (
			<div className="navbarContent">
				{generalMsg}
				{this.state.show_content && <div className=" col-sm-12 " >

					<Tabs className="navbartabs" id="financial_tabs" activeKey={this.state.activeTab} onSelect={(k) => (k !== this.state.activeTab ? (k === 'back' ? this.props.history.push("/home") : (this.state.dataChange ? this.confirmChangeTab(k) : this.renderTab(k))) : null)}>
				      	<Tab eventKey="expenses" title={Helpers.translate('Expenses',this.state.translations.financial,'financial')}>
				      		{propertyExpenses}
				      	</Tab>
				      	<Tab eventKey="revenues" title={Helpers.translate('Revenues',this.state.translations.financial,'financial')}>
				      		{propertyRevenues}
				      	</Tab>
				      	<Tab eventKey="accountant" title={Helpers.translate('Accountant',this.state.translations.financial,'financial')}>
				      		{propertyAccountant}
				      	</Tab>

				    </Tabs>
				</div>
				}
		   	</div>
		)
	}

}
export default withRouter(Financial);
