import React from 'react';
import { withRouter } from 'react-router-dom';
import DismissibleAlert from './../../components/DismissibleAlert.js';
import Helpers from './../../services/Helpers.js';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import FormValidation from './../../services/FormValidation.js';
import Select from 'react-select';

class MyAccount extends React.Component{

	constructor(props) {
		super(props);
		this.state={			
	  		errors:{},
	  		success:{},
		  	alerts:[],  	
	      	alertsField:[], 
	      	translations:{}, 
	      	account:{}, 
	      	countryLoading:false   	
		};
		this.moduleName='myaccount';
		this.validations={
	  		"name":["required"],
	  		"email":["required","email"],
	  		"country":["required"],
	  		"phone_number":["required"]
	  	};
	}

	componentDidMount(){
		let oldState=Object.assign({}, this.state);
		Helpers.doRequest('get','myaccount')
 			.then(data=>{  		 				       	                 	            	
            	oldState.account=data.account;
            	Helpers.getTranslations(this.moduleName)
		            .then(data=>{
		            	oldState.translations=data;			                						                
		                this.setState(oldState);
		            }).catch(error=>{
		            	oldState.translations=error;
		            	this.setState(oldState);
		            });
 			}).catch(error=>{
            	//	
        	});
	}

	updateStateValue(value,model,parent){
        let oldState=Object.assign({}, this.state);      
        if(parent){
        	if(model==='country'){
        		if(value !== null){
	        		oldState[parent][model]=[];
	        		oldState[parent][model].push(value);
	        	}else{
	        		oldState[parent][model]=value;
	        	}
        	}else{
        		oldState[parent][model]=value;    
        	}      	   	
        }else{
        	oldState[model]=value;
        }   
        this.setState(oldState);  
    }

    countrySearch(search,action){
    	if(action === 'input-change'){
    		if(this.searchPromise){
	            clearTimeout(this.searchPromise);
	        }
	        let oldState=Object.assign({}, this.state);
	        this.searchPromise=setTimeout(()=>{  
	        	this.setState({countryLoading:true}) 
	            Helpers.doRequest('get','countries?search='+search,{},true)
		            .then(data=>{
		            	oldState.countryLoading=false;
		                oldState.account.countries=data.countries;
		                this.setState(oldState);
		            }).catch(error=>{});
		    },500);
	    }
    }

    saveAccount(){
    	let fieldTranslations={
    		"name":Helpers.translate('Name',this.state.translations.general,'general'),
    		"email":Helpers.translate('Email',this.state.translations.general,'general'),
    		"country":Helpers.translate('Country',this.state.translations.general,'general'),
    		"phone_number":Helpers.translate('Phone Number',this.state.translations.general,'general'),
    	};
		let formErrors=FormValidation.checkValidity(this.validations,this.state.account,fieldTranslations,this.state.translations.general);
		if(formErrors.has_errors){
			Helpers.showAlerts(this,formErrors,true);
			return false;
		}
		let obj=Object.assign({},this.state.account);
		obj.country=obj.country[0].code;
		Helpers.doRequest('post','myaccount',obj)
            .then(data=>{
            	Helpers.showAlerts(this,data);
            }).catch(error=>{
            	Helpers.showAlerts(this,error,true);
            });
    }

    render(){
    	const generalMsg = this.state.alerts.map((item, key) =>
	        <DismissibleAlert key={key} index={key} type={item.type} message={item.msg} dismissAlert={(e) => Helpers.closeAlert(this,e)}/>
	    );
    	return (
    		<div className="content">
				{generalMsg}
				<div className="col-sm-8">
					<Form className="text-left">	
						<Form.Group>
					    	<Form.Label className="ft-16">{Helpers.translate('Name',this.state.translations.general,'general')} <i className="ft-11 fas fa-asterisk text-danger"></i></Form.Label>
							<Form.Control className="pl-0" type="text" value={this.state.account.name || ''} 
							name="name" placeholder={Helpers.translate('Name',this.state.translations.general,'general')} onChange={(e)=>this.updateStateValue(e.target.value,'name','account')} autoComplete="off"/>
					  		{this.state.errors.name && <p className="text-danger mt-1">{this.state.alertsField.name}</p>}
					  	</Form.Group>
					  	<Form.Group>
					    	<Form.Label className="ft-16">{Helpers.translate('Email',this.state.translations.general,'general')} <i className="ft-11 fas fa-asterisk text-danger"></i></Form.Label>
							<Form.Control className="pl-0" type="text" value={this.state.account.email || ''} 
							name="email" placeholder={Helpers.translate('Name',this.state.translations.general,'general')} onChange={(e)=>this.updateStateValue(e.target.value,'email','account')} autoComplete="off"/>
					  		{this.state.errors.email && <p className="text-danger mt-1">{this.state.alertsField.email}</p>}
					  	</Form.Group>
					  	<Form.Group>
					    	<Form.Label className="ft-16">{Helpers.translate('Address',this.state.translations.general,'general')}</Form.Label>
							<Form.Control className="pl-0" type="text" value={this.state.account.address || ''} 
							name="address" placeholder={Helpers.translate('Address',this.state.translations.general,'general')} onChange={(e)=>this.updateStateValue(e.target.value,'address','account')} autoComplete="off"/>
					  	</Form.Group>
					  	<Form.Group>
					    	<Form.Label className="ft-16">{Helpers.translate('Country',this.state.translations.general,'general')} <i className="ft-11 fas fa-asterisk text-danger"></i></Form.Label>
							<Select options={this.state.account.countries}
					    		value={this.state.account.country && this.state.account.country.length ? this.state.account.country : []} 
					    		className={"selectize "+(this.state.errors.country ? 'border-error' : '')}
          						isClearable={true}
          						isSearchable={true}
          						isLoading={this.state.countryLoading}
          						placeholder={Helpers.translate('Select',this.state.translations.general,'general')}
					    		getOptionLabel={option => option.name}
					    		getOptionValue={option => option.code}
					    		onChange={(value)=>this.updateStateValue(value,'country','account')}
					    		onInputChange={(str,action)=>this.countrySearch(str,action.action)}
					    	/>
					  		{this.state.errors.country && <p className="text-danger mt-1">{this.state.alertsField.country}</p>}
					  	</Form.Group>
					  	<Form.Group>
					    	<Form.Label className="ft-16">{Helpers.translate('Phone Number',this.state.translations.general,'general')} <i className="ft-11 fas fa-asterisk text-danger"></i></Form.Label>
							<Form.Control className="pl-0" type="text" value={this.state.account.phone_number || ''} 
							name="phone_number" placeholder={Helpers.translate('Phone Number',this.state.translations.general,'general')} onChange={(e)=>this.updateStateValue(e.target.value,'phone_number','account')} autoComplete="off"/>
					  		{this.state.errors.phone_number && <p className="text-danger mt-1">{this.state.alertsField.phone_number}</p>}
					  	</Form.Group>							  	
					</Form>
					<div className="text-left mb-2">
						<Button variant="info" onClick={()=>this.saveAccount()}>{Helpers.translate('Save',this.state.translations.general,'general')}</Button>
					</div>
				</div>
		   	</div>
    	);
    }

}
export default withRouter(MyAccount);