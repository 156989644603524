import React from 'react';
import './Login.css';
import Helpers from './../../services/Helpers.js';
import DismissibleAlert from './../../components/DismissibleAlert.js';
import Button from 'react-bootstrap/Button';
import { withRouter } from 'react-router-dom';

class Login extends React.Component{

	constructor(props) {
	  	super(props);
	  	this.state = { 			
	  		errors:{},
		  	alerts:[],
	      	alertsField:[],
	      	showSignUp:false
	  	};	  	
	}

	signInFacebook() {
 		Helpers.doRequest('get','login/facebook')
 			.then(data=>{
            	window.location.href = data.redirect;
 			}).catch(error=>{
            	Helpers.showAlerts(this,error);
        	});
	}

	signInGoogle() {
 		Helpers.doRequest('get','login/google')
 			.then(data=>{
            	window.location.href = data.redirect;
 			}).catch(error=>{
            	Helpers.showAlerts(this,error);
        	});
	}

	signUp(){
		let genThis=this;
		Helpers.showLoading();
		setTimeout(function(){
			Helpers.stopLoading();
			genThis.setState({'showSignUp':true});
		},1500);
	}

	render(){
		const generalErrors = this.state.alerts.map((item, key) =>
	        <DismissibleAlert key={key} index={key} type={item.type} message={item.msg} dismissAlert={(e) => Helpers.closeAlert(this,e)}/>
	    );
		return (
			<div className="page-login">
				<div className="page-login-vertical">
					<div className="page-login-box">
						<div className="row">
							<div className="col-sm-12">
								{!this.state.showSignUp && <h1 className="text-center">CasayCondo</h1>}
								{this.state.showSignUp && <h1 className="text-center">Sign Up</h1>}
							</div>
						</div>
						<div className="row">
							<div className="page-login-panel">
								{generalErrors}
								<form className="form-horizontal text-left" name="login_form">
									{!this.state.showSignUp && <div className="form-group page-login-actions">
										<div className="col-sm-12 text-center mb-2">
											<button type="button" className="btn btn-lg btn-default" onClick={() => this.signInGoogle()}><i className="fab fa-google"></i> Sign in with Google</button>
										</div>
										<div className="col-sm-12 text-center">
											<button type="button" className="btn btn-lg btn-default" onClick={() => this.signInFacebook()}><i className="fab fa-facebook"></i> Sign in with Facebook</button>
										</div>
										<div className="col-sm-12 text-center mt-3">
											<Button variant="link" className="text-decoration-none" onClick={() => this.signUp()}>
												<span className="ft-16 font-weight-bold">Sign up for an account</span>
											</Button>
										</div>
									</div>}
									{this.state.showSignUp && <div className="form-group page-login-actions">
										<div className="col-sm-12 text-center mb-2">
											<button type="button" className="btn btn-lg btn-default" onClick={() => this.signInGoogle()}><i className="fab fa-google"></i> Continue with Google</button>
										</div>
										<div className="col-sm-12 text-center">
											<button type="button" className="btn btn-lg btn-default" onClick={() => this.signInFacebook()}><i className="fab fa-facebook"></i> Continue with Facebook</button>
										</div>
									</div>}
								</form>
							</div>
						</div>
					</div>
			   	</div>
		   	</div>
		)
	}
}
export default withRouter(Login);
