import React from 'react';
import './App.css';
import {
  BrowserRouter,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import Header from './pages/Header/Header';
import Login from './pages/Login/Login';
import Store from './services/Store.js';
import Onboarding from './pages/Onboarding/Onboarding';
import OnboardingMore from './pages/Onboarding/OnboardingMore';
import GoogleAuth from './pages/GoogleAuth/GoogleAuth';
import FacebookAuth from './pages/FacebookAuth/FacebookAuth';
import Impersonate from './pages/Impersonate/Impersonate';
import Home from './pages/Home/Home';
import Property from './pages/Property/Property';
import Financial from './pages/Financial/Financial';
import MyAccount from './pages/MyAccount/MyAccount';

const eventEmitter = require('events');
window.ee = new eventEmitter();

class App extends React.Component{

  constructor(props) {
      super(props);
      this.state = {
        isLoged:Store.retrieveStatus(),
        main_page:Store.retrieveMainPage(),
      };
      this.listenerData=[];
  }

  componentDidMount() {
    if(this.listenerData.indexOf("storeChange") === -1){
      window.ee.on('storeChange', () => { 
        let oldState=Object.assign({}, this.state);
        oldState.isLoged=Store.retrieveStatus();
        oldState.main_page=Store.retrieveMainPage();
        this.setState(oldState);
      });
    }     
  }

  componentWillUnmount(){
    if(this.listenerData.indexOf("storeChange") !== -1){
      window.ee.removeListener('storeChange', () => { 
        //
      });
    }   
  }

  render(){ 
    return (    
        <div>          
          <BrowserRouter>
            {this.state.isLoged && <Header main_page={this.state.main_page} />}
            <Switch>
                <Route path="/onboarding" render={() => (this.state.isLoged && this.state.main_page === 'onboarding' ? <Onboarding/> : <Redirect to='/' />) }></Route>
                <Route path="/home" render={() => (this.state.isLoged && this.state.main_page === 'home' ? <Home/> : <Redirect to='/' />) }></Route>
                <Route path="/onboarding_more" render={() => (this.state.isLoged && this.state.main_page === 'onboarding_more' ? <OnboardingMore/> : <Redirect to='/' />) }></Route>
                <Route path="/gauth" render={() => (this.state.isLoged ? <Redirect to={`/${this.state.main_page}`} /> : <GoogleAuth />) }></Route>
                <Route path="/fauth" render={() => (this.state.isLoged ? <Redirect to={`/${this.state.main_page}`} /> : <FacebookAuth />) }></Route>
                <Route path="/impersonate" render={() => (this.state.isLoged ? <Redirect to={`/${this.state.main_page}`} /> : <Impersonate />) }></Route>
                <Route exact path="/property/:id" render={() => (this.state.isLoged && this.state.main_page === 'home' ? <Property /> : <Redirect to='/' />) }></Route>
                <Route exact path="/financial/:id" render={() => (this.state.isLoged && this.state.main_page === 'home' ? <Financial /> : <Redirect to='/' />) }></Route>
                <Route exact path="/" render={() => (this.state.isLoged ? <Redirect to={`/${this.state.main_page}`} /> : <Login />) }></Route>
                <Route exact path="/myaccount" render={() => (this.state.isLoged && this.state.main_page === 'home' ? <MyAccount/> : <Redirect to='/' />) }></Route>
                <Route render={() => (this.state.isLoged ? <Redirect to={`/${this.state.main_page}`} /> : <Redirect to='/' /> ) }></Route>          
            </Switch>
          </BrowserRouter>
        </div>  
    )
  } 
}
export default App;
